import React, { useContext, useEffect, useState } from "react";
import Logo from "../images/logo.png";
import LogoMini from "../images/logo-mini.png";
import ProfileImg from "../images/faces/face1.jpg";
import { Context } from "../Context/DataContext";

import "bootstrap/dist/css/bootstrap.min.css";
import "../../node_modules/bootstrap/dist/js/bootstrap.bundle";

// import '@fortawesome/fontawesome-free/css/all.min.css';

const useForceUpdate = () => {
  const [, updateState] = React.useState();
  return React.useCallback(() => updateState({}), []);
};

const TopBar = () => {
  const { state } = useContext(Context);
  const forceUpdate = useForceUpdate();

  const data = JSON.parse(localStorage.getItem("garage_data"));
  const profileData = data?.st_id ? {
    name: data.st_fname + " " + data.st_lname,
    email: data.st_email,
  }
    : {
      name: data.g_name,
      email: data.g_email,
    }

  useEffect(() => {
    // forceUpdate();
  }, []);

  const logout = () => {
    localStorage.setItem("garage_data", '');
    localStorage.setItem("screens", '');
    window.location.href = "/";
  }

  return (
    <nav className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex align-items-top flex-row navbar-success">
      <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">
        <div className="me-3">
          <button
            onclick="alert('here');"
            className="navbar-toggler navbar-toggler align-self-center"
            type="button"
            data-bs-toggle="minimize"
          >
            <span className="icon-menu" />
          </button>
        </div>
        <div>
          <a className="navbar-brand brand-logo" href="/">
            <img src={Logo} alt="logo" />
          </a>
          <a className="navbar-brand brand-logo-mini" href="/">
            <img src={LogoMini} alt="logo" />
          </a>
        </div>
      </div>
      <div className="navbar-menu-wrapper d-flex align-items-top">
        <ul className="navbar-nav">
          <li className="nav-item font-weight-semibold d-none d-lg-block ms-0">
            <h1 className="welcome-text">
              Good Morning,{" "}
              <span className="fw-bold" style={{ color: "#ffff00" }}>
                {state?.garageData?.g_name}
              </span>
            </h1>
            <h3 className="welcome-sub-text">
              Manage your Garage smartly.
              <br />
            </h3>
          </li>
        </ul>

        <ul className="navbar-nav ms-auto">
          <li className="nav-item">
            <form className="search-form" action="#">
              <i className="icon-search" />
              <input
                type="search"
                className="form-control"
                placeholder="Search Here"
                title="Search here"
              />
            </form>
          </li>
          <li className="nav-item dropdown">
            <a
              className="nav-link count-indicator"
              id="notificationDropdown"
              href="#"
              data-bs-toggle="dropdown"
            >
              <i className="icon-mail icon-lg" />
            </a>
            <div
              className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list pb-0"
              aria-labelledby="notificationDropdown"
            >
              <a className="dropdown-item py-3 border-bottom" href="/">
                <p className="mb-0 font-weight-medium float-left">
                  You have 4 new notifications{" "}
                </p>
                <span className="badge badge-pill badge-primary float-right">
                  View all
                </span>
              </a>
              <a className="dropdown-item preview-item py-3" href="/">
                <div className="preview-thumbnail">
                  <i className="mdi mdi-alert m-auto text-primary" />
                </div>
                <div className="preview-item-content">
                  <h6 className="preview-subject fw-normal text-dark mb-1">
                    Application Error
                  </h6>
                  <p className="fw-light small-text mb-0"> Just now </p>
                </div>
              </a>
              <a className="dropdown-item preview-item py-3" href="/">
                <div className="preview-thumbnail">
                  <i className="mdi mdi-settings m-auto text-primary" />
                </div>
                <div className="preview-item-content">
                  <h6 className="preview-subject fw-normal text-dark mb-1">
                    Settings
                  </h6>
                  <p className="fw-light small-text mb-0"> Private message </p>
                </div>
              </a>
              <a className="dropdown-item preview-item py-3" href="/">
                <div className="preview-thumbnail">
                  <i className="mdi mdi-airballoon m-auto text-primary" />
                </div>
                <div className="preview-item-content">
                  <h6 className="preview-subject fw-normal text-dark mb-1">
                    New user registration
                  </h6>
                  <p className="fw-light small-text mb-0"> 2 days ago </p>
                </div>
              </a>
            </div>
          </li>
          <li className="nav-item dropdown">
            {/* <a
                            className="nav-link count-indicator"
                            id="countDropdown"
                            href="#"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <i className="icon-bell" />
                            <span className="count" />
                        </a> */}
            <div
              className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list pb-0"
              aria-labelledby="countDropdown"
            >
              <a className="dropdown-item py-3" href="/">
                <p className="mb-0 font-weight-medium float-left">
                  You have 7 unread mails{" "}
                </p>
                <span className="badge badge-pill badge-primary float-right">
                  View all
                </span>
              </a>
              <div className="dropdown-divider" />
              <a className="dropdown-item preview-item" href="/">
                <div className="preview-thumbnail">
                  <img
                    src={ProfileImg}
                    alt="image"
                    className="img-sm profile-pic"
                  />
                </div>
                <div className="preview-item-content flex-grow py-2" href="/">
                  <p className="preview-subject ellipsis font-weight-medium text-dark">
                    Marian Garner{" "}
                  </p>
                  <p className="fw-light small-text mb-0">
                    {" "}
                    The meeting is cancelled{" "}
                  </p>
                </div>
              </a>
              <a className="dropdown-item preview-item" href="/">
                <div className="preview-thumbnail">
                  <img
                    src={ProfileImg}
                    alt="image"
                    className="img-sm profile-pic"
                  />
                </div>
                <div className="preview-item-content flex-grow py-2">
                  <p className="preview-subject ellipsis font-weight-medium text-dark">
                    David Grey{" "}
                  </p>
                  <p className="fw-light small-text mb-0">
                    {" "}
                    The meeting is cancelled{" "}
                  </p>
                </div>
              </a>
              <a className="dropdown-item preview-item" href="/">
                <div className="preview-thumbnail">
                  <img
                    src={ProfileImg}
                    alt="image"
                    className="img-sm profile-pic"
                  />
                </div>
                <div className="preview-item-content flex-grow py-2">
                  <p className="preview-subject ellipsis font-weight-medium text-dark">
                    Travis Jenkins{" "}
                  </p>
                  <p className="fw-light small-text mb-0">
                    {" "}
                    The meeting is cancelled{" "}
                  </p>
                </div>
              </a>
            </div>
          </li>
          <li className="nav-item dropdown d-none d-lg-block user-dropdown">
            <a
              className="nav-link"
              id="UserDropdown"
              href="#"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                className="img-xs rounded-circle"
                src={ProfileImg}
                alt="Profile image"
              />
            </a>
            <div
              className="dropdown-menu dropdown-menu-right navbar-dropdown"
              aria-labelledby="UserDropdown"
            >
              <div className="dropdown-header text-center">
                <p className="mb-1 mt-3 font-weight-semibold">{profileData?.name}</p>
                <p className="fw-light text-muted mb-0">
                  {profileData?.email}
                </p>
              </div>
              
              <a
                onClick={() => logout()}
                className="dropdown-item" href="#">
                <i className="dropdown-item-icon mdi mdi-power text-primary me-2" />
                Sign Out
              </a>
            </div>
          </li>
        </ul>
        <button
          className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
          type="button"
          data-bs-toggle="offcanvas"
          data-toggle="collapse"
          data-target="#sidebar"
        >
          <span className="mdi mdi-menu" />
        </button>
      </div>
    </nav>
  );
};

<nav className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex align-items-top flex-row">
  <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">
    <div className="me-3">
      <button
        className="navbar-toggler navbar-toggler align-self-center"
        type="button"
        data-bs-toggle="minimize"
      >
        <span className="icon-menu" />
      </button>
    </div>
    <div>
      <a className="navbar-brand brand-logo" href="/">
        <img src="images/logo.svg" alt="logo" />
      </a>
      <a className="navbar-brand brand-logo-mini" href="/">
        <img src="images/logo-mini.svg" alt="logo" />
      </a>
    </div>
  </div>
  <div className="navbar-menu-wrapper d-flex align-items-top">
    <ul className="navbar-nav">
      <li className="nav-item font-weight-semibold d-none d-lg-block ms-0">
        <h1 className="welcome-text">
          Good Morning, <span className="text-black fw-bold">John Doe</span>
        </h1>
        <h3 className="welcome-sub-text">
          Your performance summary this week{" "}
        </h3>
      </li>
    </ul>

    <ul className="navbar-nav ms-auto">
      <li className="nav-item d-none d-lg-block">
        <div
          id="datepicker-popup"
          className="input-group date datepicker navbar-date-picker"
        >
          <span className="input-group-addon input-group-prepend border-right">
            <span className="icon-calendar input-group-text calendar-icon" />
          </span>
          <input type="text" className="form-control" />
        </div>
      </li>
      <li className="nav-item">
        <form className="search-form" action="#">
          <i className="icon-search" />
          <input
            type="search"
            className="form-control"
            placeholder="Search Here"
            title="Search here"
          />
        </form>
      </li>
      <li className="nav-item dropdown">
        <a
          className="nav-link count-indicator"
          id="notificationDropdown"
          href="#"
          data-bs-toggle="dropdown"
        >
          <i className="icon-mail icon-lg" />
        </a>
        <div
          className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list pb-0"
          aria-labelledby="notificationDropdown"
        >
          <a className="dropdown-item py-3 border-bottom" href="/">
            <p className="mb-0 font-weight-medium float-left">
              You have 4 new notifications{" "}
            </p>
            <span className="badge badge-pill badge-primary float-right">
              View all
            </span>
          </a>
          <a className="dropdown-item preview-item py-3" href="/">
            <div className="preview-thumbnail">
              <i className="mdi mdi-alert m-auto text-primary" />
            </div>
            <div className="preview-item-content" href="/">
              <h6 className="preview-subject fw-normal text-dark mb-1">
                Application Error
              </h6>
              <p className="fw-light small-text mb-0"> Just now </p>
            </div>
          </a>
          <a className="dropdown-item preview-item py-3" href="/">
            <div className="preview-thumbnail">
              <i className="mdi mdi-settings m-auto text-primary" />
            </div>
            <div className="preview-item-content">
              <h6 className="preview-subject fw-normal text-dark mb-1">
                Settings
              </h6>
              <p className="fw-light small-text mb-0"> Private message </p>
            </div>
          </a>
          <a className="dropdown-item preview-item py-3" href="/">
            <div className="preview-thumbnail">
              <i className="mdi mdi-airballoon m-auto text-primary" />
            </div>
            <div className="preview-item-content">
              <h6 className="preview-subject fw-normal text-dark mb-1">
                New user registration
              </h6>
              <p className="fw-light small-text mb-0"> 2 days ago </p>
            </div>
          </a>
        </div>
      </li>
      <li className="nav-item dropdown">
        {/* <a
                                className="nav-link count-indicator"
                                id="countDropdown"
                                href="#"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                <i className="icon-bell" />
                                <span className="count" />
                            </a> */}
        <div
          className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list pb-0"
          aria-labelledby="countDropdown"
        >
          <a className="dropdown-item py-3">
            <p className="mb-0 font-weight-medium float-left">
              You have 7 unread mails{" "}
            </p>
            <span className="badge badge-pill badge-primary float-right">
              View all
            </span>
          </a>
          <div className="dropdown-divider" />
          <a className="dropdown-item preview-item" href="/">
            <div className="preview-thumbnail">
              <img
                src="images/faces/face10.jpg"
                alt="image"
                className="img-sm profile-pic"
              />
            </div>
            <div className="preview-item-content flex-grow py-2">
              <p className="preview-subject ellipsis font-weight-medium text-dark">
                Marian Garner{" "}
              </p>
              <p className="fw-light small-text mb-0">
                {" "}
                The meeting is cancelled{" "}
              </p>
            </div>
          </a>
          <a className="dropdown-item preview-item" href="/">
            <div className="preview-thumbnail">
              <img
                src="images/faces/face12.jpg"
                alt="image"
                className="img-sm profile-pic"
              />
            </div>
            <div className="preview-item-content flex-grow py-2">
              <p className="preview-subject ellipsis font-weight-medium text-dark">
                David Grey{" "}
              </p>
              <p className="fw-light small-text mb-0">
                {" "}
                The meeting is cancelled{" "}
              </p>
            </div>
          </a>
          <a className="dropdown-item preview-item" href="/">
            <div className="preview-thumbnail">
              <img
                src="images/faces/face1.jpg"
                alt="image"
                className="img-sm profile-pic"
              />
            </div>
            <div className="preview-item-content flex-grow py-2">
              <p className="preview-subject ellipsis font-weight-medium text-dark">
                Travis Jenkins{" "}
              </p>
              <p className="fw-light small-text mb-0">
                {" "}
                The meeting is cancelled{" "}
              </p>
            </div>
          </a>
        </div>
      </li>
      <li className="nav-item dropdown d-none d-lg-block user-dropdown">
        <a
          className="nav-link"
          id="UserDropdown"
          href="#"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <img
            className="img-xs rounded-circle"
            src="images/faces/face8.jpg"
            alt="Profile image"
          />
        </a>
        <div
          className="dropdown-menu dropdown-menu-right navbar-dropdown"
          aria-labelledby="UserDropdown"
        >
          <div className="dropdown-header text-center">
            <img
              className="img-md rounded-circle"
              src="images/faces/face8.jpg"
              alt="Profile image"
            />
            <p className="mb-1 mt-3 font-weight-semibold">Allen Moreno</p>
            <p className="fw-light text-muted mb-0">allenmoreno@gmail.com</p>
          </div>
          <a className="dropdown-item" href="/">
            <i className="dropdown-item-icon mdi mdi-account-outline text-primary me-2" />{" "}
            My Profile <span className="badge badge-pill badge-danger">1</span>
          </a>
          <a className="dropdown-item" href="/">
            <i className="dropdown-item-icon mdi mdi-message-text-outline text-primary me-2" />{" "}
            Messages
          </a>
          <a className="dropdown-item" href="/">
            <i className="dropdown-item-icon mdi mdi-calendar-check-outline text-primary me-2" />{" "}
            Activity
          </a>
          <a className="dropdown-item" href="/">
            <i className="dropdown-item-icon mdi mdi-help-circle-outline text-primary me-2" />{" "}
            FAQ
          </a>
          <a className="dropdown-item" href="/">
            <i className="dropdown-item-icon mdi mdi-power text-primary me-2" />
            Sign Out
          </a>
        </div>
      </li>
    </ul>
    <button
      className="navbar-toggler offcanvas navbar-toggler-right d-lg-none align-self-center"
      type="button"
      data-bs-toggle="offcanvas"
    >
      <span className="mdi mdi-menu" />
    </button>
  </div>
</nav>;

export default TopBar;
