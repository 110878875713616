import React from 'react'

const Footer = () => {
    return (
        <footer className="footer">
            <div className="d-sm-flex justify-content-center justify-content-sm-between">
                <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">
                    Design and Developed by &nbsp;
                    <a href="https://www.quality-programmers.com/" target="_blank">
                        Quality Programmers
                    </a>
                </span>
                <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">
                    Copyright © 2021. All rights reserved.
                </span>
            </div>
        </footer>
    )
}

export default Footer