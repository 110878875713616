import React, { useEffect, useState, useContext } from "react";
import $ from "jquery";
import Footer from "../../Components/Footer";



import FullCalendar, { formatDate } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { INITIAL_EVENTS, createEventId } from './event-utils'
import './main.css'

import { Context } from "../../Context/DataContext";
import { apiRequest } from "../../utils/apiCalls";
import { doConsole, useForceUpdate } from "../../utils/functions";
import Loader from "../../Components/Loader";
import { acolors } from "../../css/AppColors";
import { useHistory } from "react-router-dom";
import { ErrorAlert, SuccessAlert } from "../../Components/Alerts";
import { useLocation } from "react-router-dom";

import { Modal, Button, Form, Col, Row, Container } from "react-bootstrap";



const AppointmentCalender = () => {

  const [weekendsVisible, setWeekendsVisible] = useState(false);


  const currentEvents = [
    {
      id: 1,
      start: "2022-01-20",
      end: "2022-01-21",
      eventClasses: "optionalEvent",
      title: "test event",
      description: "This is a test description of an event",
    },
    {
      id: 2,
      start: "2015-07-19",
      end: "2015-07-25",
      title: "test event",
      description: "This is a test description of an event",
      data: "you can add what ever random data you may want to use later",
    },
  ];


  const { state } = useContext(Context);
  const forceUpdate = useForceUpdate();
  const history = useHistory();
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false)
  const [modalData, setModalData] = useState()

  function getAppointments() {
    setLoading(true);
    const reqObj = {
      token: state.garageData.token,
      g_id: state.garageData.g_id,
    };
    doConsole(reqObj)
    apiRequest(reqObj, "get_appoints")
      .then((data) => {
        doConsole(data);
        setLoading(false);
        if (data.status == "1") {
          // doConsole(data.data);
          // setErrorMessage(data.data);
          let arr = data.data;
          arr.color = 'red'
          arr.textColor = 'black'
          doConsole(arr);
          setData(arr);
        } else {
          setErrorMessage(data.error_msg);
          forceUpdate();
          window.scrollTo(0, 0);
        }
      })
      .catch((err) => {
        doConsole(err);
        setLoading(false);
      });
  }

  const handleEventClick = (clickInfo) => {
    if (alert(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
      clickInfo.event.remove()
    }
  }

  const handleDateSelect = (selectInfo) => {
    let title = prompt('Please enter a new title for your event')
    let calendarApi = selectInfo.view.calendar

    calendarApi.unselect() // clear date selection

    if (title) {
      calendarApi.addEvent({
        id: createEventId(),
        title,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        allDay: selectInfo.allDay,
      })
    }
  }


  useEffect(() => {
    getAppointments();
  }, []);

  React.useEffect(() => {
    $("#appointments").addClass("show");
  }, []);

  const renderEventContent = (event) => {
    return (
      <div style={{width:100,flexWrap:'wrap',display:'flex',}}>
        {/* <b>{event.endStr}</b> */}
        <b style={{color:'black' ,width:100, }} >{event.event.title}</b>
      </div>
    )
  }



  function renderSidebarEvent(event) {
    return (
      <li key={event.id}>
        <b>{formatDate(event.start, { year: 'numeric', month: 'short', day: 'numeric' })}</b>
        <i>{event.start}</i>
      </li>
    )
  }





  return (
    <div className="main-panel">
      {loading && <Loader />}
      {errorMessage && (<ErrorAlert onClose={() => setErrorMessage("")} errorMessage={errorMessage} />)}
      {success && (<SuccessAlert message={"Hurry! Your vehicle has been updated!"} />)}


      <div className="content-wrapper">
        <Modal
          show={showModal}
        >
          <Modal.Header>
            <Modal.Title>
              <b>Date</b> : {modalData?.appoint_date} <br /> <br />
              <b>Appointment Time:  </b>{modalData?.appoint_time}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <b>Cust Name: </b>{modalData?.cust_fname} {modalData?.cust_lname}<br />
            <b>Cust Phone:  </b>{modalData?.cust_phone} <br />
            <b>Status:  </b>{modalData?.appoint_status} <br />
            <b>Vehicle Reg No:  </b>{modalData?.v_reg_no} <br />

            <b>Remarks:  </b>{modalData?.appoint_remarks} <br />
          </Modal.Body>

          <Modal.Footer>
            <Button
              onClick={() => setShowModal(false)}
              variant="secondary">Close</Button>
            <Button
              variant="primary"
              style={{ backgroundColor: acolors.primary, color: 'white', borderWidth: 0 }}
              onClick={() => {
                history.push('editAppointment', {
                  data: modalData
                })
              }}
            >View/Edit</Button>
          </Modal.Footer>
        </Modal>
        <div className="row">
          <div className="col-sm-12">
            <div className="home-tab">
              <div className="tab-content tab-content-basic">
                <div
                  className="tab-pane fade show active"
                  id="overview"
                  role="tabpanel"
                  aria-labelledby="overview"
                >
                  <div className="row">
                    <div className="col-12">
                      <h3 className="text-black fw-bold">
                        Appointment Calendar
                      </h3>
                      <br />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 col-md-12 d-flex flex-column">
                      <div className="row flex-grow">
                        <div className="col-12 col-md-12 col-lg-12 grid-margin stretch-card">
                          <div className="card card-rounded">
                            {/* <div className="card-body text-center"> */}
                            {
                              data.length > 0 &&

                              <div className='demo-app-main'  >
                                <FullCalendar
                                  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                  headerToolbar={{
                                    left: 'prev,next today',
                                    center: 'title',
                                    right: 'dayGridMonth,timeGridWeek,timeGridDay',
                                  }}
                                  eventTextColor="white"
                                  eventColor={acolors.primary}
                                  // eventTextColor="red"
                                  
                                  eventBackgroundColor="#000000"

                                  // day
                                  // allDayText="#ffffff"
                                  // buttonText={"#ffffff"}
                                  // weekText="#ffffff"
                                  // text
                                  
                                  initialView='dayGridMonth'
                                  editable={true}
                                  selectable={true}
                                  // selectMirror={true}
                                  dayMaxEvents={true}
                                  weekends={true}
                                  events={data} // alternatively, use the `events` setting to fetch from a feed
                                  // currentEvents={data}
                                  // select={handleDateSelect}
                                  // eventContent={renderEventContent} // custom render function
                                  eventClick={(e) => {
                                    const arr = data.filter(v => v.appoint_id == e.event.id);
                                    console.log(arr)
                                    setModalData(arr[0])
                                    forceUpdate()
                                    setShowModal(true)
                                  }}
                                /* you can update a remote database when these fire:
                                
                                eventChange={function () { }}
                                eventRemove={function () { }}
                                */
                                />
                              </div>
                            }
                            {/* <img className="img-fluid" src={Calendar}></img> */}
                          </div>
                          {/* </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* content-wrapper ends */}
      {/* partial:partials/_footer.html */}
      <Footer />
      {/* partial */}
    </div>
  );
};

export default AppointmentCalender;
