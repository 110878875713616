import React, { useEffect, useState, useContext } from "react";
import $ from "jquery";

import SideBar from "../../Components/SideBar";
import TopBar from "../../Components/TopBar";
import Footer from "../../Components/Footer";
import SettingPanel from "../../Components/SettingPanel";

import { Link } from "react-router-dom";
import { Context } from "../../Context/DataContext";
import { apiRequest } from "../../utils/apiCalls.js";
import { doConsole, useForceUpdate } from "../../utils/functions.js";
import Loader from "../../Components/Loader.js";
import { ErrorAlert, SuccessAlert } from "../../Components/Alerts.js";
import { acolors } from "../../css/AppColors";

const ManageAccounts = () => {
  const forceUpdate = useForceUpdate();
  const { state } = useContext(Context);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [staffData, setStaffData] = useState([]);
  const [delId, setDelId] = useState("");
  const [delModal, setDelModal] = useState("");

  const [accountTypes, setAccountTypes] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [postData, setPostData] = useState({
    g_id: state.garageData.g_id,
    token: state.garageData.token,
    at_id: "", //account type id
    a_code: "",
    a_balance: "0", //initial balance
    a_title: "", // account title
    a_status: "1",
  });

  function get_account_types() {
    setLoading(true);
    const reqObj = {
      token: state.garageData.token,
      g_id: state.garageData.g_id,
    };
    apiRequest(reqObj, "get_account_types")
      .then((data) => {
        doConsole(data);
        setLoading(false);
        if (data.status == "1") {
          doConsole(data.data);
          setAccountTypes(data.data);
        } else {
          setErrorMessage(data.error_msg);
          forceUpdate();
          window.scrollTo(0, 0);
        }
      })
      .catch((err) => {
        doConsole(err);
        setLoading(false);
      });
  }

  function get_accounts() {
    setLoading(true);
    const reqObj = {
      token: state.garageData.token,
      g_id: state.garageData.g_id,
    };
    apiRequest(reqObj, "get_accounts")
      .then((data) => {
        doConsole(data);
        setLoading(false);
        if (data.status == "1") {
          doConsole(data.data);
          setAccounts(data.data);
        } else {
          setErrorMessage(data.error_msg);
          forceUpdate();
          window.scrollTo(0, 0);
        }
      })
      .catch((err) => {
        doConsole(err);
        setLoading(false);
      });
  }

  function doDel() {
    const reqObj = {
      token: state.garageData.token,
      g_id: state.garageData.g_id,
      st_id: delId,
    };
    setLoading(true);
    apiRequest(reqObj, "delete_staff")
      .then((data) => {
        doConsole(data);
        setLoading(false);
        if (data.status == "1") {
          if (data.data) {
            setStaffData(data.data);
          }
        } else {
          setErrorMessage(data.error_msg);
          window.scrollTo(0, 0);
        }
      })
      .catch((err) => {
        doConsole(err);
        setLoading(false);
      });
  }

  function doAddAccount(event) {
    event.preventDefault();
    doConsole(postData);
    setLoading(true);
    apiRequest(postData, "add_account")
      .then((data) => {
        doConsole(data);
        setLoading(false);
        if (data.status == "1") {
          setErrorMessage("");
          setSuccess(true);
          window.scrollTo(1, 1);
          //   setTimeout(() => {
          //     history.push("/listStaff");
          //   }, 1500);
        } else {
          setErrorMessage(data.error_msg);
          forceUpdate();
          window.scrollTo(0, 0);
          // $("#datepicker-popup").focus();
        }
      })
      .catch((err) => {
        doConsole(err);
        setLoading(false);
      });
  }

  useEffect(() => {
    // getStaff();
    get_account_types();
    get_accounts();
  }, []);

  useEffect(() => {
    // $(document).ready(function () {
    //     $('#dataTable').DataTable();
    // });
    $("#accounts").addClass("show");
  }, [loading]);

  return (
        <div className="main-panel">
          <div className="content-wrapper">
            {loading && <Loader />}
            {errorMessage && (
              <ErrorAlert
                onClose={() => setErrorMessage("")}
                errorMessage={errorMessage}
              />
            )}
            {success && (
              <SuccessAlert message={"Hurry! Your vehicle has been saved!"} />
            )}
            <div className="row">
              <div className="col-sm-12">
                <div className="home-tab">
                  <div className="tab-content tab-content-basic">
                    <div
                      className="tab-pane fade show active"
                      id="overview"
                      role="tabpanel"
                      aria-labelledby="overview"
                    >
                      <div className="row">
                        <div className="col-12">
                          {/* <h3 className="text-black fw-bold">
                            Manage Accounts
                          </h3> */}
                          <br />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12 col-md-12 d-flex flex-column">
                          <div className="row flex-grow">
                            <div className="col-12 col-md-12 col-lg-12 grid-margin stretch-card">
                              <div className="card card-rounded">
                                <div className="card-body">
                                  <div className="d-sm-flex justify-content-between align-items-start">
                                    <div className="row w-100">
                                      <h3 className="text-black fw-bold">
                                        Add Account
                                      </h3>
                                      <form
                                        onSubmit={(event) =>
                                          doAddAccount(event)
                                        }
                                        className="forms-sample"
                                      >
                                        <div className="form-group col-12  col-lg-4 col-md-4 col-sm-6 float-left p-2">
                                          <label for="exampleSelectGender">
                                            Account Type
                                          </label>
                                          <select
                                            onChange={(t) => {
                                              let val = t.target.value;
                                              let find = accountTypes.find(
                                                (item) => item.at_id == val
                                              );
                                              let arr = postData;
                                              arr.a_code = find.next_code;
                                              arr.at_id = val;
                                              setPostData(arr);
                                              forceUpdate();
                                            }}
                                            // required
                                            value={postData.at_id}
                                            className="form-control"
                                            id="exampleSelectGender"
                                            name="debit_ac[]"
                                          >
                                            <option
                                              value
                                              selected
                                              disabled={
                                                postData.at_id ? true : false
                                              }
                                            >
                                              Select
                                            </option>
                                            {accountTypes?.map((v, i) => {
                                              return (
                                                <option key={i} value={v.at_id}>
                                                  {v.at_title}
                                                </option>
                                              );
                                            })}

                                            {/* <option>Liability</option>
                                            <option>Income</option>
                                            <option>Expense</option>
                                            <option>Vehicle</option> */}
                                          </select>
                                        </div>
                                        <div className="form-group col-12  col-lg-4 col-md-4 col-sm-12 float-left p-2">
                                          <label for="exampleInputName1">
                                            Account Code
                                          </label>
                                          <input
                                            onChange={(t) => {
                                              setPostData({
                                                ...postData,
                                                a_code: t.target.value,
                                              });
                                            }}
                                            required
                                            value={postData.a_code}
                                            type="text"
                                            className="form-control p-1"
                                            id="exampleInputName1"
                                            placeholder="Account Title"
                                          />
                                        </div>
                                        <div className="form-group col-12  col-lg-4 col-md-5 col-sm-12 float-left p-2">
                                          <label for="exampleInputName1">
                                            Initial Balance
                                          </label>
                                          <input
                                            onChange={(t) => {
                                              setPostData({
                                                ...postData,
                                                a_balance: t.target.value,
                                              });
                                            }}
                                            required
                                            value={postData.a_balance}
                                            type="text"
                                            className="form-control p-1"
                                            id="exampleInputName1"
                                            placeholder="Account Title"
                                          />
                                        </div>
                                        <div className="form-group col-12  col-lg-8 col-md-8 col-sm-12 float-left p-2">
                                          <label for="exampleInputName1">
                                            Account Title
                                          </label>
                                          <input
                                            onChange={(t) => {
                                              setPostData({
                                                ...postData,
                                                a_title: t.target.value,
                                              });
                                            }}
                                            required
                                            value={postData.a_title}
                                            type="text"
                                            className="form-control p-1"
                                            id="exampleInputName1"
                                            placeholder="Account Title"
                                          />
                                        </div>

                                        <div
                                          className="col-12 col-sm-12 col-lg-1 col-md-1 text-center  float-left"
                                          style={{ paddingTop: "40px" }}
                                        >
                                          <input
                                            type="submit"
                                            title="Save"
                                            className="btn me-2  "
                                            style={{
                                              backgroundColor: acolors.primary,
                                              color: "white",
                                            }}
                                          />
                                        </div>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row flex-grow">
                            <div className="col-12 col-md-12 col-lg-12 grid-margin stretch-card">
                              <div className="card card-rounded text-center pt-2">
                                <div className="card-body">
                                  <h3 className="text-black fw-bold">
                                    Chart of Account
                                  </h3>
                                  <div className="d-sm-flex justify-content-between align-items-start">
                                    <div className="row w-100">
                                      {/* Page Heading */}
                                      <div>
                                        {/* DataTales Example */}
                                        {/* <div className="card shadow mb-4" style={{ width: "100%" }} > */}
                                        <div className="card-body">
                                          <div className="table-responsive">
                                            {/* table className="table table-bordered dt-responsive" id="dataTable" width="100%" cellspacing="0" */}
                                            <table
                                              id="dataTable"
                                              className="table table-striped table-bordered dt-responsive"
                                              style={{ width: "100%" }}
                                            >
                                              <thead>
                                                <tr>
                                                  <th className="tRow">
                                                    Account Title
                                                  </th>
                                                  <th className="tRow">
                                                    Account Type
                                                  </th>
                                                  <th className="tRow">
                                                    Action
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {accounts?.map((v) => {
                                                  return (
                                                    <tr>
                                                      <td className="text-justify">
                                                        {v.a_title}
                                                      </td>
                                                      <td className="text-justify">
                                                        {v.at_title}
                                                      </td>
                                                      <td className="text-center">
                                                        <a
                                                          href="/ManageAccounts"
                                                          title="Edit Account"
                                                          className="btn btn-info"
                                                        >
                                                          <i className="fas fa-pencil-alt"></i>
                                                        </a>{" "}
                                                        <a
                                                          href="/ManageAccounts"
                                                          title="Delete Account"
                                                          className="btn btn-danger del_customer"
                                                          id="3"
                                                        >
                                                          <i className="fas fa-trash"></i>
                                                        </a>
                                                      </td>
                                                    </tr>
                                                  );
                                                })}
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                        {/* </div> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* content-wrapper ends */}
          {/* partial:partials/_footer.html */}
          <Footer />
    </div>
  );
};
export default ManageAccounts;
