import React, { useEffect, useContext, useState } from "react";
import $ from "jquery";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";

import SideBar from "../../Components/SideBar";
import TopBar from "../../Components/TopBar";
import Footer from "../../Components/Footer";
import SettingPanel from "../../Components/SettingPanel";

// import "../../js/chart.js";

import { Link, useHistory, useLocation } from "react-router-dom";
import { Context } from "../../Context/DataContext";
import { apiRequest } from "../../utils/apiCalls.js";
import { doConsole, useForceUpdate } from "../../utils/functions.js";
import Loader from "../../Components/Loader.js";
import { ErrorAlert, SuccessAlert } from "../../Components/Alerts.js";
import { acolors } from "../../css/AppColors";
import Chart from "../../vendors/chart.js/Chart.min.js";

const ProfileTarget = () => {

    const forceUpdate = useForceUpdate();
    const history = useHistory();
    const location = useLocation();
    const { state } = useContext(Context);
    const [errorMessage, setErrorMessage] = useState("");
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    const [yearlySaleTarget, setYearlySaleTarget] = useState([]);
    const [monthlySaleTarget, setMonthlySaleTarget] = useState([]);

    const [sale_qty, setSale_qty] = useState([]); //  Actual car sale target , we achieve  "red"
    const [tp_qty, setTp_qty] = useState([]); //Planner car sale target "blue" target planner

    const [sale_amount, setSale_amount] = useState([]);
    const [tp_profit, setTp_profit] = useState([]);

    const labels = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30",]

    var carSaleByNumber = {
        labels,
        datasets: [
            {
                label: "Progress",
                data: sale_qty,
                borderColor: ["#587ce4"],
                borderWidth: 2,
                fill: false,
            },
            {
                label: "Target",
                data: tp_qty,
                // [2, 3, 7, 8, 9, 13, 14, 15, 17, 20, 22, 27],

                borderColor: ["#ff0000"],
                borderWidth: 2,
                fill: false,
            },
        ],
    };

    var carSaleByVolume = {
        labels,
        datasets: [
            {
                label: "Progress",
                data: sale_amount,
                borderColor: ["#587ce4"],
                borderWidth: 2,
                fill: false,
            },
            {
                label: "Target",
                data: tp_profit,
                borderColor: ["#ff0000"],
                borderWidth: 2,
                fill: false,
            },
        ],
    };



    var options = {
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
        },
        legend: {
            display: true,
        },
        elements: {
            point: {
                radius: 2,
            },
        },
    };


    function getMonthlySaleTarget() {
        const reqObj = {
            g_id: state?.garageData?.g_id,
            token: state?.garageData?.token,
            tp_year: "2022",
            tp_month: "01",
        };

        setLoading(true);
        apiRequest(reqObj, "get_monthly_targets")
            .then((data) => {
                doConsole(data);
                setLoading(false);
                if (data.status == "1") {
                    let data1 = data.data;
                    var sale_qtyArr = [];
                    var tp_qtyArr = [];
                    var sale_amountArr = [];
                    var tp_profitArr = [];

                    for (let i = 0; i < data1.length; i++) {
                        sale_qtyArr[i] = data1[i].sale_qty;
                        tp_qtyArr[i] = data1[i].tp_qty;
                        sale_amountArr[i] = data1[i].sale_amount;
                        tp_profitArr[i] = data1[i].tp_profit;
                    }
                    setSale_qty(sale_qtyArr);
                    setTp_qty(tp_qtyArr);
                    setSale_amount(sale_amountArr);
                    setTp_profit(tp_profitArr);

                    // forceUpdate();

                    setErrorMessage("");
                    window.scrollTo(0, 0);
                } else {
                    setErrorMessage(data.error_msg);
                    window.scrollTo(0, 0);
                    forceUpdate();
                }
            })
            .catch((err) => {
                doConsole(err);
                setLoading(false);
            });
    }

    useEffect(() => {

        $("#carSaleByNumber");
        {
          var multiLineCanvas = $("#carSaleByNumber").get(0).getContext("2d");
          var lineChart = new Chart(multiLineCanvas, {
            type: "line",
            data: carSaleByNumber,
            options: options,
          });
        }
    
        $("#carSaleByVolume");
        {
          var multiLineCanvas = $("#carSaleByVolume").get(0).getContext("2d");
          var lineChart = new Chart(multiLineCanvas, {
            type: "line",
            data: carSaleByVolume,
            options: options,
          });
        }


        return () => { };
    }, [tp_qty, sale_amount]);

    useEffect(() => {
        getMonthlySaleTarget();
    }, []);

    return (
        <div className="main-panel">
            <div className="content-wrapper">
                {loading && <Loader />}
                {errorMessage && (
                    <ErrorAlert
                        onClose={() => setErrorMessage("")}
                        errorMessage={errorMessage}
                    />
                )}
                {/* {success && (<SuccessAlert message={"Hurry! Target has been added!"} />)} */}

                <div className="row">
                    <div className="col-sm-12">
                        <div className="home-tab">
                            <div className="tab-content tab-content-basic">
                                <div
                                    className="tab-pane fade show active"
                                    id="overview"
                                    role="tabpanel"
                                    aria-labelledby="overview"
                                >
                                    <div className="row">
                                        <div className="col-12">
                                            <h3 className="text-black fw-bold">
                                                Vehicles Sale Target
                                            </h3>
                                            <br />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 d-flex flex-column">
                                            <div className="row flex-grow">
                                                <div className="col-12 col-md-12 col-lg-12 grid-margin stretch-card">
                                                    <div className="card card-rounded text-center pt-2">
                                                        <div className="card-body">
                                                            <div className="d-sm-flex justify-content-between align-items-start">
                                                                <div className="row w-100">
                                                                    {/* Page Heading */}
                                                                    <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                                        <div className="card">
                                                                            <div className="card-body">
                                                                                <h4 className="card-title">
                                                                                    Car sale by number - 2021
                                                                                </h4>
                                                                                <canvas id="carSaleByNumber"></canvas>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                                                        <div className="card">
                                                                            <div className="card-body">
                                                                                <h4 className="card-title">
                                                                                    Car sale by volume - 2021
                                                                                </h4>
                                                                                <canvas id="carSaleByVolume"></canvas>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    {/* <div>
                                                                        <div className="card">
                                                                            <div className="card-body">
                                                                                <h4 className="card-title">
                                                                                    Car Sale Chart - Jan. 2021
                                                                                </h4>
                                                                                <canvas id="monthlySaleChart"></canvas>
                                                                            </div>
                                                                        </div>
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* content-wrapper ends */}
            {/* partial:partials/_footer.html */}
            <Footer />
            {/* partial */}
        </div>
    );
};

export default ProfileTarget;
