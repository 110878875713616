import React, { useState, useContext, useEffect } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";

import Footer from "../../Components/Footer";
import '../Appointments/main.css'

import { Context } from "../../Context/DataContext";
import { apiRequest } from "../../utils/apiCalls";
import { doConsole, useForceUpdate } from "../../utils/functions";
import Loader from "../../Components/Loader";
import { acolors } from "../../css/AppColors";
import { useHistory } from "react-router-dom";
import { ErrorAlert, SuccessAlert } from "../../Components/Alerts";
import { useLocation } from "react-router-dom";

import { Modal, Button, Col, Row, Container } from "react-bootstrap";


const EditVehicle = () => {
  const { state } = useContext(Context);
  const forceUpdate = useForceUpdate();
  const history = useHistory();
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [vSData, setVSData] = useState([]);
  const [vData, setVData] = React.useState({
    v_id: location?.state?.data?.v_id,
    file_no: location?.state?.data?.file_no,
    v_reg_no: location?.state?.data?.v_reg_no,
    vs_id: location?.state?.data?.vs_id,
    v_purchase_date: location?.state?.data?.v_purchase_date,
    v_sale_date: location?.state?.data?.v_sale_date,
    v_mot: location?.state?.data?.v_mot,
    v_info_url: location?.state?.data?.v_info_url,
    v_make_model: location?.state?.data?.v_make_model,
    v_price: location?.state?.data?.v_price,
    v_prep_expenses: location?.state?.data?.v_prep_expenses,
    v_admin_fee: location?.state?.data?.v_admin_fee,
    v_delivery_charges: location?.state?.data?.v_delivery_charges,
    v_total_price: location?.state?.data?.v_total_price,
    v_sale_price: location?.state?.data?.v_sale_price,
    v_key_slot: location?.state?.data?.v_key_slot,
    token: state.garageData.token,
    g_id: state.garageData.g_id,
    v_price: location?.state?.data?.v_price,
    v_mileage: location?.state?.data?.v_mileage,

    collection_date: location?.state?.data?.collection_date ? location?.state?.data?.collection_date : "",
    collection_time: location?.state?.data?.collection_time ? location?.state?.data?.collection_time : "",
    collection_note: location?.state?.data?.collection_note ? location?.state?.data?.collection_note : "",
    is_serviced: location?.state?.data?.is_serviced ? location?.state?.data?.is_serviced : "0",
    serviced_tasks: location?.state?.data?.serviced_tasks ? location?.state?.data?.serviced_tasks : "",
    veh_color: location?.state?.data?.veh_color ? location?.state?.data?.veh_color : "",

    cust_name: location?.state?.data?.cust_name ? location?.state?.data?.cust_name : '',
    cust_email: location?.state?.data?.cust_email ? location?.state?.data?.cust_email : '',
    cust_phone: location?.state?.data?.cust_phone ? location?.state?.data?.cust_phone : '',

    sold_note: location?.state?.data?.sold_note ? location?.state?.data?.sold_note : '',
    deliver_status : location?.state?.data?.deliver_status ? location?.state?.data?.deliver_status : '',

  });

  const [vehStatusName, setVehStatusName] = useState("");
  const [collectionModal, setCollectionModal] = useState('');

  const [totalPrice, setTotalPrice] = useState(
    location?.state?.data?.v_total_price
  );
  const [salePrice, setSalePrice] = useState(
    location?.state?.data?.v_sale_price
  );
  const [profit, setProfit] = useState(location?.state?.data?.v_profit);

  function getVS() {
    setLoading(true);
    const reqObj = {
      token: state.garageData.token,
      g_id: state.garageData.g_id,
    };
    apiRequest(reqObj, "get_veh_statuses")
      .then((data) => {
        doConsole(data);
        setLoading(false);
        if (data.status == "1") {
          doConsole(data.data);
          setVSData(data.data);
          //   forceUpdate();
        } else {
          setErrorMessage(data.error_msg);
          forceUpdate();
          window.scrollTo(0, 0);
        }
      })
      .catch((err) => {
        doConsole(err);
        setLoading(false);
      });
  }

  function addV(event) {
    event.preventDefault();
    vData.v_total_price = totalPrice;
    vData.v_sale_price = salePrice;
    vData.v_profit = profit;

    if (vData.is_serviced == '0') vData.serviced_tasks = '';
    if (vData.vs_id != '4' && vData.vs_id != '5' && vData.vs_id != '9') {
      vData.collection_date = '';
      vData.collection_note = '';
      vData.collection_time = '';
      vData.cust_email = '';
      vData.cust_name = '';
      vData.cust_phone = '';
    }
    forceUpdate();
    doConsole(vData);
    setLoading(true);
    apiRequest(vData, "edit_vehicle")
      .then((data) => {
        doConsole(data);
        setLoading(false);
        if (data.status == "1") {
          setErrorMessage("");
          setSuccess(true);
          window.scrollTo(0, 0);
          setTimeout(() => {
            history.push("/listVehicle");
          }, 1000);
        } else {
          setErrorMessage(data.error_msg);
          forceUpdate();
          window.scrollTo(0, 0);
          // $("#datepicker-popup").focus();
        }
      })
      .catch((err) => {
        doConsole(err);
        setLoading(false);
      });
  }

  function calcTotalPrice() {
    var calcPrice = parseInt(vData.v_price) + parseInt(vData.v_prep_expenses);
    // parseInt(vData.v_admin_fee) +
    // parseInt(vData.v_delivery_charges);
    setTotalPrice(calcPrice);
    forceUpdate();
  }
  function calcSalePrice() {
    // sale_price = total_price + admin_fee + delivery_charges + profit;
    var calcPrice =
      parseInt(totalPrice) +
      parseInt(vData.v_admin_fee) +
      parseInt(vData.v_delivery_charges) +
      parseInt(profit);
    setSalePrice(calcPrice);
    forceUpdate();
  }
  function calcProfit(v) {
    var calcPrice =
      parseInt(v) -
      parseInt(totalPrice) -
      parseInt(vData.v_delivery_charges) -
      parseInt(vData.v_admin_fee);
    setProfit(calcPrice);
    forceUpdate();
  }
  useEffect(() => {
    getVS();
    // fetch_veh_data();
  }, []);
  return (
    <div className="main-panel">
      <div className="content-wrapper">
        {loading && <Loader />}
        {errorMessage && (
          <ErrorAlert
            onClose={() => setErrorMessage("")}
            errorMessage={errorMessage}
          />
        )}
        {success && (
          <SuccessAlert message={"Hurry! Your vehicle has been saved!"} />
        )}

        {/* <Modal
          show={collectionModal}
          dialogClassName="my-modal"
          className="col-12 col-sm-12 col-md-12 col-lg-12 float-left p-2"
        >
          <Modal.Header>
            <Modal.Title>
              <b>Collection Modal</b>
              
            </Modal.Title>
          </Modal.Header>

          <Modal.Body
            className="col-12 col-sm-12 col-md-12 col-lg-12 float-left p-2"
          >
          
          </Modal.Body>

          <Modal.Footer>
            <Button
              onClick={() => {
                setVData({
                  ...vData,
                  vs_id: location?.state?.data?.vs_id
                })
                forceUpdate();
                setCollectionModal(false)
              }}
              variant="secondary">Cancel</Button>
            <Button
              variant="primary"
              style={{ backgroundColor: acolors.primary, color: 'white', borderWidth: 0 }}
              onClick={() => {
                history.push('editAppointment', {
                  // data: modalData
                })
              }}
            >View/Edit</Button>
          </Modal.Footer>
        </Modal> */}

        <div className="row">
          <div className="col-sm-12">
            <div className="home-tab">
              <div className="tab-content tab-content-basic">
                <div
                  className="tab-pane fade show active"
                  id="overview"
                  role="tabpanel"
                  aria-labelledby="overview"
                >
                  <div className="row">
                    <div className="col-12">
                      <h3 className="text-black fw-bold">Edit Vehicle</h3>
                      <br />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 col-md-12 d-flex flex-column">
                      <div className="row flex-grow">
                        <div className="col-12 col-md-12 col-lg-12 grid-margin stretch-card">
                          <div className="card card-rounded">
                            <div className="card-body">
                              <div className="d-sm-flex justify-content-between align-items-start">
                                <div className="row w-100">
                                  <form
                                    onSubmit={(event) => addV(event)}
                                    className="forms-sample"
                                  >
                                    <div className="form-group col-12  col-lg-3 col-md-3 col-sm-12 float-left p-2">
                                      <label for="exampleInputName1">
                                        File No.
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="exampleInputName1"
                                        placeholder="File No."
                                        required
                                        onChange={(v) => {
                                          setVData({
                                            ...vData,
                                            file_no: v.target.value,
                                          });
                                        }}
                                        value={vData.file_no}
                                      />
                                    </div>
                                    <div
                                      className="form-group col-12 col-sm-12 col-md-3 col-lg-3 float-left p-2"
                                    >
                                      <label for="exampleInputName1">
                                        Registration No
                                      </label>
                                      <input
                                        required
                                        onChange={(t) => {
                                          doConsole(t.target.value);
                                          setVData({
                                            ...vData,
                                            v_reg_no: t.target.value,
                                          });
                                        }}
                                        value={vData.v_reg_no}
                                        type="text"
                                        className="form-control"
                                        id="exampleInputName1"
                                        placeholder="Description"
                                      />
                                    </div>

                                    <div
                                      className="form-group col-12 col-sm-12 col-md-3 col-lg-3 float-left p-2"
                                    >
                                      <label for="exampleInputName1">
                                        Vehicle Color
                                      </label>
                                      <input
                                        onChange={(t) => {
                                          setVData({
                                            ...vData,
                                            veh_color: t.target.value,
                                          });
                                        }}

                                        value={vData.veh_color}
                                        type="text"
                                        className="form-control"
                                        id="exampleInputName1"
                                      />
                                    </div>

                                    <div
                                      className="form-group col-12 col-sm-12 col-md-3 col-lg-3 float-left p-2"
                                    >
                                      <label for="exampleInputName1">
                                        Mileage
                                      </label>
                                      <input
                                        required
                                        onChange={(t) => {
                                          doConsole(t.target.value);
                                          setVData({
                                            ...vData,
                                            v_mileage: t.target.value,
                                          });
                                        }}
                                        value={vData.v_mileage}
                                        type="text"
                                        className="form-control"
                                        id="exampleInputName1"
                                      />
                                    </div>




                                    <div
                                      className="form-group col-12 col-sm-12 col-md-4 col-lg-4 float-left p-2"
                                    >
                                      <label for="exampleInputName1">
                                        Purchase date
                                      </label>
                                      <div
                                        id="datepicker-popup"
                                        className="input-group date datepicker navbar-date-picker"
                                      >
                                        <span className="input-group-addon input-group-prepend border-right">
                                          <span className="icon-calendar input-group-text calendar-icon"></span>
                                        </span>
                                        <input
                                          required
                                          onChange={(t) => {
                                            doConsole(t.target.value);
                                            setVData({
                                              ...vData,
                                              v_purchase_date: t.target.value,
                                            });
                                          }}
                                          value={vData.v_purchase_date}
                                          type="date"
                                          className="form-control"
                                          placeholder="dd/mm/yyyy"
                                        />
                                      </div>
                                    </div>
                                    <div
                                      className="form-group col-12 col-sm-12 col-md-4 col-lg-4 float-left p-2"
                                    >
                                      <label for="exampleInputName1">
                                        Sale date
                                      </label>
                                      <div
                                        id="datepicker-popup"
                                        className="input-group date datepicker navbar-date-picker"
                                      >
                                        <span className="input-group-addon input-group-prepend border-right">
                                          <span className="icon-calendar input-group-text calendar-icon"></span>
                                        </span>
                                        <input
                                          onChange={(t) => {
                                            doConsole(t.target.value);
                                            setVData({
                                              ...vData,
                                              v_sale_date: t.target.value,
                                            });
                                          }}
                                          value={vData.v_sale_date}
                                          type="date"
                                          className="form-control"
                                          placeholder="dd/mm/yyyy"
                                        />
                                      </div>
                                    </div>
                                    <div
                                      className="form-group col-12 col-sm-12 col-md-4 col-lg-4 float-left p-2"
                                    >
                                      <label for="exampleInputName1">MOT</label>
                                      <div
                                        id="datepicker-popup"
                                        className="input-group date datepicker navbar-date-picker"
                                      >
                                        <span className="input-group-addon input-group-prepend border-right">
                                          <span className="icon-calendar input-group-text calendar-icon"></span>
                                        </span>
                                        <input
                                          // required
                                          onChange={(t) => {
                                            doConsole(t.target.value);
                                            setVData({
                                              ...vData,
                                              v_mot: t.target.value,
                                            });
                                          }}
                                          value={vData.v_mot}
                                          type="date"
                                          className="form-control"
                                          placeholder="dd/mm/yyyy"
                                        />
                                      </div>
                                    </div>

                                    <div className="form-group col-12 col-lg-3 col-md-3 col-sm-12 float-left p-2">
                                      <label for="exampleSelectGender">
                                        Status
                                      </label>
                                      <select
                                        required
                                        onChange={(t) => {
                                          doConsole(t.target.value);
                                          if (t.target.value == 4 || t.target.value == 5) {
                                            setCollectionModal(true)
                                          }
                                          setVData({
                                            ...vData,
                                            vs_id: t.target.value,
                                          });
                                        }}
                                        value={vData.vs_id}
                                        className="form-control"
                                      // id="exampleSelectGender"
                                      >
                                        {vSData?.map((v) => {
                                          return (
                                            <option
                                              selected={
                                                v.vs_id ==
                                                  location?.state?.data?.vs_id
                                                  ? true
                                                  : false
                                              }
                                              value={v.vs_id}
                                            >
                                              {v.veh_status}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div>


                                    <div
                                      className="form-group col-12 col-sm-12 col-md-3 col-lg-3 float-left p-2"
                                    >
                                      <label for="exampleInputName1">
                                        Info Link
                                      </label>
                                      <input
                                        // required
                                        onChange={(t) => {
                                          doConsole(t.target.value);
                                          setVData({
                                            ...vData,
                                            v_info_url: t.target.value,
                                          });
                                        }}
                                        value={vData.v_info_url}
                                        type="text"
                                        className="form-control"
                                        id="exampleInputName1"
                                        placeholder="Info Link"
                                      />
                                    </div>
                                    <div
                                      className="form-group col-12 col-sm-12 col-md-4 col-lg-4 float-left p-2"
                                    >
                                      <label for="exampleInputName1">
                                        Make/Model
                                      </label>
                                      <input
                                        required
                                        onChange={(t) => {
                                          doConsole(t.target.value);
                                          setVData({
                                            ...vData,
                                            v_make_model: t.target.value,
                                          });
                                        }}
                                        value={vData.v_make_model}
                                        type="text"
                                        className="form-control"
                                        id="exampleInputName1"
                                        placeholder="Make/Model"
                                      />
                                    </div>

                                    <div
                                      className="form-group col-12 col-sm-12 col-md-2 col-lg-2 p-2 float-left"
                                    >
                                      <label for="exampleInputName1">
                                        Key slot
                                      </label>
                                      <input
                                        // required
                                        onChange={(t) => {
                                          doConsole(t.target.value);
                                          setVData({
                                            ...vData,
                                            v_key_slot: t.target.value,
                                          });
                                        }}
                                        value={vData.v_key_slot}
                                        type="text"
                                        className="form-control"
                                        id="exampleInputName1"
                                        placeholder="Key slot"
                                      />
                                    </div>

                                    {/* {
                                      location?.state?.data?.vs_id == 5  &&
                                      <> */}
                                        <div
                                          className="form-group col-12 col-sm-12 col-md-4 col-lg-4 p-2 "
                                        >
                                          <label for="exampleInputName1">Delivery Status</label>
                                          <select
                                            required
                                            onChange={(t) => {
                                              doConsole(t.target.value);
                                              setVData({
                                                ...vData,
                                                deliver_status: t.target.value,
                                              });
                                            }}
                                            value={vData.deliver_status}
                                            className="form-control"
                                          // id="exampleSelectGender"
                                          >
                                            <option value={"Pending"} >Pending</option>
                                            <option value={"Ready for collection"} >Ready for collection</option>
                                            <option value={"Delivered"} >Delivered</option>
                                          </select>
                                        </div>
                                        <div className="form-group col-12 col-sm-12 col-md-6 col-lg-6 p-2" ></div>
                                      {/* </>
                                    } */}
                                    <div
                                      className="form-group col-12 col-sm-12 col-md-4 col-lg-4 float-left p-2"
                                    >
                                      <label for="exampleInputName1">
                                        Price
                                      </label>
                                      <input
                                        required
                                        min={0}
                                        onChange={(t) => {
                                          setVData({
                                            ...vData,
                                            v_price: t.target.value,
                                          });
                                        }}
                                        onBlur={(t) => {
                                          calcTotalPrice();
                                          // calcSalePrice();
                                        }}
                                        value={vData.v_price}
                                        type="number"
                                        className="form-control"
                                        id="exampleInputName1"
                                        placeholder="Price"
                                      />
                                    </div>
                                    <div
                                      className="form-group col-12 col-sm-12 col-md-4 col-lg-4 float-left p-2"
                                    >
                                      <label for="exampleInputName1">
                                        Prep. Expenses
                                      </label>
                                      <input
                                        required
                                        onChange={(t) => {
                                          // doConsole(t.target.value);
                                          setVData({
                                            ...vData,
                                            v_prep_expenses: t.target.value,
                                          });
                                        }}
                                        onBlur={(t) => {
                                          calcTotalPrice(t.target.value);
                                        }}
                                        value={vData.v_prep_expenses}
                                        type="number"
                                        className="form-control"
                                        id="exampleInputName1"
                                        placeholder="Prep. Expenses"
                                      />
                                    </div>
                                    <div
                                      className="form-group col-12 col-sm-12 col-md-4 col-lg-4 float-left p-2"
                                    >
                                      <label for="exampleInputName1">
                                        Total Price
                                      </label>
                                      <input
                                        required
                                        onChange={(t) => {
                                          doConsole(t.target.value);
                                          setTotalPrice(t.target.value);
                                        }}
                                        value={totalPrice}
                                        type="number"
                                        className="form-control"
                                        id="exampleInputName1"
                                        placeholder="Total Price"
                                      />
                                    </div>
                                    <div
                                      className="form-group col-12 col-sm-12 col-md-3 col-lg-3 float-left p-2"
                                    >
                                      <label for="exampleInputName1">
                                        Admin Fee
                                      </label>
                                      <input
                                        required
                                        onChange={(t) => {
                                          doConsole(t.target.value);
                                          setVData({
                                            ...vData,
                                            v_admin_fee: t.target.value,
                                          });
                                        }}
                                        onBlur={(t) => {
                                          // calcTotalPrice(t.target.value);
                                          calcSalePrice();
                                        }}
                                        value={vData.v_admin_fee}
                                        type="number"
                                        className="form-control"
                                        id="exampleInputName1"
                                        placeholder="Admin Fee"
                                      />
                                    </div>
                                    <div
                                      className="form-group col-12 col-sm-12 col-md-3 col-lg-3 float-left p-2"
                                    >
                                      <label for="exampleInputName1">
                                        Delivery Charges
                                      </label>
                                      <input
                                        required
                                        onChange={(t) => {
                                          doConsole(t.target.value);
                                          setVData({
                                            ...vData,
                                            v_delivery_charges: t.target.value,
                                          });
                                        }}
                                        onBlur={(t) => {
                                          calcSalePrice();
                                        }}
                                        value={vData.v_delivery_charges}
                                        type="number"
                                        className="form-control"
                                        id="exampleInputName1"
                                        placeholder="Delivery Charges"
                                      />
                                    </div>
                                    <div
                                      className="form-group col-12 col-sm-12 col-md-3 col-lg-3 float-left p-2"
                                    >
                                      <label for="exampleInputName1">
                                        Profit
                                      </label>
                                      <input
                                        // required
                                        onChange={(t) => {
                                          doConsole(t.target.value);
                                          // setVData({
                                          //   ...vData,
                                          //   v_profit: t.target.value,
                                          // });
                                          setProfit(t.target.value);
                                        }}
                                        onBlur={(t) => {
                                          calcSalePrice(t.target.value);
                                        }}
                                        value={profit}
                                        type="number"
                                        className="form-control"
                                        id="exampleInputName1"
                                        placeholder="Profit"
                                      />
                                    </div>
                                    <div
                                      className="form-group col-12 col-sm-12 col-md-3 col-lg-3 float-left p-2"
                                    >

                                      <label for="exampleInputName1">
                                        Sale Price
                                      </label>
                                      <input
                                        required
                                        onChange={(t) => {
                                          doConsole(t.target.value);
                                          setSalePrice(t.target.value);
                                          calcProfit(t.target.value);
                                          // setVData({
                                          //   ...vData,
                                          //   v_sale_price: t.target.value,
                                          // });
                                        }}
                                        // value={vData.v_sale_price}
                                        value={salePrice}
                                        type="number"
                                        className="form-control"
                                        id="exampleInputName1"
                                        placeholder="Sale Price"
                                      />
                                    </div>

                                    {/* Review 1 changes */}
                                    {
                                      vData.vs_id == '5' || vData.vs_id == '4' || vData.vs_id == '9' ?

                                        <div>
                                          <div
                                            className="form-group col-12 col-sm-12 col-md-4 col-lg-4 float-left p-2"
                                          >
                                            <label for="exampleInputName1">
                                              Cust Name
                                            </label>
                                            <input
                                              required={vData.vs_id == '9' ? true : false}
                                              onChange={(t) => {
                                                setVData({
                                                  ...vData,
                                                  cust_name: t.target.value,
                                                });
                                              }}
                                              value={vData.cust_name}
                                              type="text"
                                              className="form-control"
                                              id="exampleInputName1"
                                              placeholder="Cust Name"
                                            />
                                          </div>

                                          <div
                                            className="form-group col-12 col-sm-12 col-md-4 col-lg-4 float-left p-2"
                                          >
                                            <label for="exampleInputName1">
                                              Cust Email
                                            </label>
                                            <input
                                              required={vData.vs_id == '9' ? true : false}
                                              onChange={(t) => {
                                                setVData({
                                                  ...vData,
                                                  cust_email: t.target.value,
                                                });
                                              }}
                                              value={vData.cust_email}
                                              type="text"
                                              className="form-control"
                                              id="exampleInputName1"
                                              placeholder="Cust Email"
                                            />
                                          </div>

                                          <div
                                            className="form-group col-12 col-sm-12 col-md-4 col-lg-4 float-left p-2"
                                          >
                                            <label for="exampleInputName1">
                                              Cust Phone
                                            </label>
                                            <input
                                              required={vData.vs_id == '9' ? true : false}
                                              onChange={(t) => {
                                                setVData({
                                                  ...vData,
                                                  cust_phone: t.target.value,
                                                });
                                              }}
                                              value={vData.cust_phone}
                                              type="text"
                                              className="form-control"
                                              id="exampleInputName1"
                                              placeholder="Cust Phone"
                                            />
                                          </div>


                                          <div

                                            className="form-group col-12 col-sm-12 col-md-3 col-lg-3 float-left p-2"
                                          >
                                            <label for="exampleInputName1">
                                              Collection Date
                                            </label>
                                            <div
                                              id="datepicker-popup"
                                              className="input-group date datepicker navbar-date-picker"
                                            >
                                              <span className="input-group-addon input-group-prepend border-right">
                                                <span className="icon-calendar input-group-text calendar-icon"></span>
                                              </span>
                                              <input
                                                required={vData.vs_id == '9' ? true : false}
                                                onChange={(t) => {
                                                  setVData({
                                                    ...vData,
                                                    collection_date: t.target.value,
                                                  });
                                                }}
                                                value={vData.collection_date}
                                                type="date"
                                                className="form-control"
                                                placeholder="dd/mm/yyyy"
                                              />
                                            </div>
                                          </div>
                                          <div

                                            className="form-group col-12 col-sm-12 col-md-3 col-lg-3 float-left p-2"
                                          >
                                            <label for="exampleInputName1">
                                              Collection Time
                                            </label>
                                            <div
                                              id="datepicker-popup"
                                              className="input-group date datepicker navbar-date-picker"
                                            >
                                              <span className="input-group-addon input-group-prepend border-right">
                                                <span className="icon-calendar input-group-text calendar-icon"></span>
                                              </span>
                                              <input
                                                required={vData.vs_id == '9' ? true : false}
                                                onChange={(t) => {
                                                  setVData({
                                                    ...vData,
                                                    collection_time: t.target.value,
                                                  });
                                                }}
                                                value={vData.collection_time}
                                                type="time"
                                                className="form-control"
                                                placeholder="00:00"
                                              />
                                            </div>
                                          </div>

                                          <div

                                            className="form-group col-12 col-sm-12 col-md-6 col-lg-6 float-left p-2"
                                          >
                                            <label for="exampleInputName1">
                                              Collection Note
                                            </label>
                                            <textarea
                                              style={{ height: 120 }}
                                              // required={vData.vs_id == '9' ? true : false}
                                              onChange={(t) => {
                                                setVData({
                                                  ...vData,
                                                  collection_note: t.target.value,
                                                });

                                              }}
                                              value={vData.collection_note}
                                              type="text"
                                              className="form-control"
                                              id="exampleInputName1"
                                              placeholder="Collection Note"
                                            ></textarea>
                                          </div>
                                        </div>
                                        : null

                                    }







                                    <div
                                      // className="form-group"
                                      className="form-group col-12 col-sm-12 col-md-2 col-lg-2 float-left p-2"
                                    >

                                      <input
                                        onChange={(v) => {
                                          setVData({
                                            ...vData,
                                            is_serviced: v.target.checked == true ? '1' : '0'
                                          })
                                        }}
                                        checked={vData.is_serviced == '1' ? true : false}
                                        type="checkbox"
                                        value={vData.is_serviced == '1' ? true : false}
                                        placeholder="Serviced"
                                      />
                                      <label
                                        style={{ marginLeft: 10 }}
                                        for="exampleInputName1">
                                        Servicd
                                      </label>
                                    </div>

                                    <div
                                      className="form-group col-12 col-sm-12 col-md-10 col-lg-10 float-left p-2"
                                    >
                                      {
                                        vData.is_serviced == '1' ?
                                          <>
                                            <label for="exampleInputName1">
                                              List of service tasks done
                                            </label>
                                            <textarea
                                              style={{ height: 100 }}
                                              // required
                                              onChange={(t) => {
                                                setVData({
                                                  ...vData,
                                                  serviced_tasks: t.target.value,
                                                });

                                              }}
                                              value={vData.serviced_tasks}
                                              type="text"
                                              className="form-control"
                                              id="exampleInputName1"

                                            ></textarea>
                                          </>
                                          : null
                                      }
                                    </div>

                                    {
                                      vData.vs_id == '5' ?
                                        <div
                                          className="form-group col-12 col-sm-12 col-md-12 col-lg-12 float-left p-2"
                                        >
                                          <label for="exampleInputName1">
                                            Sold Note
                                          </label>
                                          <textarea
                                            style={{ height: 80 }}
                                            // disabled
                                            onChange={(t) => {
                                              setVData({
                                                ...vData,
                                                sold_note: t.target.value,
                                              });

                                            }}
                                            value={vData.sold_note}
                                            type="text"
                                            className="form-control"
                                            id="exampleInputName1"
                                            placeholder="Sold Note"
                                          ></textarea>
                                        </div>
                                        : null
                                    }



                                    {/* Review 1 changes ends */}




                                    <div className="col-12 text-center">
                                      <input
                                        type="submit"
                                        value="Save"
                                        className="btn me-2"
                                        // onClick={() => addCust()}
                                        style={{ backgroundColor: acolors.primary, color: "white", }}
                                      />
                                      <button className="btn btn-light">
                                        Cancel
                                      </button>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default EditVehicle;

// import React, { useState, useContext, useEffect } from "react";

// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.bundle";

// import SideBar from "../../Components/SideBar";
// import TopBar from "../../Components/TopBar";
// import Footer from "../../Components/Footer";
// import SettingPanel from "../../Components/SettingPanel";

// import { Context } from "../../Context/DataContext";
// import { apiRequest } from "../../utils/apiCalls";
// import { doConsole, useForceUpdate } from "../../utils/functions";
// import Loader from "../../Components/Loader";
// import { acolors } from "../../css/AppColors";
// import { useHistory } from "react-router-dom";
// import { ErrorAlert, SuccessAlert } from "../../Components/Alerts";
// import { useLocation } from "react-router-dom";

// const EditVehicle = () => {
//   const { state } = useContext(Context);
//   const forceUpdate = useForceUpdate();
//   const history = useHistory();
//   const location = useLocation();
//   const [errorMessage, setErrorMessage] = useState("");
//   const [success, setSuccess] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [vSData, setVSData] = useState([]);
//   const [vData, setVData] = React.useState({
//     v_id: location?.state?.data?.v_id,
//     file_no: location?.state?.data?.file_no,
//     v_reg_no: location?.state?.data?.v_reg_no,
//     vs_id: location?.state?.data?.vs_id,
//     v_purchase_date: location?.state?.data?.v_purchase_date,
//     v_sale_date: location?.state?.data?.v_sale_date,
//     v_mot: location?.state?.data?.v_mot,
//     v_info_url: location?.state?.data?.v_info_url,
//     v_make_model: location?.state?.data?.v_make_model,
//     v_price: location?.state?.data?.v_price,
//     v_prep_expenses: location?.state?.data?.v_prep_expenses,
//     v_admin_fee: location?.state?.data?.v_admin_fee,
//     v_delivery_charges: location?.state?.data?.v_delivery_charges,
//     v_total_price: location?.state?.data?.v_total_price,
//     v_sale_price: location?.state?.data?.v_sale_price,
//     v_key_slot: location?.state?.data?.v_key_slot,
//     token: state.garageData.token,
//     g_id: state.garageData.g_id,
//   });

//   const [totalPrice, setTotalPrice] = useState(
//     location?.state?.data?.v_total_price
//   );

//   function addV(event) {
//     event.preventDefault();
//     vData.v_total_price = totalPrice;
//     forceUpdate();
//     doConsole(vData);
//     setLoading(true);
//     apiRequest(vData, "edit_vehicle")
//       .then((data) => {
//         doConsole(data);
//         setLoading(false);
//         if (data.status == "1") {
//           setErrorMessage("");
//           setSuccess(true);
//           window.scrollTo(0, 0);
//           setTimeout(() => {
//             history.push("/listVehicle");
//           }, 2000);
//         } else {
//           setErrorMessage(data.error_msg);
//           forceUpdate();
//           window.scrollTo(0, 0);
//         }
//       })
//       .catch((err) => {
//         doConsole(err);
//         setLoading(false);
//       });
//   }

//   function getVS() {
//     setLoading(true);
//     const reqObj = {
//       token: state.garageData.token,
//       g_id: state.garageData.g_id,
//     };
//     apiRequest(reqObj, "get_veh_statuses")
//       .then((data) => {
//         doConsole(data);
//         setLoading(false);
//         if (data.status == "1") {
//           doConsole(data.data);
//           setVSData(data.data);
//           //   forceUpdate();
//         } else {
//           setErrorMessage(data.error_msg);
//           forceUpdate();
//           window.scrollTo(0, 0);
//         }
//       })
//       .catch((err) => {
//         doConsole(err);
//         setLoading(false);
//       });
//   }

//   function calcTotalPrice() {
//     var calcPrice =
//       parseInt(vData.v_price) +
//       parseInt(vData.v_prep_expenses) +
//       parseInt(vData.v_admin_fee) +
//       parseInt(vData.v_delivery_charges);
//     setTotalPrice(calcPrice);
//     forceUpdate();
//   }

//   useEffect(() => {
//     if (!location?.state?.data?.v_id) {
//       history.push("listVehicle");
//     }
//     getVS();
//   }, []);

//   return (

//     <div className="main-panel">
//       <div className="content-wrapper">
//         {loading && <Loader />}
//         {errorMessage && (
//           <ErrorAlert
//             onClose={() => setErrorMessage("")}
//             errorMessage={errorMessage}
//           />
//         )}
//         {success && (
//           <SuccessAlert message={"Hurry! Your vehicle has been updated!"} />
//         )}
//         <div className="row">
//           <div className="col-sm-12">
//             <div className="home-tab">
//               <div className="tab-content tab-content-basic">
//                 <div
//                   className="tab-pane fade show active"
//                   id="overview"
//                   role="tabpanel"
//                   aria-labelledby="overview"
//                 >
//                   <div className="row">
//                     <div className="col-12">
//                       <h3 className="text-black fw-bold">Edit Vehicle</h3>
//                       <br />
//                     </div>
//                   </div>
//                   <div className="row">
//                     <div className="col-lg-12 col-md-12 d-flex flex-column">
//                       <div className="row flex-grow">
//                         <div className="col-12 col-md-12 col-lg-12 grid-margin stretch-card">
//                           <div className="card card-rounded">
//                             <div className="card-body">
//                               <div className="d-sm-flex justify-content-between align-items-start">
//                                 <div className="row w-100">
//                                   <form
//                                     onSubmit={(event) => addV(event)}
//                                     className="forms-sample"
//                                   >
//                                     <div className="form-group col-12  col-lg-4 col-md-4 col-sm-12 float-left p-2">
//                                       <label for="exampleInputName1">
//                                         File No.
//                                       </label>
//                                       <input
//                                         type="text"
//                                         className="form-control"
//                                         value={vData.file_no}
//                                         id="exampleInputName1"
//                                         placeholder="File No."
//                                         required
//                                         onChange={(v) => {
//                                           setVData({
//                                             ...vData,
//                                             file_no: v.target.value,
//                                           });
//                                         }}
//                                       />
//                                     </div>
//                                     <div
//                                       className="form-group"
//                                       className="form-group col-12 col-sm-12 col-md-4 col-lg-4 float-left p-2"
//                                     >
//                                       <label for="exampleInputName1">
//                                         Registration No
//                                       </label>
//                                       <input
//                                         required
//                                         onChange={(t) => {
//                                           doConsole(t.target.value);
//                                           setVData({
//                                             ...vData,
//                                             v_reg_no: t.target.value,
//                                           });
//                                         }}
//                                         value={vData.v_reg_no}
//                                         type="text"
//                                         className="form-control"
//                                         id="exampleInputName1"
//                                         placeholder="Description"
//                                       />
//                                     </div>

//                                     <div className="form-group col-12 col-lg-4 col-md-4 col-sm-12 float-left p-2">
//                                       <label for="exampleSelectGender">
//                                         Status
//                                       </label>
//                                       <select
//                                         required
//                                         onChange={(t) => {
//                                           doConsole(t.target.value);
//                                           setVData({
//                                             ...vData,
//                                             vs_id: t.target.value,
//                                           });
//                                         }}
//                                         value={vData.vs_id}
//                                         className="form-control"
//                                         id="exampleSelectGender"
//                                       >
//                                         {vSData?.map((v) => {
//                                           return (
//                                             <option
//                                               selected={
//                                                 v.vs_id ==
//                                                   location?.state?.data?.vs_id
//                                                   ? true
//                                                   : false
//                                               }
//                                               value={v.vs_id}
//                                             >
//                                               {v.veh_status}
//                                             </option>
//                                           );
//                                         })}
//                                       </select>
//                                     </div>
//                                     <div
//                                       className="form-group"
//                                       className="form-group col-12 col-sm-12 col-md-4 col-lg-4 float-left p-2"
//                                     >
//                                       <label for="exampleInputName1">
//                                         Purchase date
//                                       </label>
//                                       <div
//                                         id="datepicker-popup"
//                                         className="input-group date datepicker navbar-date-picker"
//                                       >
//                                         <span className="input-group-addon input-group-prepend border-right">
//                                           <span className="icon-calendar input-group-text calendar-icon"></span>
//                                         </span>
//                                         <input
//                                           required
//                                           onChange={(t) => {
//                                             doConsole(t.target.value);
//                                             setVData({
//                                               ...vData,
//                                               v_purchase_date:
//                                                 t.target.value,
//                                             });
//                                           }}
//                                           value={vData.v_purchase_date}
//                                           type="date"
//                                           className="form-control"
//                                           placeholder="dd/mm/yyyy"
//                                         />
//                                       </div>
//                                     </div>
//                                     <div
//                                       className="form-group"
//                                       className="form-group col-12 col-sm-12 col-md-4 col-lg-4 float-left p-2"
//                                     >
//                                       <label for="exampleInputName1">
//                                         Sale date
//                                       </label>
//                                       <div
//                                         id="datepicker-popup"
//                                         className="input-group date datepicker navbar-date-picker"
//                                       >
//                                         <span className="input-group-addon input-group-prepend border-right">
//                                           <span className="icon-calendar input-group-text calendar-icon"></span>
//                                         </span>
//                                         <input
//                                           // required
//                                           onChange={(t) => {
//                                             doConsole(t.target.value);
//                                             setVData({
//                                               ...vData,
//                                               v_sale_date: t.target.value,
//                                             });
//                                           }}
//                                           value={vData.v_sale_date}
//                                           type="date"
//                                           className="form-control"
//                                           placeholder="dd/mm/yyyy"
//                                         />
//                                       </div>
//                                     </div>
//                                     <div
//                                       className="form-group"
//                                       className="form-group col-12 col-sm-12 col-md-4 col-lg-4 float-left p-2"
//                                     >
//                                       <label for="exampleInputName1">
//                                         MOT
//                                       </label>
//                                       <div
//                                         id="datepicker-popup"
//                                         className="input-group date datepicker navbar-date-picker"
//                                       >
//                                         <span className="input-group-addon input-group-prepend border-right">
//                                           <span className="icon-calendar input-group-text calendar-icon"></span>
//                                         </span>
//                                         <input
//                                           required
//                                           onChange={(t) => {
//                                             doConsole(t.target.value);
//                                             setVData({
//                                               ...vData,
//                                               v_mot: t.target.value,
//                                             });
//                                           }}
//                                           value={vData.v_mot}
//                                           type="date"
//                                           className="form-control"
//                                           placeholder="dd/mm/yyyy"
//                                         />
//                                       </div>
//                                     </div>
//                                     <div
//                                       className="form-group"
//                                       className="form-group col-12 col-sm-12 col-md-4 col-lg-4 float-left p-2"
//                                     >
//                                       <label for="exampleInputName1">
//                                         Info Link
//                                       </label>
//                                       <input
//                                         // required
//                                         onChange={(t) => {
//                                           doConsole(t.target.value);
//                                           setVData({
//                                             ...vData,
//                                             v_info_url: t.target.value,
//                                           });
//                                         }}
//                                         value={vData.v_info_url}
//                                         type="text"
//                                         className="form-control"
//                                         id="exampleInputName1"
//                                         placeholder="Info Link"
//                                       />
//                                     </div>
//                                     <div
//                                       className="form-group"
//                                       className="form-group col-12 col-sm-12 col-md-4 col-lg-4 float-left p-2"
//                                     >
//                                       <label for="exampleInputName1">
//                                         Make/Model
//                                       </label>
//                                       <input
//                                         required
//                                         onChange={(t) => {
//                                           doConsole(t.target.value);
//                                           setVData({
//                                             ...vData,
//                                             v_make_model: t.target.value,
//                                           });
//                                         }}
//                                         value={vData.v_make_model}
//                                         type="text"
//                                         className="form-control"
//                                         id="exampleInputName1"
//                                         placeholder="Make/Model"
//                                       />
//                                     </div>
//                                     <div
//                                       className="form-group"
//                                       className="form-group col-12 col-sm-12 col-md-4 col-lg-4 float-left p-2"
//                                     >
//                                       <label for="exampleInputName1">
//                                         Price
//                                       </label>
//                                       <input
//                                         required
//                                         min={0}
//                                         onChange={(t) => {
//                                           doConsole(t.target.value);
//                                           setVData({
//                                             ...vData,
//                                             v_price: t.target.value,
//                                           });
//                                         }}
//                                         onBlur={() => {
//                                           calcTotalPrice();
//                                         }}
//                                         value={vData.v_price}
//                                         type="number"
//                                         className="form-control"
//                                         id="exampleInputName1"
//                                         placeholder="Price"
//                                       />
//                                     </div>
//                                     <div
//                                       className="form-group"
//                                       className="form-group col-12 col-sm-12 col-md-4 col-lg-4 float-left p-2"
//                                     >
//                                       <label for="exampleInputName1">
//                                         Prep. Expenses
//                                       </label>
//                                       <input
//                                         required
//                                         onChange={(t) => {
//                                           doConsole(t.target.value);
//                                           setVData({
//                                             ...vData,
//                                             v_prep_expenses: t.target.value,
//                                           });
//                                         }}
//                                         onBlur={() => {
//                                           calcTotalPrice();
//                                         }}
//                                         value={vData.v_prep_expenses}
//                                         type="number"
//                                         className="form-control"
//                                         id="exampleInputName1"
//                                         placeholder="Prep. Expenses"
//                                       />
//                                     </div>
//                                     <div
//                                       className="form-group"
//                                       className="form-group col-12 col-sm-12 col-md-4 col-lg-4 float-left p-2"
//                                     >
//                                       <label for="exampleInputName1">
//                                         Admin Fee
//                                       </label>
//                                       <input
//                                         required
//                                         onChange={(t) => {
//                                           doConsole(t.target.value);
//                                           setVData({
//                                             ...vData,
//                                             v_admin_fee: t.target.value,
//                                           });
//                                         }}
//                                         onBlur={() => {
//                                           calcTotalPrice();
//                                         }}
//                                         value={vData.v_admin_fee}
//                                         type="number"
//                                         className="form-control"
//                                         id="exampleInputName1"
//                                         placeholder="Admin Fee"
//                                       />
//                                     </div>
//                                     <div
//                                       className="form-group"
//                                       className="form-group col-12 col-sm-12 col-md-4 col-lg-4 float-left p-2"
//                                     >
//                                       <label for="exampleInputName1">
//                                         Delivery Charges
//                                       </label>
//                                       <input
//                                         required
//                                         onChange={(t) => {
//                                           doConsole(t.target.value);
//                                           setVData({
//                                             ...vData,
//                                             v_delivery_charges:
//                                               t.target.value,
//                                           });
//                                         }}
//                                         onBlur={() => {
//                                           calcTotalPrice();
//                                         }}
//                                         value={vData.v_delivery_charges}
//                                         type="number"
//                                         className="form-control"
//                                         id="exampleInputName1"
//                                         placeholder="Delivery Charges"
//                                       />
//                                     </div>
//                                     <div
//                                       className="form-group"
//                                       className="form-group col-12 col-sm-12 col-md-4 col-lg-4 float-left p-2"
//                                     >
//                                       <label for="exampleInputName1">
//                                         Total Price
//                                       </label>
//                                       <input
//                                         required
//                                         onChange={(t) => {
//                                           // doConsole(t.target.value);
//                                           setTotalPrice(t.target.value);
//                                         }}
//                                         value={totalPrice}
//                                         type="number"
//                                         className="form-control"
//                                         id="exampleInputName1"
//                                         placeholder="Total Price"
//                                       />
//                                     </div>
//                                     <div
//                                       className="form-group"
//                                       className="form-group col-12 col-sm-12 col-md-4 col-lg-4 float-left p-2"
//                                     >
//                                       <label for="exampleInputName1">
//                                         Sale Price
//                                       </label>
//                                       <input
//                                         required
//                                         onChange={(t) => {
//                                           doConsole(t.target.value);
//                                           setVData({
//                                             ...vData,
//                                             v_sale_price: t.target.value,
//                                           });
//                                         }}
//                                         value={vData.v_sale_price}
//                                         type="number"
//                                         className="form-control"
//                                         id="exampleInputName1"
//                                         placeholder="Sale Price"
//                                       />
//                                     </div>

//                                     <div
//                                       className="form-group"
//                                       className="form-group col-12 col-sm-12 col-md-4 col-lg-4 p-2 float-left"
//                                     >
//                                       <label for="exampleInputName1">
//                                         Key slot
//                                       </label>
//                                       <input
//                                         required
//                                         onChange={(t) => {
//                                           doConsole(t.target.value);
//                                           setVData({
//                                             ...vData,
//                                             v_key_slot: t.target.value,
//                                           });
//                                         }}
//                                         value={vData.v_key_slot}
//                                         type="text"
//                                         className="form-control"
//                                         id="exampleInputName1"
//                                         placeholder="Key slot"
//                                       />
//                                     </div>

//                                     <div className="col-12 text-center">
//                                       <input
//                                         type="submit"
//                                         value="Save"
//                                         className="btn me-2"
//                                         // onClick={() => addCust()}
//                                         style={{
//                                           backgroundColor: acolors.primary,
//                                           color: "white",
//                                         }}
//                                       />

//                                       <button className="btn btn-light">
//                                         Cancel
//                                       </button>
//                                     </div>
//                                   </form>
//                                 </div>
//                               </div>
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       {/* content-wrapper ends */}
//       {/* partial:partials/_footer.html */}
//       <Footer />
//       {/* partial */}
//     </div>
//   );
// };

// export default EditVehicle;

// {
//   /* <div className="row w-100">
//                                                                             <form className="forms-sample">
//                                                                                 <div className="form-group col-12 col-lg-6 col-md-6 col-sm-12 float-left p-2">
//                                                                                     <label for="exampleSelectGender">Expense Type</label>
//                                                                                     <select className="form-control" id="exampleSelectGender">
//                                                                                         <option>Fixed Expenses</option>
//                                                                                         <option>Wages</option>
//                                                                                         <option>Variable Exp.</option>
//                                                                                         <option>Vehicle Specific</option>
//                                                                                     </select>
//                                                                                 </div>
//                                                                                 <div className="form-group col-12  col-lg-6 col-md-6 col-sm-12 float-left p-2">
//                                                                                     <label for="exampleInputName1">File No.</label>
//                                                                                     <input type="text" className="form-control" id="exampleInputName1" placeholder="File No." />
//                                                                                 </div>

//                                                                                 <div className="form-group"  className="form-group col-12 col-sm-12 col-md-6 col-lg-6 float-left p-2">
//                                                                                     <label for="exampleInputName1">Description</label>
//                                                                                     <input type="text" className="form-control" id="exampleInputName1" placeholder="Description" />
//                                                                                 </div>
//                                                                                 <div className="form-group"  className="form-group col-12 col-sm-12 col-md-6 col-lg-6 float-left p-2">
//                                                                                     <label for="exampleInputName1">Description</label>
//                                                                                     <input type="text" className="form-control" id="exampleInputName1" placeholder="Description" />
//                                                                                 </div>
//                                                                                 <div className="col-12 text-center">
//                                                                                     <button type="submit" className="btn btn-primary me-2">Save</button>
//                                                                                     <button className="btn btn-light">Cancel</button>
//                                                                                 </div>
//                                                                             </form>
//                                                                         </div> */
// }
