export const urls = {
    // API: "http://www.garage1.qwpcorp.com/public/api/"
    // API: "http://127.0.0.1:8000/api/"
    // API: "https://www.garage.qwpcorp.com/public/api/" // PRODUCTION
    API: "https://www.garage1.qwpcorp.com/public/api/" // DEVELOPEMENT
}


// manage vehicles
// show all the vehicles
// search box
// total price, sale price , profit margin



// sale_price = total_price + admin_fee + delivery_charges + profit;

// profit = sale_price - total_price - delivery_charges -admin_fee;

        