import React, { useContext, useState, useEffect } from "react";




import "../../css/vertical-layout-light/style.css";


// import { Link } from 'react-router-dom';
import SideBar from "../../Components/SideBar";
import TopBar from "../../Components/TopBar";
import Footer from "../../Components/Footer";


import { useHistory, useLocation } from "react-router";
import { Context } from "../../Context/DataContext";
import { apiRequest } from "../../utils/apiCalls";
import { doConsole, useForceUpdate } from "../../utils/functions";
import { alertMessage } from "../../utils/helpers";
import Loader from "../../Components/Loader";
import { acolors } from "../../css/AppColors";
import { ErrorAlert, SuccessAlert } from "../../Components/Alerts";

import { Store as notify } from 'react-notifications-component';


function ManageAccess() {


    const { state } = useContext(Context);
    const forceUpdate = useForceUpdate();
    const history = useHistory();
    const location = useLocation();
    const [errorMessage, setErrorMessage] = useState("");
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});

    const [dep, setDepartments] = useState([]);


    const [accessScreens, setAccessScreens] = useState(["Dashboard"]);
    const [dep_id, setDepId] = useState('');

    function makeAccessScreens(v) {
        if (accessScreens.includes(v)) {
            let foundIndex = accessScreens.indexOf(v);
            accessScreens.splice(foundIndex,1);
            forceUpdate();
        }
        else {
            let arr = accessScreens;
            arr.push(v)
            setAccessScreens(arr);
            forceUpdate();
        }
    }

    function addAccessScreen() {


        if (dep_id == '') {
            notify.addNotification(
                alertMessage({
                    type: 'danger',
                    title: 'Error',
                    message: "Please select department"
                })
            );
            return;
        }
        if (!accessScreens.length) {
            notify.addNotification(
                alertMessage({
                    type: 'danger',
                    title: 'Error',
                    message: "Please select atleast one screen"
                })
            );
            return;
        }
        setLoading(true)

        const reqObj = {
            token: state.garageData.token,
            g_id: state.garageData.g_id,
            screens: accessScreens,
            dep_id: dep_id
        };

        apiRequest(reqObj, "add_access_screens")
            .then((data) => {
                doConsole(data);
                setLoading(false);
                if (data.status == "1") {
                    doConsole(data.data);
                    setLoading(false)
                    setErrorMessage('');
                    notify.addNotification(
                        alertMessage({
                            type: 'success',
                            title: 'Success',
                            message: "Added"
                        })
                    );
                } else {
                    setErrorMessage(data.error_msg);
                    forceUpdate();
                    setLoading(false)
                    window.scrollTo(0, 0);
                }
            })
            .catch((err) => {
                doConsole(err);
                setLoading(false);
            });

    }


    function get_access_screens(v) {
        setLoading(true)
        const reqObj = {
            token: state.garageData.token,
            dep_id: v
        };
        // alert(v)

        apiRequest(reqObj, "get_access_screens")
            .then((data) => {
                doConsole(data);
                setLoading(false);
                setAccessScreens(data?.data ? data?.data : []);
                forceUpdate();
                if (data.status == "1") {

                    // setStaffData(data.data);
                } else {
                    setErrorMessage(data.error_msg);
                    forceUpdate();
                    window.scrollTo(0, 0);
                }
            })
            .catch((err) => {
                doConsole(err);
                setLoading(false);
            });

    }

    const routes = [

        { name: "Dashboard", route: "Dashboard" },

        // Vehicles
        { parent: 'Vehicles' },

        { name: "Add Vehicle", route: "AddVehicle" },
        { name: "Available Vehicles", route: "ListVehicle" },
        { name: "All Vehicles", route: "AllVehicles" },
        { name: "Edit Vehicle", route: "EditVehicle" },
        { name: "Search Vehicle", route: "SearchVehicle" },
        { name: "List Vehicle (Single)", route: "ViewSingleVehicle" },
        { name: "Sold Vehicles", route: "SoldVehicles" },
        { name: "Delete Vehicles", route: "DeleteVehicle" },

        // Appointments
        { parent: "Appointments" },

        { name: "Add Appointment", route: "AddAppointment" },
        { name: "View Appointments", route: "AppointmentCalender" },
        { name: "Edit Appointment", route: "EditAppointment" },

        // Tasks
        { parent: "Tasks" },

        { name: "Assign Task", route: "AssignTask" },
        { name: "Add new task", route: "AddTask" },
        { name: "Edit Task", route: "EditTask" },
        { name: "View tasks", route: "ViewOpenTasks" },
        { name: "Manage Tasks", route: "ManageTasks" },

        // Staff
        { parent: "Staff" },

        { name: "Add Staff", route: "AddStaff" },
        { name: "View Staff", route: "ListStaff" },
        { name: "Edit Staff", route: "EditStaff" },

        //  Accounts
        { parent: "Accounts" },

        { name: "Add Income", route: "AddIncome" },
        { name: "Journal General", route: "Journal" },
        { name: "Ledger Account", route: "AccountStatement" },
        { name: "Trial Accountss", route: "TrailAccount" },
        { name: "Manage Accounts", route: "ManageAccounts" },

        // Target Planner
        { parent: "Planner" },

        { name: "Target Planner", route: "TargetPlanner" },
        { name: "Montyly Sale Target", route: "ProfitTarget" }, //Monthly Sale Target
        { name: "Yearly Sale Target", route: "VehiclesSalesTarget" },

        // Reports
        { parent: "Reports" },

        { name: "Upcoming Mots", route: "UpcomingMotReport" },
        { name: "Vehicle Stock Report", route: "VehicleStockReport" },
        { name: "Target Achievement", route: "TargetAchievementReport" },
        { name: "Upcoming Collection", route: "UpcomingCollections" },



    ];



    function getDepartments() {
        setLoading(true);
        const reqObj = {
            token: state.garageData.token,
            g_id: state.garageData.g_id,
        };

        apiRequest(reqObj, "get_departments")
            .then((data) => {
                doConsole(data);
                setLoading(false);
                if (data.status == "1") {
                    setErrorMessage("");
                    setDepartments(data.data);
                    window.scrollTo(0, 0);
                } else {
                    setErrorMessage(data.error_msg);
                    forceUpdate();
                    window.scrollTo(0, 0);
                    // $("#datepicker-popup").focus();
                }
            })
            .catch((err) => {
                doConsole(err);
                setLoading(false);
            });
    }



    useEffect(() => {
        getDepartments();
    }, []);








    return (
        <div className="main-panel">
            <div className="content-wrapper">
                {loading && <Loader />}
                {errorMessage && (
                    <ErrorAlert
                        onClose={() => setErrorMessage("")}
                        errorMessage={errorMessage}
                    />
                )}
                {success && (
                    <SuccessAlert message={"Hurry! Your vehicle has been updated!"} />
                )}
                <h3 className="text-black fw-bold">Manage Access</h3>
                <div className="row">

                    <div className="col-sm-12">
                        <div
                            className="tab-pane fade show active"
                            id="overview"
                            role="tabpanel"
                            aria-labelledby="overview"
                        >
                            <div className="col-sm-12">

                                <div className="row w-100">
                                    <div className="form-group col-12 col-lg-3 col-md-3 col-sm-12 float-left p-2">
                                        <label for="exampleSelectGender">
                                            Select department member
                                        </label>
                                        <select
                                            onChange={(t) => {
                                                const value = t.target.value
                                                setDepId(value)
                                                get_access_screens(value)
                                            }}
                                            required
                                            // value={postData.assign_to_id}
                                            className="form-control"
                                            id="exampleSelectGender"
                                        >
                                            <option>
                                                Select
                                            </option>
                                            {dep?.map((v, i) => {
                                                return (
                                                    <option value={v.dep_id}>
                                                        {v.dep_title}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <label style={{ marginLeft: -20 }} >
                                    Select Access Screens
                                </label>

                                <input
                                    style={{ marginLeft: 55, marginBottom: 20 }}
                                    onChange={(t) => {
                                        if (accessScreens.length == routes.length) {
                                            setAccessScreens([])
                                        }
                                        else {
                                            let arr = [];
                                            for (let key in routes) {
                                                arr.push(routes[key].route)
                                            }
                                            setAccessScreens(arr)
                                        }
                                    }}
                                    checked={accessScreens.length == routes.length ? true : false}
                                    type="checkbox"
                                    // value={vData.is_serviced == '1' ? true : false}
                                    placeholder="Serviced"
                                />
                                <label
                                    style={{ marginLeft: 5 }}
                                    for="exampleInputName1">
                                    Select all
                                </label>


                                <div className="row">
                                    {
                                        routes?.map((v, i) => {
                                            return (
                                                <div
                                                    key={i}
                                                    style={{ margin: 0, marginLeft: v?.parent ? 0 : 10 }}
                                                    className={v?.parent ? "form-group col-sm-12 col-md-12 col-lg-12" : "form-group col-sm-12 col-md-3 col-lg-3"}
                                                >
                                                    {
                                                        v?.parent ?
                                                            <div>
                                                                <label style={{ color: '#222222', fontSize: 15, fontWeight: 'bold' }}>{v?.parent}</label>
                                                            </div>
                                                            :
                                                            <>
                                                                <input
                                                                    onChange={(t) => {
                                                                        makeAccessScreens(v.route)
                                                                    }}
                                                                    checked={accessScreens.includes(v.route) ? true : false}
                                                                    type="checkbox"
                                                                    placeholder="Serviced"
                                                                />
                                                                <label
                                                                    style={{ marginLeft: 5 }}
                                                                    for="exampleInputName1">
                                                                    {v.name}
                                                                </label>
                                                            </>
                                                    }
                                                </div>
                                            )
                                        })
                                    }

                                </div>

                                <div className="col-12 text-center mt-5 ">
                                    <button
                                        className="btn me-2 col-lg-2 "
                                        onClick={() => addAccessScreen()}
                                        style={{
                                            backgroundColor: acolors.primary,
                                            color: "white",
                                        }}
                                    >
                                        Save
                                    </button>
                                    <button
                                        onClick={() => { setAccessScreens([]) }}
                                        className="btn btn-light col-lg-2">
                                        Cancel
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* content-wrapper ends */}
            {/* partial:partials/_footer.html */}
            <Footer />
            {/* partial */}
        </div>
    );
}

export default ManageAccess;


