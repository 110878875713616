import React, { useState, useEffect, useContext } from 'react'
import $ from 'jquery';

import SideBar from '../../Components/SideBar';
import TopBar from '../../Components/TopBar';
import Footer from '../../Components/Footer';
import SettingPanel from '../../Components/SettingPanel';

import { Context } from '../../Context/DataContext';
import { apiRequest } from '../../utils/apiCalls';
import { doConsole, useForceUpdate } from '../../utils/functions';
import Loader from '../../Components/Loader';
import { acolors } from '../../css/AppColors';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { ErrorAlert, SuccessAlert } from '../../Components/Alerts';


const EditStaff = () => {


    const { state } = useContext(Context);
    const forceUpdate = useForceUpdate();
    const history = useHistory();
    const location = useLocation();
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [success, setSuccess] = useState(false)

    const [departments, setDepartments] = useState([]);
    const [postData, setPostData] = useState({
        token: state.garageData.token,
        g_id: state.garageData.g_id,
        st_id: location?.state?.data?.st_id,
        st_fname: location?.state?.data?.st_fname,
        st_lname: location?.state?.data?.st_lname,
        dep_id: location?.state?.data?.dep_id,
        st_phone: location?.state?.data?.st_phone,
        st_email: location?.state?.data?.st_email,
        st_password: location?.state?.data?.st_password
    });

    function doEditStaff(event) {
        event.preventDefault();
        doConsole(postData)
        setLoading(true)
        apiRequest(postData, 'edit_staff')
            .then(data => {
                doConsole(data)
                setLoading(false);
                if (data.status == '1') {
                    setErrorMessage('');
                    setSuccess(true)
                    window.scrollTo(0, 0)
                    setTimeout(() => {
                        history.push('/listStaff');
                    }, 2000);
                }
                else {
                    setErrorMessage(data.error_msg)
                    forceUpdate();
                    window.scrollTo(0, 0)
                    // $("#datepicker-popup").focus();
                }
            })
            .catch(err => {
                doConsole(err)
                setLoading(false)
            })
    }


    function getDepartments() {


        setLoading(true)
        const reqObj = {
            token: state.garageData.token,
            g_id: state.garageData.g_id
        };

        apiRequest(reqObj, 'get_departments')
            .then(data => {
                doConsole(data)
                setLoading(false);
                if (data.status == '1') {
                    setErrorMessage('');
                    setDepartments(data.data)
                    window.scrollTo(0, 0)
                }
                else {
                    setErrorMessage(data.error_msg)
                    forceUpdate();
                    window.scrollTo(0, 0)
                    // $("#datepicker-popup").focus();
                }
            })
            .catch(err => {
                doConsole(err)
                setLoading(false)
            })
    }



    React.useEffect(() => {
        $("#staff").addClass("show");
    }, [])

    useEffect(() => {
        if (!location?.state?.data?.st_id) {
            history.push('listStaff')
        }
        getDepartments();
    }, [])

    return (

        <div className="main-panel">
            <div className="content-wrapper">
                {loading && <Loader />}
                {errorMessage && <ErrorAlert onClose={() => setErrorMessage('')} errorMessage={errorMessage} />}
                {success && <SuccessAlert message={"Hurry! Your staff member has been updated!"} />}
                <div className="row">
                    <div className="col-sm-12">
                        <div className="home-tab">

                            <div className="tab-content tab-content-basic">
                                <div
                                    className="tab-pane fade show active"
                                    id="overview"
                                    role="tabpanel"
                                    aria-labelledby="overview"
                                >
                                    <div className="row">
                                        <div className="col-12">
                                            <h3 className="text-black fw-bold">Edit Staff</h3><br />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 d-flex flex-column">
                                            <div className="row flex-grow">
                                                <div className="col-12 col-md-12 col-lg-12 grid-margin stretch-card">
                                                    <div className="card card-rounded">
                                                        <div className="card-body">
                                                            <div className="d-sm-flex justify-content-between align-items-start">
                                                                <div className="row w-100">
                                                                    <form
                                                                        onSubmit={(event) => doEditStaff(event)}
                                                                        className="forms-sample">
                                                                        <div className="form-group col-12  col-lg-4 col-md-4 col-sm-12 float-left p-2">
                                                                            <label for="exampleInputName1">First Name</label>
                                                                            <input
                                                                                required
                                                                                onChange={(t) => {
                                                                                    setPostData({
                                                                                        ...postData,
                                                                                        st_fname: t.target.value,
                                                                                    })
                                                                                }}
                                                                                value={postData.st_fname}
                                                                                type="text" className="form-control" id="exampleInputName1" placeholder="First Name" />
                                                                        </div>
                                                                        <div className="form-group" className="form-group col-12 col-sm-12 col-md-4 col-lg-4 float-left p-2">
                                                                            <label for="exampleInputName1">Last Name</label>
                                                                            <input
                                                                                required
                                                                                onChange={(t) => {
                                                                                    setPostData({
                                                                                        ...postData,
                                                                                        st_lname: t.target.value,
                                                                                    })
                                                                                }}
                                                                                value={postData.st_lname}
                                                                                type="text" className="form-control" id="exampleInputName1" placeholder="Last Name" />
                                                                        </div>

                                                                        <div className="form-group col-12 col-lg-4 col-md-4 col-sm-12 float-left p-2">
                                                                            <label for="exampleSelectGender">Department</label>
                                                                            <select
                                                                                required
                                                                                onChange={(t) => {
                                                                                    setPostData({
                                                                                        ...postData,
                                                                                        dep_id: t.target.value,
                                                                                    })
                                                                                }}
                                                                                value={postData.dep_id}
                                                                                className="form-control" id="exampleSelectGender"
                                                                            >
                                                                                <option selected
                                                                                    disabled={postData.dep_id ? true : false}  >Select</option>
                                                                                {
                                                                                    departments?.map((v, i) => {
                                                                                        return (
                                                                                            <option selected={v.dep_id == location?.state?.data?.dep_id ? true : false} value={v.dep_id} >{v.dep_title}</option>
                                                                                        )
                                                                                    })
                                                                                }

                                                                            </select>
                                                                        </div>
                                                                        <div className="form-group" className="form-group col-12 col-sm-12 col-md-4 col-lg-4 float-left p-2">
                                                                            <label for="exampleInputName1">Phone No.</label>
                                                                            <input
                                                                                required
                                                                                onChange={(t) => {
                                                                                    setPostData({
                                                                                        ...postData,
                                                                                        st_phone: t.target.value,
                                                                                    })
                                                                                }}
                                                                                value={postData.st_phone}
                                                                                type="text" className="form-control" id="exampleInputName1" placeholder="Phone No." />
                                                                        </div>
                                                                        <div className="form-group" className="form-group col-12 col-sm-12 col-md-4 col-lg-4 float-left p-2">
                                                                            <label for="exampleInputName1">Email</label>
                                                                            <input
                                                                                required
                                                                                onChange={(t) => {
                                                                                    setPostData({
                                                                                        ...postData,
                                                                                        st_email: t.target.value,
                                                                                    })
                                                                                }}
                                                                                value={postData.st_email}
                                                                                type="text" className="form-control" id="exampleInputName1" placeholder="Email" />
                                                                        </div>
                                                                        <div className="form-group" className="form-group col-12 col-sm-12 col-md-4 col-lg-4 float-left p-2">
                                                                            <label for="exampleInputName1">Password</label>
                                                                            <input
                                                                                required
                                                                                onChange={(t) => {
                                                                                    setPostData({
                                                                                        ...postData,
                                                                                        st_password: t.target.value,
                                                                                    })
                                                                                }}
                                                                                value={postData.st_password}
                                                                                // type="password"
                                                                                className="form-control" id="exampleInputName1" placeholder="Password" />
                                                                        </div>

                                                                        <div className="col-12 text-center">
                                                                            <input
                                                                                type="submit"
                                                                                title='Save'
                                                                                className="btn me-2"
                                                                                style={{ backgroundColor: acolors.primary, color: 'white' }}
                                                                            />
                                                                            <button className="btn btn-light">Cancel</button>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* content-wrapper ends */}
            {/* partial:partials/_footer.html */}
            <Footer />
            {/* partial */}
        </div>

    )
}

export default EditStaff
