import React, { useState, useEffect, useContext } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";



import SideBar from "../../Components/SideBar";
import TopBar from "../../Components/TopBar";
import Footer from "../../Components/Footer";
import SettingPanel from "../../Components/SettingPanel";

import { Link, useHistory, useLocation } from "react-router-dom";
import { Context } from "../../Context/DataContext";
import { apiRequest } from "../../utils/apiCalls.js";
import { doConsole, useForceUpdate } from "../../utils/functions.js";
import Loader from "../../Components/Loader.js";
import { ErrorAlert, SuccessAlert } from "../../Components/Alerts.js";
import { acolors } from "../../css/AppColors";


const TargetPlanner = () => {

  const forceUpdate = useForceUpdate();
  const history = useHistory();
  const location = useLocation();
  const { state } = useContext(Context);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [postData, setPostData] = useState({
    g_id: state.garageData.g_id,
    token: state.garageData.token,
    tp_id: '',
    tp_year: '2022',
    tp_month: [],
    tp_profit: [],
    tp_qty: [],
  });

  function addTarget(event) {
    // event.preventDefault();
    // if()
    doConsole(postData);
    setLoading(true);
    apiRequest(postData, "add_update_planner")
      .then((data) => {
        doConsole(data);
        setLoading(false);
        if (data.status == "1") {
          setErrorMessage("");
          setSuccess(true);
          window.scrollTo(0, 0);
          // setTimeout(() => {
          //   // history.push("/ProfitTarget");
          // }, 2000);
        } else {
          setErrorMessage(data.error_msg);
          window.scrollTo(0, 0);
          forceUpdate();

          // $("#datepicker-popup").focus();
        }
      })
      .catch((err) => {
        doConsole(err);
        setLoading(false);
      });
  }

  function getTargets(t) {
    const reqObj = {
      g_id: state.garageData.g_id,
      token: state.garageData.token,
      tp_year: t ? t : "2022"
    };
    setLoading(true);
    apiRequest(reqObj, "get_yearly_planner")
      .then((data) => {
        doConsole(data);
        setLoading(false);
        if (data.status == "1" && data.data.length) {
          setErrorMessage("");
          for (let key in data.data) {
            if (data.data[key].tp_month) {
              postData.tp_month[data.data[key].tp_month] = data.data[key].tp_month ? data.data[key].tp_month : "";
              postData.tp_profit[data.data[key].tp_month] = data.data[key].tp_profit ? data.data[key].tp_profit : "";
              postData.tp_qty[data.data[key].tp_month] = data.data[key].tp_qty ? data.data[key].tp_qty : "";
            }
          }
          forceUpdate();
        } else {
          // alert('asd')
          for (let i = 0; i <= 12; i++) {
            postData.tp_month[i] = ""
            postData.tp_profit[i] = ""
            postData.tp_qty[i] = ""
          }
          setErrorMessage(data.error_msg);
          window.scrollTo(0, 0);
          forceUpdate();

          // $("#datepicker-popup").focus();
        }
      })
      .catch((err) => {
        doConsole(err);
        setLoading(false);
      });
  }

  useEffect(() => {
    getTargets()
  }, [])

  return (



    <div className="main-panel">
      <div className="content-wrapper">

        {loading && <Loader />}
        {errorMessage && (<ErrorAlert onClose={() => setErrorMessage("")} errorMessage={errorMessage} />)}
        {success && (<SuccessAlert message={"Hurry! Target has been added!"} />)}

        <div className="row">
          <div className="col-sm-12">
            <div className="home-tab">
              <div className="tab-content tab-content-basic">
                <div
                  className="tab-pane fade show active"
                  id="overview"
                  role="tabpanel"
                  aria-labelledby="overview"
                >
                  <div className="row">
                    <div className="col-12">
                      <h3 className="text-black fw-bold">Target Planner</h3>
                      <br />
                    </div>
                    <div className="form-group col-12 col-lg-4 col-md-4 col-sm-12 float-left p-2">
                      <label for="exampleSelectGender">Select Year</label>
                      <select
                        required
                        onChange={(t) => {
                          getTargets(t.target.value)
                          // doConsole(t.target.value)
                        }}
                        // value={vData.vs_id}
                        className="form-control"
                        id="exampleSelectGender"
                      >
                        <option disabled selected >Select</option>
                        <option value={"2021"} >2021</option>
                        <option value={"2022"} >2022</option>
                        <option value={"2023"} >2023</option>
                        <option value={"2024"} >2024</option>
                        <option value={"2025"} >2025</option>
                        <option value={"2026"} >2026</option>
                        <option value={"2027"} >2027</option>
                        <option value={"2028"} >2028</option>
                        <option value={"2029"} >2029</option>
                        <option value={"2030"} >2030</option>
                        <option value={"2031"} >2031</option>
                        <option value={"2032"} >2032</option>
                        <option value={"2033"} >2033</option>
                        <option value={"2034"} >2034</option>
                        <option value={"2035"} >2035</option>
                        <option value={"2036"} >2036</option>
                        <option value={"2037"} >2037</option>
                        <option value={"2038"} >2038</option>
                        <option value={"2039"} >2039</option>
                        <option value={"2040"} >2040</option>
                        <option value={"2040"} >2040</option>
                        <option value={"2041"} >2041</option>
                        <option value={"2042"} >2042</option>
                        <option value={"2043"} >2043</option>
                        <option value={"2044"} >2044</option>
                        <option value={"2045"} >2045</option>
                        <option value={"2046"} >2046</option>
                        <option value={"2047"} >2047</option>
                        <option value={"2048"} >2048</option>
                        <option value={"2049"} >2049</option>
                        <option value={"2050"} >2050</option>
                        {/* {vSData?.map((v) => {
                        return (
                          <option value={v.vs_id}>{v.veh_status}</option>
                        );
                      })} */}
                      </select>
                    </div>
                  </div>


                  <div className="row">
                    <div className="col-lg-12 col-md-12 d-flex flex-column">
                      <div className="row flex-grow">
                        <div className="col-12 col-md-12 col-lg-12 grid-margin stretch-card">
                          <div className="card card-rounded text-center pt-2">
                            <div className="card-body">
                              <div className="d-sm-flex justify-content-between align-items-start">
                                <div className="row w-100">
                                  {/* Page Heading */}
                                  <div>
                                    {/* DataTales Example */}
                                    {/* <div className="card shadow mb-4" style={{ width: "100%" }} > */}
                                    <div className="card-body">
                                      <div className="table-responsive">
                                        {/* table className="table table-bordered dt-responsive" id="dataTable" width="100%" cellspacing="0" */}
                                        <table
                                          id="dataTable"
                                          className="table table-striped table-bordered dt-responsive"
                                          style={{ width: "100%" }}
                                        >
                                          <thead>
                                            <tr>
                                              <th className="tRow">
                                                Month
                                              </th>
                                              <th className="tRow">
                                                Vehicle Sale Target
                                              </th>
                                              <th className="tRow">
                                                Profit Target
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td className="text-justify">
                                                January
                                              </td>
                                              <td className="text-justify">
                                                <input
                                                  type="text"
                                                  className="form-control p-1"
                                                  id="exampleInputName1"
                                                  placeholder="January vehicles sale target"
                                                  onChange={(v) => {
                                                    let arr = postData.tp_qty;
                                                    arr[1] = v.target.value;
                                                    setPostData({
                                                      ...postData,
                                                      tp_qty: arr
                                                    });
                                                  }}
                                                  value={postData.tp_qty[1]}
                                                />
                                              </td>
                                              <td className="text-center">
                                                <input
                                                  type="text"
                                                  className="form-control p-1"
                                                  id="exampleInputName1"
                                                  placeholder="January profit target"
                                                  onChange={(v) => {
                                                    let arr = postData.tp_profit;
                                                    arr[1] = v.target.value;
                                                    let arrMonth = postData.tp_month;
                                                    arrMonth[1] = '1';
                                                    setPostData({
                                                      ...postData,
                                                      tp_profit: arr,
                                                      tp_month: arrMonth
                                                    });
                                                  }}
                                                  value={postData.tp_profit[1]}
                                                />
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="text-justify">
                                                February
                                              </td>
                                              <td className="text-justify">
                                                <input
                                                  type="text"
                                                  className="form-control p-1"
                                                  id="exampleInputName1"
                                                  placeholder="February vehicles sale target"
                                                  onChange={(v) => {
                                                    let arr = postData.tp_qty;
                                                    arr[2] = v.target.value;
                                                    setPostData({
                                                      ...postData,
                                                      tp_qty: arr
                                                    });
                                                  }}
                                                  value={postData.tp_qty[2]}
                                                />
                                              </td>
                                              <td className="text-center">
                                                <input
                                                  type="text"
                                                  className="form-control p-1"
                                                  id="exampleInputName1"
                                                  placeholder="February profit target"
                                                  onChange={(v) => {
                                                    let arr = postData.tp_profit;
                                                    arr[2] = v.target.value;
                                                    let arrMonth = postData.tp_month;
                                                    arrMonth[2] = '2';
                                                    setPostData({
                                                      ...postData,
                                                      tp_profit: arr,
                                                      tp_month: arrMonth
                                                    });
                                                  }}
                                                  value={postData.tp_profit[2]}
                                                />
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="text-justify">
                                                March
                                              </td>
                                              <td className="text-justify">
                                                <input
                                                  type="text"
                                                  className="form-control p-1"
                                                  id="exampleInputName1"
                                                  placeholder="March vehicles sale target"
                                                  onChange={(v) => {
                                                    let arr = postData.tp_qty;
                                                    arr[3] = v.target.value;
                                                    setPostData({
                                                      ...postData,
                                                      tp_qty: arr
                                                    });
                                                  }}
                                                  value={postData.tp_qty[3]}
                                                />
                                              </td>
                                              <td className="text-center">
                                                <input
                                                  type="text"
                                                  className="form-control p-1"
                                                  id="exampleInputName1"
                                                  placeholder="March profit target"
                                                  onChange={(v) => {
                                                    let arr = postData.tp_profit;
                                                    arr[3] = v.target.value;
                                                    let arrMonth = postData.tp_month;
                                                    arrMonth[3] = '3';
                                                    setPostData({
                                                      ...postData,
                                                      tp_profit: arr,
                                                      tp_month: arrMonth
                                                    });
                                                  }}
                                                  value={postData.tp_profit[3]}
                                                />
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="text-justify">
                                                April
                                              </td>
                                              <td className="text-justify">
                                                <input
                                                  type="text"
                                                  className="form-control p-1"
                                                  id="exampleInputName1"
                                                  placeholder="April vehicles sale target"
                                                  onChange={(v) => {
                                                    let arr = postData.tp_qty;
                                                    arr[4] = v.target.value;
                                                    setPostData({
                                                      ...postData,
                                                      tp_qty: arr
                                                    });
                                                  }}
                                                  value={postData.tp_qty[4]}
                                                />
                                              </td>
                                              <td className="text-center">
                                                <input
                                                  type="text"
                                                  className="form-control p-1"
                                                  id="exampleInputName1"
                                                  placeholder="April profit target"
                                                  onChange={(v) => {
                                                    let arr = postData.tp_profit;
                                                    arr[4] = v.target.value;
                                                    let arrMonth = postData.tp_month;
                                                    arrMonth[4] = '4';
                                                    setPostData({
                                                      ...postData,
                                                      tp_profit: arr,
                                                      tp_month: arrMonth
                                                    });
                                                  }}
                                                  value={postData.tp_profit[4]}
                                                />
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="text-justify">
                                                May
                                              </td>
                                              <td className="text-justify">
                                                <input
                                                  type="text"
                                                  className="form-control p-1"
                                                  id="exampleInputName1"
                                                  placeholder="May vehicles sale target"
                                                  onChange={(v) => {
                                                    let arr = postData.tp_qty;
                                                    arr[5] = v.target.value;
                                                    setPostData({
                                                      ...postData,
                                                      tp_qty: arr
                                                    });
                                                  }}
                                                  value={postData.tp_qty[5]}
                                                />
                                              </td>
                                              <td className="text-center">
                                                <input
                                                  type="text"
                                                  className="form-control p-1"
                                                  id="exampleInputName1"
                                                  placeholder="May profit target"
                                                  onChange={(v) => {
                                                    let arr = postData.tp_profit;
                                                    arr[5] = v.target.value;
                                                    let arrMonth = postData.tp_month;
                                                    arrMonth[5] = '5';
                                                    setPostData({
                                                      ...postData,
                                                      tp_profit: arr,
                                                      tp_month: arrMonth
                                                    });
                                                  }}
                                                  value={postData.tp_profit[5]}
                                                />
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="text-justify">
                                                June
                                              </td>
                                              <td className="text-justify">
                                                <input
                                                  type="text"
                                                  className="form-control p-1"
                                                  id="exampleInputName1"
                                                  placeholder="June vehicles sale target"
                                                  onChange={(v) => {
                                                    let arr = postData.tp_qty;
                                                    arr[6] = v.target.value;
                                                    setPostData({
                                                      ...postData,
                                                      tp_qty: arr
                                                    });
                                                  }}
                                                  value={postData.tp_qty[6]}
                                                />
                                              </td>
                                              <td className="text-center">
                                                <input
                                                  type="text"
                                                  className="form-control p-1"
                                                  id="exampleInputName1"
                                                  placeholder="June profit target"
                                                  onChange={(v) => {
                                                    let arr = postData.tp_profit;
                                                    arr[6] = v.target.value;
                                                    let arrMonth = postData.tp_month;
                                                    arrMonth[6] = '6';
                                                    setPostData({
                                                      ...postData,
                                                      tp_profit: arr,
                                                      tp_month: arrMonth
                                                    });
                                                  }}
                                                  value={postData.tp_profit[6]}
                                                />
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="text-justify">
                                                July
                                              </td>
                                              <td className="text-justify">
                                                <input
                                                  type="text"
                                                  className="form-control p-1"
                                                  id="exampleInputName1"
                                                  placeholder="July vehicles sale target"
                                                  onChange={(v) => {
                                                    let arr = postData.tp_qty;
                                                    arr[7] = v.target.value;
                                                    setPostData({
                                                      ...postData,
                                                      tp_qty: arr
                                                    });
                                                  }}
                                                  value={postData.tp_qty[7]}
                                                />
                                              </td>
                                              <td className="text-center">
                                                <input
                                                  type="text"
                                                  className="form-control p-1"
                                                  id="exampleInputName1"
                                                  placeholder="July profit target"
                                                  onChange={(v) => {
                                                    let arr = postData.tp_profit;
                                                    arr[7] = v.target.value;
                                                    let arrMonth = postData.tp_month;
                                                    arrMonth[7] = '7';
                                                    setPostData({
                                                      ...postData,
                                                      tp_profit: arr,
                                                      tp_month: arrMonth
                                                    });
                                                  }}
                                                  value={postData.tp_profit[7]}
                                                />
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="text-justify">
                                                August
                                              </td>
                                              <td className="text-justify">
                                                <input
                                                  type="text"
                                                  className="form-control p-1"
                                                  id="exampleInputName1"
                                                  placeholder="August vehicles sale target"
                                                  onChange={(v) => {
                                                    let arr = postData.tp_qty;
                                                    arr[8] = v.target.value;
                                                    setPostData({
                                                      ...postData,
                                                      tp_qty: arr
                                                    });
                                                  }}
                                                  value={postData.tp_qty[8]}
                                                />
                                              </td>
                                              <td className="text-center">
                                                <input
                                                  type="text"
                                                  className="form-control p-1"
                                                  id="exampleInputName1"
                                                  placeholder="August profit target"
                                                  onChange={(v) => {
                                                    let arr = postData.tp_profit;
                                                    arr[8] = v.target.value;
                                                    let arrMonth = postData.tp_month;
                                                    arrMonth[8] = '8';
                                                    setPostData({
                                                      ...postData,
                                                      tp_profit: arr,
                                                      tp_month: arrMonth
                                                    });
                                                  }}
                                                  value={postData.tp_profit[8]}
                                                />
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="text-justify">
                                                September
                                              </td>
                                              <td className="text-justify">
                                                <input
                                                  type="text"
                                                  className="form-control p-1"
                                                  id="exampleInputName1"
                                                  placeholder="September vehicles sale target"
                                                  onChange={(v) => {
                                                    let arr = postData.tp_qty;
                                                    arr[9] = v.target.value;
                                                    setPostData({
                                                      ...postData,
                                                      tp_qty: arr
                                                    });
                                                  }}
                                                  value={postData.tp_qty[9]}
                                                />
                                              </td>
                                              <td className="text-center">
                                                <input
                                                  type="text"
                                                  className="form-control p-1"
                                                  id="exampleInputName1"
                                                  placeholder="September profit target"
                                                  onChange={(v) => {
                                                    let arr = postData.tp_profit;
                                                    arr[9] = v.target.value;
                                                    let arrMonth = postData.tp_month;
                                                    arrMonth[9] = '9';
                                                    setPostData({
                                                      ...postData,
                                                      tp_profit: arr,
                                                      tp_month: arrMonth
                                                    });
                                                  }}
                                                  value={postData.tp_profit[9]}
                                                />
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="text-justify">
                                                October
                                              </td>
                                              <td className="text-justify">
                                                <input
                                                  type="text"
                                                  className="form-control p-1"
                                                  id="exampleInputName1"
                                                  placeholder="October vehicles sale target"
                                                  onChange={(v) => {
                                                    let arr = postData.tp_qty;
                                                    arr[10] = v.target.value;
                                                    setPostData({
                                                      ...postData,
                                                      tp_qty: arr
                                                    });
                                                  }}
                                                  value={postData.tp_qty[10]}
                                                />
                                              </td>
                                              <td className="text-center">
                                                <input
                                                  type="text"
                                                  className="form-control p-1"
                                                  id="exampleInputName1"
                                                  placeholder="October profit target"
                                                  onChange={(v) => {
                                                    let arr = postData.tp_profit;
                                                    arr[10] = v.target.value;
                                                    let arrMonth = postData.tp_month;
                                                    arrMonth[10] = '10';
                                                    setPostData({
                                                      ...postData,
                                                      tp_profit: arr,
                                                      tp_month: arrMonth
                                                    });
                                                  }}
                                                  value={postData.tp_profit[10]}
                                                />
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="text-justify">
                                                November
                                              </td>
                                              <td className="text-justify">
                                                <input
                                                  type="text"
                                                  className="form-control p-1"
                                                  id="exampleInputName1"
                                                  placeholder="November vehicles sale target"
                                                  onChange={(v) => {
                                                    let arr = postData.tp_qty;
                                                    arr[11] = v.target.value;
                                                    setPostData({
                                                      ...postData,
                                                      tp_qty: arr
                                                    });
                                                  }}
                                                  value={postData.tp_qty[11]}
                                                />
                                              </td>
                                              <td className="text-center">
                                                <input
                                                  type="text"
                                                  className="form-control p-1"
                                                  id="exampleInputName1"
                                                  placeholder="November profit target"
                                                  onChange={(v) => {
                                                    let arr = postData.tp_profit;
                                                    arr[11] = v.target.value;
                                                    let arrMonth = postData.tp_month;
                                                    arrMonth[11] = '11';
                                                    setPostData({
                                                      ...postData,
                                                      tp_profit: arr,
                                                      tp_month: arrMonth
                                                    });
                                                  }}
                                                  value={postData.tp_profit[11]}
                                                />
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="text-justify">
                                                December
                                              </td>
                                              <td className="text-justify">
                                                <input
                                                  type="text"
                                                  className="form-control p-1"
                                                  id="exampleInputName1"
                                                  placeholder="December vehicles sale target"
                                                  onChange={(v) => {
                                                    let arr = postData.tp_qty;
                                                    arr[12] = v.target.value;
                                                    setPostData({
                                                      ...postData,
                                                      tp_qty: arr
                                                    });
                                                  }}
                                                  value={postData.tp_qty[12]}
                                                />
                                              </td>
                                              <td className="text-center">
                                                <input
                                                  type="text"
                                                  className="form-control p-1"
                                                  id="exampleInputName1"
                                                  placeholder="December profit target"
                                                  onChange={(v) => {
                                                    let arr = postData.tp_profit;
                                                    arr[12] = v.target.value;
                                                    let arrMonth = postData.tp_month;
                                                    arrMonth[12] = '12';
                                                    setPostData({
                                                      ...postData,
                                                      tp_profit: arr,
                                                      tp_month: arrMonth
                                                    });
                                                  }}
                                                  value={postData.tp_profit[12]}
                                                />
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                      <div className="col-12 text-center pt-3">
                                        <button
                                          // type="submit"
                                          onClick={() => addTarget()}
                                          className="btn btn-primary me-2"
                                          style={{ backgroundColor: acolors.primary, color: 'white' }}
                                        >
                                          Save
                                        </button>
                                      </div>
                                    </div>
                                    {/* </div> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* content-wrapper ends */}
      {/* partial:partials/_footer.html */}
      <Footer />
      {/* partial */}
    </div>
  );
};
export default TargetPlanner;
