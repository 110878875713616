import React, { useEffect, useState, useContext } from "react";
import $ from "jquery";

import { Button, Modal } from "react-bootstrap";

import SideBar from "../../Components/SideBar";
import TopBar from "../../Components/TopBar";
import Footer from "../../Components/Footer";
import SettingPanel from "../../Components/SettingPanel";

import { Link } from "react-router-dom";
import { Context } from "../../Context/DataContext";
import { apiRequest } from "../../utils/apiCalls.js";
import { doConsole, useForceUpdate } from "../../utils/functions.js";
import Loader from "../../Components/Loader.js";
import { ErrorAlert, SuccessAlert } from "../../Components/Alerts.js";

const ListStaff = () => {
  const forceUpdate = useForceUpdate();
  const { state } = useContext(Context);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [staffData, setStaffData] = useState([]);
  const [delId, setDelId] = useState("");
  const [delModal, setDelModal] = useState("");

  function getStaff() {
    setLoading(true);
    const reqObj = {
      token: state.garageData.token,
      g_id: state.garageData.g_id,
    };
    apiRequest(reqObj, "get_staff")
      .then((data) => {
        doConsole(data);
        setLoading(false);
        if (data.status == "1") {
          doConsole(data.data);
          setStaffData(data.data);
        } else {
          setErrorMessage(data.error_msg);
          forceUpdate();
          window.scrollTo(0, 0);
        }
      })
      .catch((err) => {
        doConsole(err);
        setLoading(false);
      });
  }

  function doDel() {
    const reqObj = {
      token: state.garageData.token,
      g_id: state.garageData.g_id,
      st_id: delId,
    };
    setLoading(true);
    apiRequest(reqObj, "delete_staff")
      .then((data) => {
        doConsole(data);
        setLoading(false);
        if (data.status == "1") {
          if (data.data) {
            setStaffData(data.data);
          }
        } else {
          setErrorMessage(data.error_msg);
          window.scrollTo(0, 0);
        }
      })
      .catch((err) => {
        doConsole(err);
        setLoading(false);
      });
  }

  useEffect(() => {
    getStaff();
  }, []);

  useEffect(() => {
    // $(document).ready(function () {
    //     $('#dataTable').DataTable();
    // });
    $("#staff").addClass("show");
  }, [loading]);

  return (
   
        <div className="main-panel">
          <div className="content-wrapper">
            {loading && <Loader />}
            {errorMessage && (
              <ErrorAlert
                onClose={() => setErrorMessage("")}
                errorMessage={errorMessage}
              />
            )}
            {success && (
              <SuccessAlert message={"Hurry! Your vehicle has been saved!"} />
            )}
            <Modal show={delModal} onHide={() => setDelModal(false)}>
              <Modal.Header>
                <Modal.Title
                  style={{
                    fontSize: 18,
                    textAlign: "center",
                    alignSelf: "center",
                    fontFamily: "sans-serif",
                    fontWeight: "bold",
                  }}
                >
                  Are you sure you want to delete this staff member?
                </Modal.Title>
              </Modal.Header>
              <Modal.Footer>
                <Button
                  style={{ fontFamily: "sans-serif", fontWeight: "bold" }}
                  variant="secondary"
                  onClick={() => {
                    setDelModal(false);
                  }}
                >
                  No
                </Button>
                <Button
                  style={{ fontFamily: "sans-serif", fontWeight: "bold" }}
                  variant="danger"
                  onClick={() => {
                    setDelModal(false);
                    doDel();
                  }}
                >
                  Yes
                </Button>
              </Modal.Footer>
            </Modal>

            <div className="row">
              <div className="col-sm-12">
                <div className="home-tab">
                  <div className="tab-content tab-content-basic">
                    <div
                      className="tab-pane fade show active"
                      id="overview"
                      role="tabpanel"
                      aria-labelledby="overview"
                    >
                      <div className="row">
                        <div className="col-8 float-left">
                          <h3 className="text-black fw-bold">Manage Staff</h3>
                          <br />
                        </div>
                        <div className="col-4 text-right float-left">
                          <Link
                            style={{ textDecorationLine: "none" }}
                            to="/addStaff"
                            className="d-none d-sm-inline-block btn btn-sm btn-outline-primary shadow-sm"
                          >
                            <i className="fas fa-folder-plus fa-sm text-white-20" />{" "}
                            Add Staff
                          </Link>
                        </div>
                      </div>
                      <div>
                        {/* Page Heading */}
                        <div>
                          {/* DataTales Example */}
                          <div
                            className="card shadow mb-4"
                            style={{ width: "100%" }}
                          >
                            <div className="card-body">
                              <div className="table-responsive">
                                {/* table className="table table-bordered dt-responsive" id="dataTable" width="100%" cellspacing="0" */}
                                <table
                                  id="dataTable"
                                  className="table table-striped table-bordered dt-responsive"
                                  style={{ width: "100%" }}
                                >
                                  <thead>
                                    <tr>
                                      <th className="tRow">First Name</th>
                                      <th className="tRow">Last Name</th>
                                      <th className="tRow">Email</th>
                                      <th className="tRow">Phone</th>
                                      <th className="tRow">Departnment</th>
                                      <th className="tRow">Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {staffData.map((v, i) => {
                                      return (
                                        <tr key={i}>
                                          <td>
                                            <nobr>{v.st_fname}</nobr>
                                          </td>
                                          <td>
                                            <nobr>{v.st_lname}</nobr>
                                          </td>
                                          <td>
                                            <nobr>{v.st_email}</nobr>
                                          </td>
                                          <td>
                                            <nobr>{v.st_phone}</nobr>
                                          </td>
                                          <td>
                                            <nobr>{v.dep_title}</nobr>
                                          </td>
                                          <td>
                                            <span className="dtr-data">
                                              <Link
                                                style={{
                                                  textDecorationLine: "none",
                                                }}
                                                to={{
                                                  pathname: "/editStaff",
                                                  state: {
                                                    data: v,
                                                  },
                                                }}
                                                title="Edit Vehicle"
                                                className="btn btn-info"
                                              >
                                                <i className="fas fa-pencil-alt"></i>
                                              </Link>
                                              <Button
                                                onClick={() => {
                                                  setDelId(v.st_id);
                                                  setDelModal(true);
                                                }}
                                                title="Delete Vehicle"
                                                className="btn btn-danger del_customer"
                                              >
                                                <i className="fas fa-trash"></i>
                                              </Button>
                                              {/* <a href="/AddStaff" title="Edit Staff" className="btn btn-info">
                                                                                                    <i className="fas fa-pencil-alt"></i>
                                                                                                </a>
                                                                                                <a href="/DeleteStaff" title="Delete Staff" className="btn btn-danger del_customer" id="3">
                                                                                                    <i className="fas fa-trash">
                                                                                                    </i>
                                                                                                </a> */}
                                            </span>
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* content-wrapper ends */}
          {/* partial:partials/_footer.html */}
          <Footer />
          {/* partial */}
        </div>
        
  );
};

export default ListStaff;
