import React, { useContext } from "react";
// import { AppColors } from '../css/AppColors';
// import '../css/sb-admin-2.min.css'
// import '../App.css';
import { Link, useHistory, Redirect, Route } from "react-router-dom";

import { useEffect, useState } from "react";
import { apiRequest } from "../utils/apiCalls";

import { Context } from "../Context/DataContext";
import { Modal } from "react-bootstrap";
import Logo from "../images/logo.png";

import { Store as notify } from 'react-notifications-component';
import { alertMessage } from "../utils/helpers";
import { doConsole } from "../utils/functions";
import Loader from "../Components/Loader";

const Login = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [loginData, setLoginData] = useState({
    g_email: "",
    g_pass: "",
  });
  const [logined, setLogined] = useState("0");
  const { state, setGarageData } = useContext(Context);

  const [dep, setDepartments] = useState([]);
  const [dep_id, setDepId] = useState('');


  const AppColors = {
    primary: "green",
  };



  useEffect(() => {
    // localStorage.removeItem('store_data')
    // getDepartments();
    var checklogin = [];
    checklogin = localStorage.getItem("garage_data");
    console.log(checklogin);
    if (checklogin) {
      // localStorage.getItem('store_data') ?
      setGarageData();
      setLogined("1");
    } else setLogined("2");
  }, []);


  function login_staff() {


    const reqObj = {
      st_email: loginData.g_email,
      st_password: loginData.g_pass,

    }
    setLoading(true)
    apiRequest(reqObj, "login_staff")
      .then((data) => {
        console.log(data);
        setLoading(false);
        if (data.status == "1") {
          if (data?.screens.length) {
            localStorage.setItem("garage_data", JSON.stringify(data.data[0]));
            localStorage.setItem("screens", JSON.stringify(data?.screens ? data?.screens : []));
            setGarageData();
          }
          else {
            notify.addNotification(
              alertMessage({
                type: 'info',
                title: 'Access Denied!',
                message: "You are not allowed to access any screen. Please contact your admin",
                duration : 10000
              })
            );
            setLoading(false);
            return;
          }

          setLoading(false);
          // window.location = '/dashboard'
          window.location.pathname = '/dashboard'
        } else {
          notify.addNotification(
            alertMessage({
              type: 'danger',
              title: 'Error',
              message: data.error
            })
          );
        }
      })
      .catch((err) => {
        setLoading(false);
        notify.addNotification(
          alertMessage({
            type: 'danger',
            title: 'Error',
            message: "Network error"
          })
        );

      });


  }



  function login() {



    if (!loginData.g_email) {
      notify.addNotification(
        alertMessage({
          type: 'danger',
          title: 'Error',
          message: "Please enter a valid email"
        })
      );
      return;
    }
    if (!loginData.g_pass) {
      notify.addNotification(
        alertMessage({
          type: 'danger',
          title: 'Error',
          message: "Please enter password"
        })
      );
      return;
    }


    if (!dep_id) {
      notify.addNotification(
        alertMessage({
          type: 'danger',
          title: 'Error',
          message: "Please select your department"
        })
      );
      return;
    }

    if (dep_id != 1) { // login staff
      login_staff()
      return;
    }


    setLoading(true);

    apiRequest(loginData, "login")
      .then((data) => {
        console.log(data);
        setLoading(false);
        if (data.status == "1") {
          localStorage.setItem("garage_data", JSON.stringify(data.data[0]));
          setGarageData();
          setLoading(false);
          // window.location = '/dashboard'
          window.location.pathname = '/dashboard'
        } else {
          notify.addNotification(
            alertMessage({
              type: 'danger',
              title: 'Error',
              message: data.error
            })
          );
        }
      })
      .catch((err) => {
        setLoading(false);
        notify.addNotification(
          alertMessage({
            type: 'danger',
            title: 'Error',
            message: "Network error"
          })
        );

      });
  }

  function getDepartments() {
    setLoading(true);


    apiRequest('', "get_departments")
      .then((data) => {
        doConsole(data);
        setLoading(false);
        if (data.status == "1") {
          setDepartments(data.data);
          window.scrollTo(0, 0);
        } else {
          notify.addNotification(
            alertMessage({
              type: 'danger',
              title: 'Error',
              message: data.error_msg
            })
          );
        }
      })
      .catch((err) => {
        doConsole(err);
        setLoading(false);
      });
  }


  if (logined == "0") {
    return (
      <Modal show={true} onHide={() => setLoading(false)}>
        <Modal.Body className="text-center">
          <div
            className="text-center"
            className="spinner-border text-center"
            style={{ margin: 10, color: AppColors.primary }}
            role="status"
          ></div>
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <Route
      render={(props) =>
        logined == "2" ? (
          <div
            style={{
              width: "100%",
              height: "100vh",
              backgroundColor: "#E5E5E5",
              borderRadius: 10,
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            {loading && <Loader />}
            {/* <title>SB Admin 2 - Login</title> */}
            <div className="container">
              {/* Outer Row */}
              <div className="row justify-content-center">
                <div className="col-xl-9 col-lg-12 col-md-9">
                  <div className="card o-hidden border-0 shadow-lg my-5">
                    <div className="card-body p-0">
                      {/* Nested Row within Card Body */}
                      <div
                        className="row"
                        style={{
                          backgroundColor: "#1693BC",
                          paddingBottom: 50,
                          paddingTop: 50,
                          borderRadius: 10,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          className="col-lg-6 d-none d-lg-block text-center  "
                          style={{
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            style={{ textAlign: "center", width: "95%" }}
                            src={Logo}
                          />
                        </div>
                        <div className="col-lg-6">
                          <div className="p-5">
                            <div className="text-center">
                              <h1
                                className="h4 text-900 mb-4 RRe "
                                style={{ fontFamily: "monospace", fontWeight: "bold", fontSize: 32, color: "white", }}
                              >
                                Welcome!
                              </h1>
                            </div>
                            <form>
                              <div className="form-group">
                                <input
                                  type="email"
                                  required
                                  value={loginData.g_email}
                                  className="form-control"
                                  id="exampleInputEmail"
                                  aria-describedby="emailHelp"
                                  placeholder="Enter Email Address..."
                                  style={{ fontFamily: "monospace", height: 49, }}
                                  onChange={(t) => {
                                    setLoginData({
                                      ...loginData,
                                      g_email: t.target.value,
                                    });
                                  }}
                                />
                              </div>
                              <div className="form-group">
                                <input
                                  required
                                  type="password"
                                  value={loginData.g_pass}
                                  className="form-control form-control-user"
                                  id="exampleInputPassword"
                                  style={{ fontFamily: "monospace", height: 49, }}
                                  placeholder="Password"
                                  onChange={(t) => {
                                    setLoginData({
                                      ...loginData,
                                      g_pass: t.target.value,
                                    });
                                  }}
                                />
                              </div>

                              <div className="form-group">
                                <select
                                  required
                                  className="form-control form-control-user"
                                  id="exampleInputPassword"
                                  style={{ fontFamily: "monospace", height: 49, }}
                                  onChange={(t) => {
                                    const value = t.target.value
                                    setDepId(value)
                                    // get_access_screens(value)
                                  }}
                                >
                                  <option disabled selected >Select user type</option>
                                  <option value={"1"}>Super Admin</option>
                                  <option value={"0"}>Staff</option>
                                </select>

                              </div>


                              <div
                                style={{
                                  width: "100%",
                                  justifyContent: "center",
                                  display: "flex",
                                }}
                              >
                                <div
                                  onClick={() => login()}
                                  className="btn btn-user btn-block text-center "
                                  style={{
                                    backgroundColor: "#32BBE8",
                                    color: "white",
                                    fontFamily: "monospace",
                                    fontSize: 18,
                                    fontWeight: "bold",
                                    alignSelf: "center",
                                    textAlign: "center",
                                    display: "flex",
                                    width: 100,
                                    justifyContent: "center",
                                    flexDirection: "row",
                                  }}
                                >Login</div>
                              </div>




                            </form>
                            <hr style={{ backgroundColor: "white" }} />

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Redirect to="/dashboard" />
        )
      }
    />
  );
};

export default Login;
