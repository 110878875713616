import React from 'react'
import { Alert } from 'react-bootstrap'

export const ErrorAlert = ({ onClose, errorMessage }) => {
    return (
        <Alert
            id="error"
            dismissible
            variant="danger"
            onClose={onClose}>
            <Alert.Heading>Oh snap! {errorMessage}</Alert.Heading>
        </Alert>
    )
}

export const SuccessAlert = ({ onClose, message }) => {
    return (<Alert
        id="success"
        variant="success"
    >
        <Alert.Heading>{message}</Alert.Heading>
    </Alert>
    )
}

