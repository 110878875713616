import React, { useEffect, useState, useContext } from "react";
import $ from "jquery";

import { Modal } from "react-bootstrap";

import SideBar from "../../Components/SideBar";
import TopBar from "../../Components/TopBar";
import Footer from "../../Components/Footer";
import SettingPanel from "../../Components/SettingPanel";

import { Link } from "react-router-dom";
import { Context } from "../../Context/DataContext";
import { apiRequest } from "../../utils/apiCalls.js";
import { doConsole, useForceUpdate } from "../../utils/functions.js";
import Loader from "../../Components/Loader.js";
import { ErrorAlert, SuccessAlert } from "../../Components/Alerts.js";
import { useHistory, useLocation } from "react-router-dom";
import { acolors } from "../../css/AppColors";

const AccountStatement = () => {
  const forceUpdate = useForceUpdate();
  const { state } = useContext(Context);
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [trailAcc, setTrailAcc] = useState([]);
  const [delId, setDelId] = useState("");
  const [delModal, setDelModal] = useState("");

  const [accountTypes, setAccountTypes] = useState([]);
  const [accounts, setAccounts] = useState([]);

  const [postData, setPostData] = useState({
    token: state.garageData.token,
    g_id: state.garageData.g_id,
    j_ref: "",
    a_id: "",
    from_date: "",
    to_date: "",
  });

  function get_trail_acc() {
    setLoading(true);
    doConsole(postData);
    apiRequest(postData, "get_journal")
      .then((data) => {
        doConsole(data);
        setLoading(false);
        if (data.status == "1") {
          doConsole(data.data);
          setTrailAcc(data.data);
        } else {
          setErrorMessage(data.error_msg);
          forceUpdate();
          window.scrollTo(0, 0);
        }
      })
      .catch((err) => {
        doConsole(err);
        setLoading(false);
      });
  }

  useEffect(() => {
    // $(document).ready(function () {
    //     $('#dataTable').DataTable();
    // });
    $("#accounts").addClass("show");
  }, [loading]);

  useEffect(() => {
    get_trail_acc();
  }, []);
  return (

    <div className="main-panel">
      <div className="content-wrapper">
        {loading && <Loader />}
        {errorMessage && (
          <ErrorAlert
            onClose={() => setErrorMessage("")}
            errorMessage={errorMessage}
          />
        )}
        <div className="row">
          <div className="col-sm-12">
            <div className="home-tab">
              <div className="tab-content tab-content-basic">
                <div
                  className="tab-pane fade show active"
                  id="overview"
                  role="tabpanel"
                  aria-labelledby="overview"
                >
                  <div className="row">
                    <div className="col-8 float-left">
                      <h3 className="text-black fw-bold">Trail Account</h3>
                      <br />
                    </div>
                    <div className="col-4 text-right float-left">
                      <a
                        href="/addIncome"
                        className="d-none d-sm-inline-block btn btn-sm btn-outline-primary shadow-sm"
                      >
                        <i className="fas fa-folder-plus fa-sm text-white-20" />{" "}
                        Add Income/Expense
                      </a>
                    </div>
                  </div>
                  <div>
                    {/* Page Heading */}
                    <div>
                      {/* DataTales Example */}
                      <div
                        className="card shadow mb-4"
                        style={{ width: "100%" }}
                      >
                        <div className="card-body">
                          <div className="table-responsive">
                            {/* table className="table table-bordered dt-responsive" id="dataTable" width="100%" cellspacing="0" */}
                            <table
                              id="dataTable"
                              className="table table-striped table-bordered dt-responsive"
                              style={{ width: "100%" }}
                            >
                              <thead>
                                <tr>
                                  <th className="tRow">Date</th>
                                  <th className="tRow">Account</th>
                                  <th className="tRow">Debit</th>
                                  <th className="tRow">Credit</th>
                                </tr>
                              </thead>

                              <tbody>
                                {trailAcc?.map((v, i) => {
                                  return (
                                    <tr>
                                      <td>
                                        <nobr>{v.j_date}</nobr>
                                      </td>
                                      <td>
                                        <nobr>{v.a_title}</nobr>
                                      </td>
                                      <td className="text-right">
                                        <nobr>{v.j_debit}</nobr>
                                      </td>
                                      <td className="text-right">
                                        <nobr>{v.j_credit}</nobr>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* content-wrapper ends */}
      {/* partial:partials/_footer.html */}
      
      {/* partial */}
    </div>
  );
};

export default AccountStatement;
