import React, { useState, useContext, useEffect } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";

import Footer from "../../Components/Footer";


import { Context } from "../../Context/DataContext";
import { apiRequest } from "../../utils/apiCalls";
import { doConsole, useForceUpdate } from "../../utils/functions";
import Loader from "../../Components/Loader";
import { acolors } from "../../css/AppColors";
import { useHistory } from "react-router-dom";
import { ErrorAlert, SuccessAlert } from "../../Components/Alerts";
import { useLocation } from "react-router-dom";


const ViewSingleVehicle = () => {
    const { state } = useContext(Context);
    const forceUpdate = useForceUpdate();
    const history = useHistory();
    const location = useLocation();
    const [errorMessage, setErrorMessage] = useState("");
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [vSData, setVSData] = useState([]);
    const [vData, setVData] = React.useState({
        v_id: location?.state?.data?.v_id,
        file_no: location?.state?.data?.file_no,
        v_reg_no: location?.state?.data?.v_reg_no,
        vs_id: location?.state?.data?.vs_id,

        veh_status: location?.state?.data?.veh_status,
        v_purchase_date: location?.state?.data?.v_purchase_date,
        v_sale_date: location?.state?.data?.v_sale_date,
        v_mot: location?.state?.data?.v_mot,
        v_info_url: location?.state?.data?.v_info_url,
        v_make_model: location?.state?.data?.v_make_model,
        v_price: location?.state?.data?.v_price,
        v_prep_expenses: location?.state?.data?.v_prep_expenses,
        v_admin_fee: location?.state?.data?.v_admin_fee,
        v_delivery_charges: location?.state?.data?.v_delivery_charges,
        v_total_price: location?.state?.data?.v_total_price,
        v_sale_price: location?.state?.data?.v_sale_price,
        v_key_slot: location?.state?.data?.v_key_slot,
        token: state.garageData.token,
        g_id: state.garageData.g_id,
        v_price: location?.state?.data?.v_price,

        collection_date: location?.state?.data?.collection_date,
        collection_time: location?.state?.data?.collection_time ? location?.state?.data?.collection_time : "",
        collection_note: location?.state?.data?.collection_note,
        is_serviced: location?.state?.data?.is_serviced,
        serviced_tasks: location?.state?.data?.serviced_tasks,
        veh_color: location?.state?.data?.veh_color ? location?.state?.data?.veh_color : "",

        cust_name: location?.state?.data?.cust_name ? location?.state?.data?.cust_name : '',
        cust_email: location?.state?.data?.cust_email ? location?.state?.data?.cust_email : '',
        cust_phone: location?.state?.data?.cust_phone ? location?.state?.data?.cust_phone : '',

        sold_note: location?.state?.data?.sold_note ? location?.state?.data?.sold_note : '',

        deliver_status : location?.state?.data?.deliver_status ? location?.state?.data?.deliver_status : '',


    });

    const [vehStatusName, setVehStatusName] = useState("");

    const [totalPrice, setTotalPrice] = useState(
        location?.state?.data?.v_total_price
    );
    const [salePrice, setSalePrice] = useState(
        location?.state?.data?.v_sale_price
    );
    const [profit, setProfit] = useState(location?.state?.data?.v_profit);


    return (
        <div className="main-panel">
            <div className="content-wrapper">
                {loading && <Loader />}
                {errorMessage && (
                    <ErrorAlert
                        onClose={() => setErrorMessage("")}
                        errorMessage={errorMessage}
                    />
                )}
                {success && (
                    <SuccessAlert message={"Hurry! Your vehicle has been saved!"} />
                )}
                <div className="row">
                    <div className="col-sm-12">
                        <div className="home-tab">
                            <div className="tab-content tab-content-basic">
                                <div
                                    className="tab-pane fade show active"
                                    id="overview"
                                    role="tabpanel"
                                    aria-labelledby="overview"
                                >
                                    <div className="row">
                                        <div className="col-12">
                                            <h3 className="text-black fw-bold">List Vehicle</h3>
                                            <br />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 d-flex flex-column">
                                            <div className="row flex-grow">
                                                <div className="col-12 col-md-12 col-lg-12 grid-margin stretch-card">
                                                    <div className="card card-rounded">
                                                        <div className="card-body">
                                                            <div className="d-sm-flex justify-content-between align-items-start">
                                                                <div className="row w-100">
                                                                    {/* <form className="forms-sample"> */}
                                                                    <div className="form-group col-12  col-lg-4 col-md-4 col-sm-12 float-left p-2">
                                                                        <label for="exampleInputName1">
                                                                            File No.
                                                                        </label>
                                                                        <input
                                                                            disabled={true}
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="exampleInputName1"
                                                                            placeholder="File No."
                                                                            required
                                                                            onChange={(v) => {
                                                                                setVData({
                                                                                    ...vData,
                                                                                    file_no: v.target.value,
                                                                                });
                                                                            }}
                                                                            value={vData.file_no}
                                                                        />
                                                                    </div>
                                                                    <div
                                                                        className="form-group"
                                                                        className="form-group col-12 col-sm-12 col-md-4 col-lg-4 float-left p-2"
                                                                    >
                                                                        <label for="exampleInputName1">
                                                                            Registration No
                                                                        </label>
                                                                        <input
                                                                            disabled={true}
                                                                            required
                                                                            onChange={(t) => {
                                                                                doConsole(t.target.value);
                                                                                setVData({
                                                                                    ...vData,
                                                                                    v_reg_no: t.target.value,
                                                                                });
                                                                            }}
                                                                            value={vData.v_reg_no}
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="exampleInputName1"
                                                                            placeholder="Description"
                                                                        />
                                                                    </div>

                                                                    <div className="form-group col-12 col-lg-4 col-md-4 col-sm-12 float-left p-2">
                                                                        <label for="exampleSelectGender">
                                                                            Status
                                                                        </label>
                                                                        <input
                                                                            disabled={true}
                                                                            required
                                                                            value={vData.veh_status}
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="exampleInputName1"

                                                                        />


                                                                    </div>
                                                                    <div
                                                                        className="form-group"
                                                                        className="form-group col-12 col-sm-12 col-md-4 col-lg-4 float-left p-2"
                                                                    >
                                                                        <label for="exampleInputName1">
                                                                            Purchase date
                                                                        </label>
                                                                        <div
                                                                            id="datepicker-popup"
                                                                            className="input-group date datepicker navbar-date-picker"
                                                                        >
                                                                            <span className="input-group-addon input-group-prepend border-right">
                                                                                <span className="icon-calendar input-group-text calendar-icon"></span>
                                                                            </span>
                                                                            <input
                                                                                disabled={true}
                                                                                required
                                                                                onChange={(t) => {
                                                                                    doConsole(t.target.value);
                                                                                    setVData({
                                                                                        ...vData,
                                                                                        v_purchase_date: t.target.value,
                                                                                    });
                                                                                }}
                                                                                value={vData.v_purchase_date}
                                                                                type="date"
                                                                                className="form-control"
                                                                                placeholder="dd/mm/yyyy"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className="form-group"
                                                                        className="form-group col-12 col-sm-12 col-md-4 col-lg-4 float-left p-2"
                                                                    >
                                                                        <label for="exampleInputName1">
                                                                            Sale date
                                                                        </label>
                                                                        <div
                                                                            id="datepicker-popup"
                                                                            className="input-group date datepicker navbar-date-picker"
                                                                        >
                                                                            <span className="input-group-addon input-group-prepend border-right">
                                                                                <span className="icon-calendar input-group-text calendar-icon"></span>
                                                                            </span>
                                                                            <input
                                                                                disabled={true}
                                                                                onChange={(t) => {
                                                                                    doConsole(t.target.value);
                                                                                    setVData({
                                                                                        ...vData,
                                                                                        v_sale_date: t.target.value,
                                                                                    });
                                                                                }}
                                                                                value={vData.v_sale_date}
                                                                                type="date"
                                                                                className="form-control"
                                                                                placeholder="dd/mm/yyyy"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className="form-group"
                                                                        className="form-group col-12 col-sm-12 col-md-4 col-lg-4 float-left p-2"
                                                                    >
                                                                        <label for="exampleInputName1">MOT</label>
                                                                        <div
                                                                            id="datepicker-popup"
                                                                            className="input-group date datepicker navbar-date-picker"
                                                                        >
                                                                            <span className="input-group-addon input-group-prepend border-right">
                                                                                <span className="icon-calendar input-group-text calendar-icon"></span>
                                                                            </span>
                                                                            <input
                                                                                disabled={true}
                                                                                // required
                                                                                onChange={(t) => {
                                                                                    doConsole(t.target.value);
                                                                                    setVData({
                                                                                        ...vData,
                                                                                        v_mot: t.target.value,
                                                                                    });
                                                                                }}
                                                                                value={vData.v_mot}
                                                                                type="date"
                                                                                className="form-control"
                                                                                placeholder="dd/mm/yyyy"
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div
                                                                        className="form-group"
                                                                        className="form-group col-12 col-sm-12 col-md-3 col-lg-3 float-left p-2"
                                                                    >
                                                                        <label for="exampleInputName1">
                                                                            Vehicle Color
                                                                        </label>
                                                                        <input
                                                                            disabled={true}
                                                                            onChange={(t) => {
                                                                                setVData({
                                                                                    ...vData,
                                                                                    veh_color: t.target.value,
                                                                                });
                                                                            }}

                                                                            value={vData.veh_color}
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="exampleInputName1"
                                                                        />
                                                                    </div>

                                                                    <div
                                                                        className="form-group"
                                                                        className="form-group col-12 col-sm-12 col-md-3 col-lg-3 float-left p-2"
                                                                    >
                                                                        <label for="exampleInputName1">
                                                                            Info Link
                                                                        </label>
                                                                        <input
                                                                            disabled={true}
                                                                            // required
                                                                            onChange={(t) => {
                                                                                doConsole(t.target.value);
                                                                                setVData({
                                                                                    ...vData,
                                                                                    v_info_url: t.target.value,
                                                                                });
                                                                            }}
                                                                            value={vData.v_info_url}
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="exampleInputName1"
                                                                            placeholder="Info Link"
                                                                        />
                                                                    </div>
                                                                    <div
                                                                        className="form-group"
                                                                        className="form-group col-12 col-sm-12 col-md-4 col-lg-4 float-left p-2"
                                                                    >
                                                                        <label for="exampleInputName1">
                                                                            Make/Model
                                                                        </label>
                                                                        <input
                                                                            disabled={true}
                                                                            required
                                                                            onChange={(t) => {
                                                                                doConsole(t.target.value);
                                                                                setVData({
                                                                                    ...vData,
                                                                                    v_make_model: t.target.value,
                                                                                });
                                                                            }}
                                                                            value={vData.v_make_model}
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="exampleInputName1"
                                                                            placeholder="Make/Model"
                                                                        />
                                                                    </div>

                                                                    <div
                                                                        className="form-group"
                                                                        className="form-group col-12 col-sm-12 col-md-2 col-lg-2 p-2 float-left"
                                                                    >
                                                                        <label for="exampleInputName1">
                                                                            Key slot
                                                                        </label>
                                                                        <input
                                                                            disabled={true}
                                                                            // required
                                                                            onChange={(t) => {
                                                                                doConsole(t.target.value);
                                                                                setVData({
                                                                                    ...vData,
                                                                                    v_key_slot: t.target.value,
                                                                                });
                                                                            }}
                                                                            value={vData.v_key_slot}
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="exampleInputName1"
                                                                            placeholder="Key slot"
                                                                        />
                                                                    </div>

                                                                    {
                                                                        location?.state?.data?.vs_id == 5 &&
                                                                        <>
                                                                            <div
                                                                                className="form-group col-12 col-sm-12 col-md-4 col-lg-4 p-2 "
                                                                            >
                                                                                <label for="exampleInputName1">Delivery Status</label>
                                                                                <select
                                                                                    required
                                                                                    disabled
                                                                                    onChange={(t) => {
                                                                                        doConsole(t.target.value);
                                                                                        setVData({
                                                                                            ...vData,
                                                                                            deliver_status: t.target.value,
                                                                                        });
                                                                                    }}
                                                                                    value={vData.deliver_status}
                                                                                    className="form-control"
                                                                                // id="exampleSelectGender"
                                                                                >
                                                                                    <option value={"Pending"} >Pending</option>
                                                                                    <option value={"Ready for collection"} >Ready for collection</option>
                                                                                    <option value={"Delivered"} >Delivered</option>
                                                                                </select>
                                                                            </div>
                                                                            <div className="form-group col-12 col-sm-12 col-md-6 col-lg-6 p-2" ></div>
                                                                        </>
                                                                    }


                                                                    <div
                                                                        className="form-group col-12 col-sm-12 col-md-4 col-lg-4 float-left p-2"
                                                                    >
                                                                        <label for="exampleInputName1">
                                                                            Price
                                                                        </label>
                                                                        <input
                                                                            disabled={true}
                                                                            required
                                                                            min={0}
                                                                            onChange={(t) => {
                                                                                setVData({
                                                                                    ...vData,
                                                                                    v_price: t.target.value,
                                                                                });
                                                                            }}
                                                                            value={vData.v_price}
                                                                            type="number"
                                                                            className="form-control"
                                                                            id="exampleInputName1"
                                                                            placeholder="Price"
                                                                        />
                                                                    </div>
                                                                    <div
                                                                        className="form-group"
                                                                        className="form-group col-12 col-sm-12 col-md-4 col-lg-4 float-left p-2"
                                                                    >
                                                                        <label for="exampleInputName1">
                                                                            Prep. Expenses
                                                                        </label>
                                                                        <input
                                                                            disabled={true}
                                                                            required

                                                                            value={vData.v_prep_expenses}
                                                                            type="number"
                                                                            className="form-control"
                                                                            id="exampleInputName1"
                                                                            placeholder="Prep. Expenses"
                                                                        />
                                                                    </div>
                                                                    <div
                                                                        className="form-group"
                                                                        className="form-group col-12 col-sm-12 col-md-4 col-lg-4 float-left p-2"
                                                                    >
                                                                        <label for="exampleInputName1">
                                                                            Total Price
                                                                        </label>
                                                                        <input
                                                                            disabled={true}
                                                                            required
                                                                            onChange={(t) => {
                                                                                doConsole(t.target.value);
                                                                                setTotalPrice(t.target.value);
                                                                            }}
                                                                            value={totalPrice}
                                                                            type="number"
                                                                            className="form-control"
                                                                            id="exampleInputName1"
                                                                            placeholder="Total Price"
                                                                        />
                                                                    </div>
                                                                    <div
                                                                        className="form-group"
                                                                        className="form-group col-12 col-sm-12 col-md-3 col-lg-3 float-left p-2"
                                                                    >
                                                                        <label for="exampleInputName1">
                                                                            Admin Fee
                                                                        </label>
                                                                        <input
                                                                            disabled={true}

                                                                            value={vData.v_admin_fee}
                                                                            type="number"
                                                                            className="form-control"
                                                                            id="exampleInputName1"
                                                                            placeholder="Admin Fee"
                                                                        />
                                                                    </div>
                                                                    <div
                                                                        className="form-group"
                                                                        className="form-group col-12 col-sm-12 col-md-3 col-lg-3 float-left p-2"
                                                                    >
                                                                        <label for="exampleInputName1">
                                                                            Delivery Charges
                                                                        </label>
                                                                        <input
                                                                            disabled={true}
                                                                            value={vData.v_delivery_charges}
                                                                            type="number"
                                                                            className="form-control"
                                                                            id="exampleInputName1"
                                                                            placeholder="Delivery Charges"
                                                                        />
                                                                    </div>
                                                                    <div
                                                                        className="form-group"
                                                                        className="form-group col-12 col-sm-12 col-md-3 col-lg-3 float-left p-2"
                                                                    >
                                                                        <label for="exampleInputName1">
                                                                            Profit
                                                                        </label>
                                                                        <input
                                                                            disabled={true}
                                                                            value={profit}
                                                                            type="number"
                                                                            className="form-control"
                                                                            id="exampleInputName1"
                                                                            placeholder="Profit"
                                                                        />
                                                                    </div>
                                                                    <div
                                                                        className="form-group"
                                                                        className="form-group col-12 col-sm-12 col-md-3 col-lg-3 float-left p-2"
                                                                    >

                                                                        <label for="exampleInputName1">
                                                                            Sale Price
                                                                        </label>
                                                                        <input
                                                                            disabled={true}
                                                                            value={salePrice}
                                                                            type="number"
                                                                            className="form-control"
                                                                            id="exampleInputName1"
                                                                            placeholder="Sale Price"
                                                                        />
                                                                    </div>

                                                                    {/* Review 1 changes */}



                                                                    {
                                                                        vData.vs_id == '5' || vData.vs_id == '4' ?

                                                                            <div>
                                                                                <div
                                                                                    className="form-group"
                                                                                    className="form-group col-12 col-sm-12 col-md-4 col-lg-4 float-left p-2"
                                                                                >
                                                                                    <label for="exampleInputName1">
                                                                                        Cust Name
                                                                                    </label>
                                                                                    <input
                                                                                        // required
                                                                                        disabled
                                                                                        onChange={(t) => {
                                                                                            setVData({
                                                                                                ...vData,
                                                                                                cust_name: t.target.value,
                                                                                            });
                                                                                        }}
                                                                                        value={vData.cust_name}
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        id="exampleInputName1"
                                                                                        placeholder="Cust Name"
                                                                                    />
                                                                                </div>

                                                                                <div
                                                                                    className="form-group"
                                                                                    className="form-group col-12 col-sm-12 col-md-4 col-lg-4 float-left p-2"
                                                                                >
                                                                                    <label for="exampleInputName1">
                                                                                        Cust Email
                                                                                    </label>
                                                                                    <input
                                                                                        disabled
                                                                                        // required
                                                                                        onChange={(t) => {
                                                                                            setVData({
                                                                                                ...vData,
                                                                                                cust_email: t.target.value,
                                                                                            });
                                                                                        }}
                                                                                        value={vData.cust_email}
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        id="exampleInputName1"
                                                                                        placeholder="Cust Email"
                                                                                    />
                                                                                </div>

                                                                                <div
                                                                                    className="form-group"
                                                                                    className="form-group col-12 col-sm-12 col-md-4 col-lg-4 float-left p-2"
                                                                                >
                                                                                    <label for="exampleInputName1">
                                                                                        Cust Phone
                                                                                    </label>
                                                                                    <input
                                                                                        disabled
                                                                                        // required
                                                                                        onChange={(t) => {
                                                                                            setVData({
                                                                                                ...vData,
                                                                                                cust_phone: t.target.value,
                                                                                            });
                                                                                        }}
                                                                                        value={vData.cust_phone}
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        id="exampleInputName1"
                                                                                        placeholder="Cust Phone"
                                                                                    />
                                                                                </div>


                                                                                <div
                                                                                    className="form-group"
                                                                                    className="form-group col-12 col-sm-12 col-md-3 col-lg-3 float-left p-2"
                                                                                >
                                                                                    <label for="exampleInputName1">
                                                                                        Collection Date
                                                                                    </label>
                                                                                    <div
                                                                                        id="datepicker-popup"
                                                                                        className="input-group date datepicker navbar-date-picker"
                                                                                    >
                                                                                        <span className="input-group-addon input-group-prepend border-right">
                                                                                            <span className="icon-calendar input-group-text calendar-icon"></span>
                                                                                        </span>
                                                                                        <input
                                                                                            disabled
                                                                                            onChange={(t) => {
                                                                                                setVData({
                                                                                                    ...vData,
                                                                                                    collection_date: t.target.value,
                                                                                                });
                                                                                            }}
                                                                                            value={vData.collection_date}
                                                                                            type="date"
                                                                                            className="form-control"
                                                                                            placeholder="dd/mm/yyyy"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div
                                                                                    className="form-group"
                                                                                    className="form-group col-12 col-sm-12 col-md-3 col-lg-3 float-left p-2"
                                                                                >
                                                                                    <label for="exampleInputName1">
                                                                                        Collection Time
                                                                                    </label>
                                                                                    <div
                                                                                        id="datepicker-popup"
                                                                                        className="input-group date datepicker navbar-date-picker"
                                                                                    >
                                                                                        <span className="input-group-addon input-group-prepend border-right">
                                                                                            <span className="icon-calendar input-group-text calendar-icon"></span>
                                                                                        </span>
                                                                                        <input
                                                                                            disabled
                                                                                            onChange={(t) => {
                                                                                                setVData({
                                                                                                    ...vData,
                                                                                                    collection_time: t.target.value,
                                                                                                });
                                                                                            }}
                                                                                            value={vData.collection_time}
                                                                                            type="time"
                                                                                            className="form-control"
                                                                                            placeholder="00:00"
                                                                                        />
                                                                                    </div>
                                                                                </div>

                                                                                <div
                                                                                    className="form-group"
                                                                                    className="form-group col-12 col-sm-12 col-md-6 col-lg-6 float-left p-2"
                                                                                >
                                                                                    <label for="exampleInputName1">
                                                                                        Collection Note
                                                                                    </label>
                                                                                    <textarea
                                                                                        style={{ height: 120 }}
                                                                                        disabled
                                                                                        onChange={(t) => {
                                                                                            setVData({
                                                                                                ...vData,
                                                                                                collection_note: t.target.value,
                                                                                            });

                                                                                        }}
                                                                                        value={vData.collection_note}
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        id="exampleInputName1"
                                                                                        placeholder="Collection Note"
                                                                                    ></textarea>
                                                                                </div>
                                                                            </div>
                                                                            : null

                                                                    }
                                                                    {
                                                                        vData.vs_id == '5' ?
                                                                            <div
                                                                                className="form-group"
                                                                                className="form-group col-12 col-sm-12 col-md-12 col-lg-12 float-left p-2"
                                                                            >
                                                                                <label for="exampleInputName1">
                                                                                    Sold Note
                                                                                </label>
                                                                                <textarea
                                                                                    style={{ height: 120 }}
                                                                                    disabled
                                                                                    onChange={(t) => {
                                                                                        setVData({
                                                                                            ...vData,
                                                                                            sold_note: t.target.value,
                                                                                        });

                                                                                    }}
                                                                                    value={vData.sold_note}
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    id="exampleInputName1"
                                                                                    placeholder="Collection Note"
                                                                                ></textarea>
                                                                            </div>
                                                                            : null
                                                                    }

                                                                    <div
                                                                        // className="form-group"
                                                                        className="form-group col-12 col-sm-12 col-md-2 col-lg-2 float-left p-2"
                                                                    >

                                                                        <input
                                                                            disabled={true}
                                                                            onChange={(v) => {
                                                                                setVData({
                                                                                    ...vData,
                                                                                    is_serviced: v.target.checked == true ? '1' : '0'
                                                                                })
                                                                            }}
                                                                            checked={vData.is_serviced == '1' ? true : false}
                                                                            type="checkbox"
                                                                            value={vData.is_serviced == '1' ? true : false}
                                                                            placeholder="Serviced"
                                                                        />
                                                                        <label
                                                                            style={{ marginLeft: 10 }}
                                                                            for="exampleInputName1">
                                                                            Servicd
                                                                        </label>
                                                                    </div>

                                                                    <div
                                                                        className="form-group"
                                                                        className="form-group col-12 col-sm-12 col-md-10 col-lg-10 float-left p-2"
                                                                    >

                                                                        <label for="exampleInputName1">
                                                                            List of service tasks done
                                                                        </label>
                                                                        <textarea
                                                                            disabled={true}
                                                                            style={{ height: 100 }}
                                                                            // required
                                                                            onChange={(t) => {
                                                                                setVData({
                                                                                    ...vData,
                                                                                    serviced_tasks: t.target.value,
                                                                                });

                                                                            }}
                                                                            value={vData.serviced_tasks}
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="exampleInputName1"

                                                                        ></textarea>

                                                                    </div>



                                                                    {/* Review 1 changes ends */}




                                                                    <div className="col-12 text-center">

                                                                        <button
                                                                            onClick={history.goBack}
                                                                            className="btn btn-light">
                                                                            Back
                                                                        </button>
                                                                    </div>
                                                                    {/* </form> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};
export default ViewSingleVehicle