import React, { useState, useEffect, useContext } from 'react'
import $ from 'jquery';

import SideBar from '../../Components/SideBar';
import TopBar from '../../Components/TopBar';
import Footer from '../../Components/Footer';
import SettingPanel from '../../Components/SettingPanel';

import { Context } from '../../Context/DataContext';
import { apiRequest } from '../../utils/apiCalls';
import { doConsole, useForceUpdate } from '../../utils/functions';
import Loader from '../../Components/Loader';
import { acolors } from '../../css/AppColors';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { ErrorAlert, SuccessAlert } from '../../Components/Alerts';



const AddTask = () => {

    const { state } = useContext(Context);
    const forceUpdate = useForceUpdate();
    const history = useHistory();
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [success, setSuccess] = useState(false)
    const [postData, setPostData] = useState({
        g_id: state.garageData.g_id,
        token: state.garageData.token,
        tt_title: '',
        tt_type: ''
    });

    function doAddTask(event) {
        event.preventDefault();
        doConsole(postData)
        setLoading(true)
        apiRequest(postData, 'add_task_title')
            .then(data => {
                doConsole(data)
                setLoading(false);
                if (data.status == '1') {
                    setErrorMessage('');
                    setSuccess(true)
                    window.scrollTo(0, 0)
                    setTimeout(() => {
                        history.push('/ManageTasks');
                    }, 2000);
                }
                else {
                    setErrorMessage(data.error_msg);
                    forceUpdate();
                    window.scrollTo(0, 0)
                    // $("#datepicker-popup").focus();
                }
            })
            .catch(err => {
                doConsole(err)
                setLoading(false)
            })
    }


    React.useEffect(() => {
        $("#to_do").addClass("show");
    }, [])

    return (

        <div className="main-panel">
            <div className="content-wrapper">
                {loading && <Loader />}
                {errorMessage && <ErrorAlert onClose={() => setErrorMessage('')} errorMessage={errorMessage} />}
                {success && <SuccessAlert message={"Hurry! Your task has been saved!"} />}

                <div className="row">
                    <div className="col-sm-12">
                        <div className="home-tab">

                            <div className="tab-content tab-content-basic">
                                <div
                                    className="tab-pane fade show active"
                                    id="overview"
                                    role="tabpanel"
                                    aria-labelledby="overview"
                                >
                                    <div className="row">
                                        <div className="col-12">
                                            <h3 className="text-black fw-bold">Add Task</h3><br />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 d-flex flex-column">
                                            <div className="row flex-grow">
                                                <div className="col-12 col-md-12 col-lg-12 grid-margin stretch-card">
                                                    <div className="card card-rounded">
                                                        <div className="card-body">
                                                            <div className="justify-content-between align-items-start">
                                                                <form className="forms-sample"
                                                                    onSubmit={(event) => doAddTask(event)}
                                                                >
                                                                    <div className="row w-100">
                                                                        <div className="form-group col-12 col-lg-3 col-md-3 col-sm-12 float-left p-2">
                                                                            <label for="exampleSelectGender">Task Type</label>
                                                                            <select
                                                                                onChange={(t) => {
                                                                                    setPostData({
                                                                                        ...postData,
                                                                                        tt_type: t.target.value,
                                                                                    })
                                                                                }}
                                                                                value={postData.tt_type}

                                                                                className="form-control" id="exampleSelectGender">
                                                                                <option selected >Select task</option>
                                                                                <option value={"Vehicle"}>Vehicle</option>
                                                                                <option value={"Office"}>Office</option>
                                                                                <option value={"Sale"}>Sale</option>
                                                                                <option value={"Purchase"}>Purchase</option>
                                                                            </select>
                                                                        </div>
                                                                        <div className="form-group col-12  col-lg-6 col-md-6 col-sm-12 float-left p-2">
                                                                            <label for="exampleInputName1">Task Title</label>
                                                                            <input
                                                                                onChange={(t) => {
                                                                                    setPostData({
                                                                                        ...postData,
                                                                                        tt_title: t.target.value,

                                                                                    })
                                                                                }}
                                                                                value={postData.tt_title}
                                                                                type="text"
                                                                                className="form-control" id="exampleInputName1" placeholder="Task Title" />
                                                                        </div>
                                                                        <div className="form-group col-12 col-sm-12 col-md-3 col-lg-3 float-left p-2">
                                                                            <label for="exampleSelectGender">Status</label>
                                                                            <select className="form-control" id="exampleSelectGender">
                                                                                <option>Active</option>
                                                                                <option>Inactive</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-12 text-center">
                                                                            <input
                                                                                type="submit"
                                                                                title='Save'
                                                                                className="btn me-2"
                                                                                style={{ backgroundColor: acolors.primary, color: 'white' }}
                                                                            />
                                                                            <button className="btn btn-light">Cancel</button>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* content-wrapper ends */}
            {/* partial:partials/_footer.html */}
            <Footer />
            {/* partial */}
        </div>

    )
}

export default AddTask
