import React, { useContext, useState, useEffect } from "react";
import $, { post } from "jquery";

import SideBar from "../../Components/SideBar";
import TopBar from "../../Components/TopBar";
import Footer from "../../Components/Footer";
import SettingPanel from "../../Components/SettingPanel";

import { Context } from "../../Context/DataContext";
import { apiRequest } from "../../utils/apiCalls";
import { doConsole, useForceUpdate } from "../../utils/functions";
import Loader from "../../Components/Loader";
import { acolors } from "../../css/AppColors";
import { Link, useHistory, useLocation } from "react-router-dom";
import { ErrorAlert, SuccessAlert } from "../../Components/Alerts";

const AssignTask = () => {
  const { state } = useContext(Context);
  const forceUpdate = useForceUpdate();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);

  const [staff, setStaff] = useState([]);
  const [taskTitles, setTaskTitles] = useState([]);
  const [postData, setPostData] = useState({
    token: state?.garageData?.token,
    g_id: state?.garageData?.g_id,
    assign_to_id: "",
    task_title: "",
    task_priority: "",
    task_due_date: "",
    task_details: "",
  });

  function getStaff() {
    const reqObj = {
      token: state.garageData.token,
      g_id: state.garageData.g_id,
    };
    setLoading(true);
    apiRequest(reqObj, "get_staff")
      .then((data) => {
        doConsole(data);
        setLoading(false);
        if (data.status == "1") {
          if (data.data) {
            setStaff(data.data);
          }
        } else {
          setErrorMessage(data.error_msg);
          window.scrollTo(0, 0);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  function getTaskTitles() {
    const reqObj = {
      token: state.garageData.token,
      g_id: state.garageData.g_id,
    };
    setLoading(true);
    apiRequest(reqObj, "get_task_titles")
      .then((data) => {
        doConsole(data);
        setLoading(false);
        if (data.status == "1") {
          if (data.data) {
            setTaskTitles(data.data);
          }
        } else {
          setErrorMessage(data.error_msg);
          window.scrollTo(0, 0);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  function doAssignTask(event) {
    event.preventDefault();
    doConsole(postData);
    setLoading(true);
    apiRequest(postData, "assign_task")
      .then((data) => {
        doConsole(data);
        setLoading(false);
        if (data.status == "1") {
          setErrorMessage("");
          setSuccess(true);
          window.scrollTo(0, 0);
          setTimeout(() => {
            history.push("/viewOpenTasks");
          }, 2000);
        } else {
          setErrorMessage(data.error_msg);
          forceUpdate();
          window.scrollTo(0, 0);
          // $("#datepicker-popup").focus();
        }
      })
      .catch((err) => {
        doConsole(err);
        setLoading(false);
      });
  }

  // React.useEffect(() => {
  //     $("#to_do").addClass("show");
  // }, [])

  useEffect(() => {
    getStaff();
    getTaskTitles();
  }, []);

  return (
   
        <div className="main-panel">
          <div className="content-wrapper">
            {loading && <Loader />}
            {errorMessage && (
              <ErrorAlert
                onClose={() => setErrorMessage("")}
                errorMessage={errorMessage}
              />
            )}
            {success && (
              <SuccessAlert message={"Hurry! Your vehicle has been saved!"} />
            )}

            <div className="row">
              <div className="col-sm-12">
                <div className="home-tab">
                  <div className="tab-content tab-content-basic">
                    <div
                      className="tab-pane fade show active"
                      id="overview"
                      role="tabpanel"
                      aria-labelledby="overview"
                    >
                      <div className="row">
                        <div className="col-12">
                          <h3 className="text-black fw-bold">Assign Task</h3>
                          <br />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12 col-md-12 d-flex flex-column">
                          <div className="row flex-grow">
                            <div className="col-12 col-md-12 col-lg-12 grid-margin stretch-card">
                              <div className="card card-rounded">
                                <div className="card-body">
                                  <div className="justify-content-between align-items-start">
                                    <form
                                      onSubmit={(event) => doAssignTask(event)}
                                      className="forms-sample"
                                      action="/ManageTasks"
                                    >
                                      <div className="row w-100">
                                        <div className="form-group col-12 col-lg-3 col-md-3 col-sm-12 float-left p-2">
                                          <label for="exampleSelectGender">
                                            Assign to
                                          </label>
                                          <select
                                            onChange={(t) => {
                                              setPostData({
                                                ...postData,
                                                assign_to_id: t.target.value,
                                              });
                                            }}
                                            required
                                            value={postData.assign_to_id}
                                            className="form-control"
                                            id="exampleSelectGender"
                                          >
                                            <option
                                              disabled={
                                                postData.assign_to_id
                                                  ? true
                                                  : false
                                              }
                                            >
                                              Select
                                            </option>
                                            {staff?.map((v, i) => {
                                              return (
                                                <option value={v.st_id}>
                                                  {v.st_fname} {v.st_lname}{" "}
                                                </option>
                                              );
                                            })}
                                          </select>
                                        </div>
                                        <div className="form-group col-12 col-lg-3 col-md-3 col-sm-12 float-left p-2">
                                          <label for="exampleSelectGender">
                                            Select task
                                          </label>
                                          <select
                                            onChange={(t) => {
                                              setPostData({
                                                ...postData,
                                                task_title: t.target.value,
                                              });
                                            }}
                                            required
                                            value={postData.task_title}
                                            className="form-control"
                                            id="exampleSelectGender"
                                          >
                                            <option
                                              disabled={
                                                postData.task_title
                                                  ? true
                                                  : false
                                              }
                                            >
                                              Select
                                            </option>
                                            {taskTitles?.map((v, i) => {
                                              return (
                                                <option value={v.tt_id}>
                                                  {v.tt_title}
                                                </option>
                                              );
                                            })}
                                          </select>
                                        </div>
                                        <div className="form-group col-12 col-lg-3 col-md-3 col-sm-12 float-left p-2">
                                          <label for="exampleSelectGender">
                                            Priority
                                          </label>
                                          <select
                                            onChange={(t) => {
                                              setPostData({
                                                ...postData,
                                                task_priority: t.target.value,
                                              });
                                            }}
                                            required
                                            value={postData.task_priority}
                                            className="form-control"
                                            id="exampleSelectGender"
                                          >
                                            <option value="High">High</option>
                                            <option value="Medium">
                                              Medium
                                            </option>
                                            <option value="Normal">
                                              Normal
                                            </option>
                                          </select>
                                        </div>
                                        <div
                                          className="form-group"
                                          className="form-group col-12 col-sm-12 col-md-3 col-lg-3 float-left p-2"
                                        >
                                          <label for="exampleInputName1">
                                            Due date
                                          </label>
                                          <div
                                            id="datepicker-popup"
                                            className="input-group date datepicker navbar-date-picker"
                                          >
                                            <span className="input-group-addon input-group-prepend border-right">
                                              <span className="icon-calendar input-group-text calendar-icon"></span>
                                            </span>
                                            <input
                                              onChange={(t) => {
                                                setPostData({
                                                  ...postData,
                                                  task_due_date: t.target.value,
                                                });
                                              }}
                                              required
                                              value={postData.task_due_date}
                                              type="date"
                                              className="form-control"
                                              placeholder="dd/mm/yyyy"
                                            />
                                          </div>
                                        </div>
                                        <div className="form-group col-12  col-lg-12 col-md-12 col-sm-12 float-left p-2">
                                          <label for="exampleInputName1">
                                            Task detail
                                          </label>
                                          <input
                                            onChange={(t) => {
                                              setPostData({
                                                ...postData,
                                                task_details: t.target.value,
                                              });
                                            }}
                                            required
                                            value={postData.task_details}
                                            type="text"
                                            className="form-control"
                                            id="exampleInputName1"
                                            placeholder="Task detail"
                                          />
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-12 text-center">
                                          <input
                                            type="submit"
                                            title="Save"
                                            className="btn me-2"
                                            style={{
                                              backgroundColor: acolors.primary,
                                              color: "white",
                                            }}
                                          />

                                          <button className="btn btn-light">
                                            Cancel
                                          </button>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* content-wrapper ends */}
          {/* partial:partials/_footer.html */}
          <Footer />
          {/* partial */}
        </div>
  );
};

export default AssignTask;
