import React, { useEffect, useState } from "react";
import $ from "jquery";
// import "bootstrap/dist/css/bootstrap.min.css";
// import 'bootstrap/dist/js/bootstrap.bundle';

import { Modal } from "react-bootstrap";

import SideBar from "../../Components/SideBar";
import TopBar from "../../Components/TopBar";
import Footer from "../../Components/Footer";
import SettingPanel from "../../Components/SettingPanel";

const ViewClosedTasks = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // $(document).ready(function () {
    //     $('#dataTable').DataTable();
    // });
    $("#to_do").addClass("show");
  }, [loading]);

  return (
   
        <div className="main-panel">
          <div className="content-wrapper">
            <div className="row">
              <div className="col-sm-12">
                <div className="home-tab">
                  <div className="tab-content tab-content-basic">
                    <div
                      className="tab-pane fade show active"
                      id="overview"
                      role="tabpanel"
                      aria-labelledby="overview"
                    >
                      <div className="row">
                        <div className="col-8 float-left">
                          <h3 className="text-black fw-bold">Closed Tasks</h3>
                          <br />
                        </div>
                        <div className="col-4 text-right float-left">
                          <a
                            href="/assignTask"
                            className="d-none d-sm-inline-block btn btn-sm btn-outline-primary shadow-sm"
                          >
                            <i className="fas fa-folder-plus fa-sm text-white-20" />{" "}
                            Assign Task
                          </a>
                        </div>
                      </div>
                      <div>
                        <Modal show={loading} onHide={() => setLoading(false)}>
                          <Modal.Body className="text-center">
                            <div
                              className="text-center"
                              className="spinner-border text-center"
                              style={{ margin: 10 }}
                              role="status"
                            ></div>
                          </Modal.Body>
                        </Modal>
                        {/* Page Heading */}
                        <div>
                          {/* DataTales Example */}
                          <div
                            className="card shadow mb-4"
                            style={{ width: "100%" }}
                          >
                            <div className="card-body">
                              <div className="table-responsive">
                                {/* table className="table table-bordered dt-responsive" id="dataTable" width="100%" cellspacing="0" */}
                                <table
                                  id="dataTable"
                                  className="table table-striped table-bordered dt-responsive"
                                  style={{ width: "100%" }}
                                >
                                  <thead>
                                    <tr>
                                      <th
                                        className="tRow"
                                        style={{ width: "20%" }}
                                      >
                                        Assigned to
                                      </th>
                                      <th
                                        className="tRow"
                                        style={{ width: "30%" }}
                                      >
                                        Task - Details
                                      </th>
                                      <th
                                        className="tRow"
                                        style={{ width: "20%" }}
                                      >
                                        Due date
                                      </th>
                                      <th
                                        className="tRow"
                                        style={{ width: "15%" }}
                                      >
                                        Priority
                                      </th>
                                      <th
                                        className="tRow"
                                        style={{ width: "15%" }}
                                      >
                                        Status
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <nobr>Isaac</nobr>
                                        <br></br>
                                      </td>
                                      <td>
                                        <nobr>Wash - Wash car no. AAA3N21</nobr>
                                      </td>
                                      <td>
                                        <nobr>18/12/2021</nobr>
                                      </td>
                                      <td>
                                        <select className="form-control bg-white text-black-50">
                                          <option>High</option>
                                          <option>Medium</option>
                                          <option>Normal</option>
                                        </select>
                                      </td>
                                      <td>
                                        <select className="form-control bg-white text-black-50">
                                          <option>Open</option>
                                          <option>Inprogress</option>
                                          <option>Complete</option>
                                          <option selected>Close</option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <nobr>Imran</nobr>
                                        <br></br>
                                      </td>
                                      <td>
                                        <nobr>
                                          Purchase - Visit owner of AAA3N21 to
                                          view car.
                                        </nobr>
                                      </td>
                                      <td>
                                        <nobr>20/12/2021</nobr>
                                      </td>
                                      <td>
                                        <select className="form-control bg-white text-black-50">
                                          <option>High</option>
                                          <option>Medium</option>
                                          <option>Normal</option>
                                        </select>
                                      </td>
                                      <td>
                                        <select className="form-control bg-white text-black-50">
                                          <option>Open</option>
                                          <option>Inprogress</option>
                                          <option>Complete</option>
                                          <option selected>Close</option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <nobr>Ibrahim</nobr>
                                        <br></br>
                                      </td>
                                      <td>
                                        <nobr>
                                          Ad post - Ad post for car no. AAB3N18
                                        </nobr>
                                      </td>
                                      <td>
                                        <nobr>17/12/2021</nobr>
                                      </td>
                                      <td>
                                        <select className="form-control bg-white text-black-50">
                                          <option>High</option>
                                          <option>Medium</option>
                                          <option>Normal</option>
                                        </select>
                                      </td>
                                      <td>
                                        <select className="form-control bg-white text-black-50">
                                          <option>Open</option>
                                          <option>Inprogress</option>
                                          <option>Complete</option>
                                          <option selected>Close</option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <nobr>Isaac</nobr>
                                        <br></br>
                                      </td>
                                      <td>
                                        <nobr>
                                          Service - Mechenical service car no.
                                          AAC3N20
                                        </nobr>
                                      </td>
                                      <td>
                                        <nobr>24/12/2021</nobr>
                                      </td>
                                      <td>
                                        <select className="form-control bg-white text-black-50">
                                          <option>High</option>
                                          <option>Medium</option>
                                          <option>Normal</option>
                                        </select>
                                      </td>
                                      <td>
                                        <select className="form-control bg-white text-black-50">
                                          <option>Open</option>
                                          <option>Inprogress</option>
                                          <option>Complete</option>
                                          <option selected>Close</option>
                                        </select>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* content-wrapper ends */}
          {/* partial:partials/_footer.html */}
          <Footer />
      
        </div>
      
  );
};

export default ViewClosedTasks;
