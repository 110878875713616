import React, { useContext, useEffect, useState } from "react";

// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.bundle";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";



import $ from "jquery";

// import SideBar from "../../Components/SideBar";
// import TopBar from "../../Components/TopBar";
import Footer from "../../Components/Footer";

import { Context } from "../../Context/DataContext";
import { apiRequest } from "../../utils/apiCalls";
import { doConsole } from "../../utils/functions";
import Loader from "../../Components/Loader";
import { acolors } from "../../css/AppColors";
import { useHistory } from "react-router-dom";
import { ErrorAlert, SuccessAlert } from "../../Components/Alerts";

const useForceUpdate = () => {
  const [, updateState] = React.useState();
  return React.useCallback(() => updateState({}), []);
};

const AddVehicle = () => {
  const forceUpdate = useForceUpdate();
  const { state } = useContext(Context);
  const history = useHistory();

  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [vSData, setVSData] = useState([]);
  const [vData, setVData] = React.useState({
    file_no: "",
    v_reg_no: "",
    vs_id: "",
    v_purchase_date: "",
    v_sale_date: "",
    v_mot: "",
    v_info_url: "",
    v_make_model: "",
    v_price: 0,
    v_prep_expenses: 0,
    v_admin_fee: 0,
    v_delivery_charges: 0,
    v_total_price: 0,
    v_sale_price: 0,
    v_key_slot: "",
    token: state.garageData.token,
    g_id: state.garageData.g_id,
    veh_color: '',
    v_profit: 0,
    v_mileage: ''

  });

  const [totalPrice, setTotalPrice] = useState(0);
  const [salePrice, setSalePrice] = useState(0);
  const [profit, setProfit] = useState(0);

  function getVS() {
    setLoading(true);
    const reqObj = {
      token: state.garageData.token,
      g_id: state.garageData.g_id,
    };
    apiRequest(reqObj, "get_veh_statuses")
      .then((data) => {
        doConsole(data);
        setLoading(false);
        if (data.status == "1") {
          doConsole(data.data);
          setVData({
            ...vData,
            file_no: data.file_no,
          });
          setVSData(data.data);
          //   forceUpdate();
        } else {
          setErrorMessage(data.error_msg);
          forceUpdate();
          window.scrollTo(0, 0);
        }
      })
      .catch((err) => {
        doConsole(err);
        setLoading(false);
      });
  }

  React.useEffect(() => {
    $("#nav_vehicle").addClass("show");
    $("#add_vehicle").addClass("active");
  }, []);

  function addV(event) {
    event.preventDefault();
    vData.v_total_price = totalPrice;
    vData.v_sale_price = salePrice;
    vData.v_profit = profit;
    forceUpdate();
    doConsole(vData);
    setLoading(true);
    apiRequest(vData, "add_vehicle")
      .then((data) => {
        doConsole(data);
        setLoading(false);
        if (data.status == "1") {
          setErrorMessage("");
          setSuccess(true);
          window.scrollTo(0, 0);
          setTimeout(() => {
            history.push("/listVehicle");
          }, 2000);
        } else {
          setErrorMessage(data.error_msg);
          forceUpdate();
          window.scrollTo(0, 0);
          // $("#datepicker-popup").focus();
        }
      })
      .catch((err) => {
        doConsole(err);
        setLoading(false);
      });
  }

  // function fetch_veh_data() {
  //   const url = `https://uk1.ukvehicledata.co.uk/api/datapackage/VehicleData?v=2&api_nullitems=1&auth_apikey=f60fccf4-8d3a-4f0a-b88c-7a3315e22669&key_VRM=${vData.v_reg_no}`;
  //   console.log(url);
  //   fetch(url)
  //     .then((response) => response.json())
  //     // .then((response) => response.text())
  //     .then((responseJson) => {
  //       doConsole(responseJson);
  //     })
  //     .catch((error) => {
  //       console.log("error is");
  //       console.log(error);
  //       return "Internet error";
  //     });
  // }

  function calcTotalPrice() {
    var calcPrice =
      parseInt(vData.v_price) +
      parseInt(vData.v_prep_expenses);
    // parseInt(vData.v_admin_fee) +
    // parseInt(vData.v_delivery_charges);
    setTotalPrice(calcPrice);
    forceUpdate();
  }

  function calcSalePrice() {
    // sale_price = total_price + admin_fee + delivery_charges + profit;
    var calcPrice =
      parseInt(totalPrice) +
      parseInt(vData.v_admin_fee) +
      parseInt(vData.v_delivery_charges) +
      parseInt(profit);
    setSalePrice(calcPrice);
    forceUpdate();

  }

  function calcProfit(v) {


    var calcPrice =
      parseInt(v) -
      parseInt(totalPrice) -
      parseInt(vData.v_delivery_charges) -
      parseInt(vData.v_admin_fee);

    setProfit(calcPrice);
    forceUpdate();



  }
  useEffect(() => {
    getVS();
    // fetch_veh_data();
  }, []);

  return (
    <div className="main-panel">
      <div className="content-wrapper">
        {loading && <Loader />}
        {errorMessage && (<ErrorAlert onClose={() => setErrorMessage("")} errorMessage={errorMessage} />)}
        {success && (<SuccessAlert message={"Hurry! Your vehicle has been saved!"} />)}
        <div className="row">
          <div className="col-sm-12">
            <div className="home-tab">
              <div className="tab-content tab-content-basic">
                <div
                  className="tab-pane fade show active"
                  id="overview"
                  role="tabpanel"
                  aria-labelledby="overview"
                >
                  <div className="row">
                    <div className="col-12">
                      <h3 className="text-black fw-bold">Add Vehicle</h3>
                      <br />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 col-md-12 d-flex flex-column">
                      <div className="row flex-grow">
                        <div className="col-12 col-md-12 col-lg-12 grid-margin stretch-card">
                          <div className="card card-rounded">
                            <div className="card-body">
                              <div className="d-sm-flex justify-content-between align-items-start">
                                <div className="row w-100">
                                  <form
                                    onSubmit={(event) => addV(event)}
                                    className="forms-sample"
                                  >
                                    <div className="row w-100">
                                      <div className="form-group col-12  col-lg-3 col-md-3 col-sm-12 float-left p-2">
                                        <label for="exampleInputName1">
                                          File No.
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="exampleInputName1"
                                          placeholder="File No."
                                          required
                                          onChange={(v) => {
                                            setVData({
                                              ...vData,
                                              file_no: v.target.value,
                                            });
                                          }}
                                          value={vData.file_no}
                                        />
                                      </div>
                                      <div
                                        className="form-group"
                                        className="form-group col-12 col-sm-12 col-md-3 col-lg-3 float-left p-2"
                                      >
                                        <label for="exampleInputName1">
                                          Registration No
                                        </label>
                                        <input
                                          required
                                          onChange={(t) => {
                                            doConsole(t.target.value);
                                            setVData({
                                              ...vData,
                                              v_reg_no: t.target.value,
                                            });
                                          }}
                                          value={vData.v_reg_no}
                                          type="text"
                                          className="form-control"
                                          id="exampleInputName1"
                                          placeholder="Description"
                                        />
                                      </div>

                                      <div className="form-group col-12 col-lg-3 col-md-3 col-sm-12 float-left p-2">
                                        <label for="exampleSelectGender">Status</label>
                                        <select
                                          required
                                          onChange={(t) => {
                                            doConsole(t.target.value);
                                            setVData({
                                              ...vData,
                                              vs_id: t.target.value,
                                            });
                                          }}
                                          value={vData.vs_id}
                                          className="form-control"
                                        // id="exampleSelectGender"
                                        >
                                          <option
                                            disabled={vData.vs_id ? true : false}
                                          >
                                            Select
                                          </option>

                                          {vSData?.map((v, i) => {
                                            return (
                                              <option
                                                key={i}
                                                value={v.vs_id}
                                              >
                                                {v.veh_status}
                                              </option>
                                            );
                                          })}
                                        </select>
                                      </div>
                                      <div
                                        className="form-group"
                                        className="form-group col-12 col-sm-12 col-md-3 col-lg-3 float-left p-2"
                                      >
                                        <label for="exampleInputName1">
                                          Mileage
                                        </label>
                                        <input
                                          required
                                          onChange={(t) => {
                                            doConsole(t.target.value);
                                            setVData({
                                              ...vData,
                                              v_mileage: t.target.value,
                                            });
                                          }}
                                          value={vData.v_mileage}
                                          type="text"
                                          className="form-control"
                                          id="exampleInputName1"
                                        />
                                      </div>

                                      <div
                                        className="form-group col-12 col-sm-12 col-md-4 col-lg-4 float-left p-1"
                                      >
                                        <label for="exampleInputName1">
                                          Purchase date
                                        </label>
                                        <div
                                          id="datepicker-popup"
                                          className="input-group date datepicker navbar-date-picker"
                                        >
                                          <span className="input-group-addon input-group-prepend border-right">
                                            <span className="icon-calendar input-group-text calendar-icon"></span>
                                          </span>
                                          <input
                                            required
                                            onChange={(t) => {
                                              doConsole(t.target.value);
                                              setVData({
                                                ...vData,
                                                v_purchase_date: t.target.value,
                                              });
                                            }}
                                            value={vData.v_purchase_date}
                                            type="date"
                                            className="form-control"
                                            placeholder="dd/mm/yyyy"
                                          />
                                        </div>
                                      </div>
                                      <div
                                        className="form-group col-12 col-sm-12 col-md-4 col-lg-4 float-left p-2"
                                      >
                                        <label for="exampleInputName1">
                                          Sale date
                                        </label>
                                        <div
                                          id="datepicker-popup"
                                          className="input-group date datepicker navbar-date-picker"
                                        >
                                          <span className="input-group-addon input-group-prepend border-right">
                                            <span className="icon-calendar input-group-text calendar-icon"></span>
                                          </span>
                                          <input
                                            // required
                                            onChange={(t) => {
                                              doConsole(t.target.value);
                                              setVData({
                                                ...vData,
                                                v_sale_date: t.target.value,
                                              });
                                            }}
                                            value={vData.v_sale_date}
                                            type="date"
                                            className="form-control"
                                            placeholder="dd/mm/yyyy"
                                          />
                                        </div>
                                      </div>



                                      <div
                                        className="form-group col-12 col-sm-12 col-md-4 col-lg-4 float-left p-2"
                                      >
                                        <label for="exampleInputName1">MOT</label>
                                        <div
                                          id="datepicker-popup"
                                          className="input-group date datepicker navbar-date-picker"
                                        >
                                          <span className="input-group-addon input-group-prepend border-right">
                                            <span className="icon-calendar input-group-text calendar-icon"></span>
                                          </span>
                                          <input
                                            // required
                                            onChange={(t) => {
                                              doConsole(t.target.value);
                                              setVData({
                                                ...vData,
                                                v_mot: t.target.value,
                                              });
                                            }}
                                            value={vData.v_mot}
                                            type="date"
                                            className="form-control"
                                            placeholder="dd/mm/yyyy"
                                          />
                                        </div>
                                      </div>

                                      <div
                                        className="form-group"
                                        className="form-group col-12 col-sm-12 col-md-3 col-lg-3 float-left p-2"
                                      >
                                        <label for="exampleInputName1">
                                          Vehicle Color
                                        </label>
                                        <input
                                          onChange={(t) => {
                                            setVData({
                                              ...vData,
                                              veh_color: t.target.value,
                                            });
                                          }}

                                          value={vData.veh_color}
                                          type="text"
                                          className="form-control"
                                          id="exampleInputName1"
                                        />
                                      </div>


                                      <div
                                        className="form-group"
                                        className="form-group col-12 col-sm-12 col-md-3 col-lg-3 float-left p-2"
                                      >
                                        <label for="exampleInputName1">
                                          Info Link
                                        </label>
                                        <input
                                          // required
                                          onChange={(t) => {
                                            doConsole(t.target.value);
                                            setVData({
                                              ...vData,
                                              v_info_url: t.target.value,
                                            });
                                          }}
                                          value={vData.v_info_url}
                                          type="text"
                                          className="form-control"
                                          id="exampleInputName1"
                                          placeholder="Info Link"
                                        />
                                      </div>
                                      <div
                                        className="form-group"
                                        className="form-group col-12 col-sm-12 col-md-4 col-lg-4 float-left p-2"
                                      >
                                        <label for="exampleInputName1">
                                          Make/Model
                                        </label>
                                        <input
                                          required
                                          onChange={(t) => {
                                            doConsole(t.target.value);
                                            setVData({
                                              ...vData,
                                              v_make_model: t.target.value,
                                            });
                                          }}
                                          value={vData.v_make_model}
                                          type="text"
                                          className="form-control"
                                          id="exampleInputName1"
                                          placeholder="Make/Model"
                                        />
                                      </div>

                                      <div
                                        className="form-group"
                                        className="form-group col-12 col-sm-12 col-md-2 col-lg-2 p-2 float-left"
                                      >
                                        <label for="exampleInputName1">
                                          Key slot
                                        </label>
                                        <input
                                          // required
                                          onChange={(t) => {
                                            doConsole(t.target.value);
                                            setVData({
                                              ...vData,
                                              v_key_slot: t.target.value,
                                            });
                                          }}
                                          value={vData.v_key_slot}
                                          type="text"
                                          className="form-control"
                                          id="exampleInputName1"
                                          placeholder="Key slot"
                                        />
                                      </div>

                                      <div
                                        className="form-group"
                                        className="form-group col-12 col-sm-12 col-md-2 col-lg-4 float-left p-2"
                                      >
                                        <label for="exampleInputName1">
                                          Price
                                        </label>
                                        <input
                                          required
                                          min={0}
                                          onChange={(t) => {
                                            // doConsole(t.target.value);
                                            setVData({
                                              ...vData,
                                              v_price: t.target.value,
                                            });
                                          }}
                                          onBlur={(t) => {
                                            calcTotalPrice();
                                            // calcSalePrice();
                                          }}
                                          value={vData.v_price}
                                          type="number"
                                          className="form-control"
                                          id="exampleInputName1"
                                          placeholder="Price"
                                        />
                                      </div>
                                      <div
                                        className="form-group"
                                        className="form-group col-12 col-sm-12 col-md-4 col-lg-4 float-left p-2"
                                      >
                                        <label for="exampleInputName1">
                                          Prep. Expenses
                                        </label>
                                        <input
                                          required
                                          onChange={(t) => {
                                            // doConsole(t.target.value);
                                            setVData({
                                              ...vData,
                                              v_prep_expenses: t.target.value,
                                            });
                                          }}
                                          onBlur={(t) => {
                                            calcTotalPrice(t.target.value);
                                          }}
                                          value={vData.v_prep_expenses}
                                          type="number"
                                          className="form-control"
                                          id="exampleInputName1"
                                          placeholder="Prep. Expenses"
                                        />
                                      </div>

                                      <div
                                        className="form-group"
                                        className="form-group col-12 col-sm-12 col-md-4 col-lg-4 float-left p-2"
                                      >
                                        <label for="exampleInputName1">
                                          Total Price
                                        </label>
                                        <input
                                          required
                                          onChange={(t) => {
                                            doConsole(t.target.value);
                                            setTotalPrice(t.target.value);
                                          }}
                                          value={totalPrice}
                                          type="number"
                                          className="form-control"
                                          id="exampleInputName1"
                                          placeholder="Total Price"
                                        />
                                      </div>

                                      <div
                                        className="form-group"
                                        className="form-group col-12 col-sm-12 col-md-3 col-lg-3 float-left p-2"
                                      >
                                        <label for="exampleInputName1">
                                          Admin Fee
                                        </label>
                                        <input
                                          required
                                          onChange={(t) => {
                                            doConsole(t.target.value);
                                            setVData({
                                              ...vData,
                                              v_admin_fee: t.target.value,
                                            });
                                          }}
                                          onBlur={(t) => {
                                            // calcTotalPrice(t.target.value);
                                            calcSalePrice()

                                          }}
                                          value={vData.v_admin_fee}
                                          type="number"
                                          className="form-control"
                                          id="exampleInputName1"
                                          placeholder="Admin Fee"
                                        />
                                      </div>
                                      <div
                                        className="form-group"
                                        className="form-group col-12 col-sm-12 col-md-3 col-lg-3 float-left p-2"
                                      >
                                        <label for="exampleInputName1">
                                          Delivery Charges
                                        </label>
                                        <input
                                          required
                                          onChange={(t) => {
                                            doConsole(t.target.value);
                                            setVData({
                                              ...vData,
                                              v_delivery_charges: t.target.value,
                                            });
                                          }}
                                          onBlur={(t) => {
                                            calcSalePrice();
                                          }}
                                          value={vData.v_delivery_charges}
                                          type="number"
                                          className="form-control"
                                          id="exampleInputName1"
                                          placeholder="Delivery Charges"
                                        />
                                      </div>
                                      <div
                                        className="form-group"
                                        className="form-group col-12 col-sm-12 col-md-3 col-lg-3 float-left p-2"
                                      >
                                        <label for="exampleInputName1">
                                          Profit
                                        </label>
                                        <input
                                          // required
                                          onChange={(t) => {
                                            doConsole(t.target.value);
                                            // setVData({
                                            //   ...vData,
                                            //   v_profit: t.target.value,
                                            // });
                                            setProfit(t.target.value)
                                          }}
                                          onBlur={(t) => {
                                            calcSalePrice(t.target.value);
                                          }}
                                          value={profit}
                                          type="number"
                                          className="form-control"
                                          id="exampleInputName1"
                                          placeholder="Profit"
                                        />
                                      </div>

                                      <div
                                        className="form-group"
                                        className="form-group col-12 col-sm-12 col-md-3 col-lg-3 float-left p-2"
                                      >
                                        <label for="exampleInputName1">
                                          Sale Price
                                        </label>
                                        <input
                                          required
                                          onChange={(t) => {
                                            doConsole(t.target.value);
                                            setSalePrice(t.target.value);
                                            calcProfit(t.target.value);
                                            // setVData({
                                            //   ...vData,
                                            //   v_sale_price: t.target.value,
                                            // });
                                          }}

                                          // value={vData.v_sale_price}
                                          value={salePrice}
                                          type="number"
                                          className="form-control"
                                          id="exampleInputName1"
                                          placeholder="Sale Price"
                                        />
                                      </div>



                                      <div className="col-12 text-center">
                                        <input
                                          type="submit"
                                          value="Save"
                                          className="btn me-2"
                                          // onClick={() => addCust()}
                                          style={{
                                            backgroundColor: acolors.primary,
                                            color: "white",
                                          }}
                                        />

                                        <button className="btn btn-light">
                                          Cancel
                                        </button>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AddVehicle;

// {/* <div className="theme-setting-wrapper">
//                     <div id="settings-trigger">
//                         <i className="ti-settings" />
//                     </div>
//                     <div id="theme-settings" className="settings-panel">
//                         <i className="settings-close ti-close" />
//                         <p className="settings-heading">SIDEBAR SKINS</p>
//                         <div className="sidebar-bg-options selected" id="sidebar-light-theme">
//                             <div className="img-ss rounded-circle bg-light border me-3" />
//                             Light
//                         </div>
//                         <div className="sidebar-bg-options" id="sidebar-dark-theme">
//                             <div className="img-ss rounded-circle bg-dark border me-3" />
//                             Dark
//                         </div>
//                         <p className="settings-heading mt-2">HEADER SKINS</p>

//                         <div className="color-tiles mx-0 px-4">
//                             <div className="tiles success" />
//                             <div className="tiles warning" />
//                             <div className="tiles danger" />
//                             <div className="tiles info" />
//                             <div className="tiles dark" />
//                             <div className="tiles default" />
//                         </div>
//                     </div>
//                 </div>
//                 <div id="right-sidebar" className="settings-panel">
//                     <i className="settings-close ti-close" />
//                     <ul
//                         className="nav nav-tabs border-top"
//                         id="setting-panel"
//                         role="tablist"
//                     >
//                         <li className="nav-item">
//                             <a
//                                 className="nav-link active"
//                                 id="todo-tab"
//                                 data-bs-toggle="tab"
//                                 href="#todo-section"
//                                 role="tab"
//                                 aria-controls="todo-section"
//                                 aria-expanded="true"
//                             >
//                                 TO DO LIST
//                             </a>
//                         </li>
//                         <li className="nav-item">
//                             <a
//                                 className="nav-link"
//                                 id="chats-tab"
//                                 data-bs-toggle="tab"
//                                 href="#chats-section"
//                                 role="tab"
//                                 aria-controls="chats-section"
//                             >
//                                 CHATS
//                             </a>
//                         </li>
//                     </ul>
//                     <div className="tab-content" id="setting-content">
//                         <div
//                             className="tab-pane fade show active scroll-wrapper"
//                             id="todo-section"
//                             role="tabpanel"
//                             aria-labelledby="todo-section"
//                         >
//                             <div className="add-items d-flex px-3 mb-0">
//                                 <form className="form w-100">
//                                     <div className="form-group d-flex">
//                                         <input
//                                             type="text"
//                                             className="form-control todo-list-input"
//                                             placeholder="Add To-do"
//                                         />
//                                         <button
//                                             type="submit"
//                                             className="add btn btn-primary todo-list-add-btn"
//                                             id="add-task"
//                                         >
//                                             Add
//                                         </button>
//                                     </div>
//                                 </form>
//                             </div>

//                             <div className="list-wrapper px-3">
//                                 <ul className="d-flex flex-column-reverse todo-list">
//                                     <li>
//                                         <div className="form-check">
//                                             <label className="form-check-label">
//                                                 <input className="checkbox" type="checkbox" />
//                                                 Team review meeting at 3.00 PM
//                                             </label>
//                                         </div>
//                                         <i className="remove ti-close" />
//                                     </li>
//                                     <li>
//                                         <div className="form-check">
//                                             <label className="form-check-label">
//                                                 <input className="checkbox" type="checkbox" />
//                                                 Prepare for presentation
//                                             </label>
//                                         </div>
//                                         <i className="remove ti-close" />
//                                     </li>
//                                     <li>
//                                         <div className="form-check">
//                                             <label className="form-check-label">
//                                                 <input className="checkbox" type="checkbox" />
//                                                 Resolve all the low priority tickets due today
//                                             </label>
//                                         </div>
//                                         <i className="remove ti-close" />
//                                     </li>
//                                     <li className="completed">
//                                         <div className="form-check">
//                                             <label className="form-check-label">
//                                                 <input
//                                                     className="checkbox"
//                                                     type="checkbox"
//                                                     defaultChecked
//                                                 />
//                                                 Schedule meeting for next week
//                                             </label>
//                                         </div>
//                                         <i className="remove ti-close" />
//                                     </li>
//                                     <li className="completed">
//                                         <div className="form-check">
//                                             <label className="form-check-label">
//                                                 <input
//                                                     className="checkbox"
//                                                     type="checkbox"
//                                                     defaultChecked
//                                                 />
//                                                 Project review
//                                             </label>
//                                         </div>
//                                         <i className="remove ti-close" />
//                                     </li>
//                                 </ul>
//                             </div>
//                             <h4 className="px-3 text-muted mt-5 fw-light mb-0">Events</h4>
//                             <div className="events pt-4 px-3">
//                                 <div className="wrapper d-flex mb-2">
//                                     <i className="ti-control-record text-primary me-2" />
//                                     <span>Feb 11 2018</span>
//                                 </div>
//                                 <p className="mb-0 font-weight-thin text-gray">
//                                     Creating component page build a js
//                                 </p>
//                                 <p className="text-gray mb-0">The total number of sessions</p>
//                             </div>
//                             <div className="events pt-4 px-3">
//                                 <div className="wrapper d-flex mb-2">
//                                     <i className="ti-control-record text-primary me-2" />
//                                     <span>Feb 7 2018</span>
//                                 </div>
//                                 <p className="mb-0 font-weight-thin text-gray">
//                                     Meeting with Alisa
//                                 </p>
//                                 <p className="text-gray mb-0 ">Call Sarah Graves</p>
//                             </div>
//                         </div>
//                         {/* To do section tab ends */}
//                         <div
//                             className="tab-pane fade"
//                             id="chats-section"
//                             role="tabpanel"
//                             aria-labelledby="chats-section"
//                         >
//                             <div className="d-flex align-items-center justify-content-between border-bottom">
//                                 <p className="settings-heading border-top-0 mb-3 pl-3 pt-0 border-bottom-0 pb-0">
//                                     Friends
//                                 </p>
//                                 <small className="settings-heading border-top-0 mb-3 pt-0 border-bottom-0 pb-0 pr-3 fw-normal">
//                                     See All
//                                 </small>
//                             </div>
//                         </div>
//                         {/* chat tab ends */}
//                     </div>
//                 </div> */}
