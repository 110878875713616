import createDataContext from "./createDataContext";
// import React from 'react'









const dataReducer = (state, action) => {
    switch (action.type) {
        case 'setCustomers':
            return { ...state, customers: action.payload }
        case 'setProducts':
            return { ...state, products: action.payload }
        case 'setGarageData':
            return { ...state, garageData: action.payload }
        case 'setManufacturs':
            return { ...state, manufacturs: action.payload }
        default: return state
    }

}


const setCustomersGlobal = dispatch => {
    return (data) => {
        dispatch({ type: 'setCustomers', payload: data })
    }
}

const setProductsGlobal = dispatch => {
    return (data) => {
        dispatch({ type: 'setProducts', payload: data })
    }
}

const setManufactursGlobal = dispatch => {
    return (data) => {
        dispatch({ type: 'setManufacturs', payload: data })
    }
}

const setGarageData = dispatch => {
    return async () => {
        return localStorage.getItem('garage_data') &&
            dispatch({ type: 'setGarageData', payload: JSON.parse(localStorage.getItem('garage_data')) })
    }
}

export const { Provider, Context } = createDataContext(
    dataReducer,
    {
        setCustomersGlobal,
        setProductsGlobal,
        setGarageData,
        setManufactursGlobal
    },
    {
        customers: [],
        products : [],
        garageData: [],
        manufacturs : [],
        customersState : null
    }
)