import React, { useEffect, useState, useContext } from "react";
import $ from "jquery";

import { Modal } from "react-bootstrap";

import SideBar from "../../Components/SideBar";
import TopBar from "../../Components/TopBar";
import Footer from "../../Components/Footer";
import SettingPanel from "../../Components/SettingPanel";

import { Context } from "../../Context/DataContext";
import { apiRequest } from "../../utils/apiCalls";
import { doConsole, useForceUpdate } from "../../utils/functions";
import Loader from "../../Components/Loader";
import { acolors } from "../../css/AppColors";
import { useHistory } from "react-router-dom";
import { ErrorAlert, SuccessAlert } from "../../Components/Alerts";
import { useLocation } from "react-router-dom";

const VehicleStockReport = () => {
  const { state } = useContext(Context);
  const forceUpdate = useForceUpdate();
  const history = useHistory();
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  function getMots() {
    setLoading(true);
    const reqObj = {
      token: state.garageData.token,
      g_id: state.garageData.g_id,
    };
    apiRequest(reqObj, "get_veh_stocks")
      .then((data) => {
        doConsole(data);
        setLoading(false);
        if (data.status == "1") {
          doConsole(data.data);
          setData(data.data);
        } else {
          setErrorMessage(data.error_msg);
          forceUpdate();
          window.scrollTo(0, 0);
        }
      })
      .catch((err) => {
        doConsole(err);
        setLoading(false);
      });
  }

  useEffect(() => {
    // $(document).ready(function () {
    //     $('#dataTable').DataTable();
    // });
    $("#nav_vehicle").addClass("show");
  }, [loading]);

  return (
        <div className="main-panel">
          <div className="content-wrapper">
            {loading && <Loader />}
            {errorMessage && (
              <ErrorAlert
                onClose={() => setErrorMessage("")}
                errorMessage={errorMessage}
              />
            )}
            {success && (
              <SuccessAlert message={"Hurry! Your vehicle has been updated!"} />
            )}

            <div className="row">
              <div className="col-sm-12">
                <div className="home-tab">
                  <div className="tab-content tab-content-basic">
                    <div
                      className="tab-pane fade show active"
                      id="overview"
                      role="tabpanel"
                      aria-labelledby="overview"
                    >
                      <div className="row">
                        <div className="col-8 float-left">
                          <h3 className="text-black fw-bold">
                            Vehicles in Stock
                          </h3>
                          <br />
                        </div>
                        <div className="col-4 text-right float-left">
                          <a
                            href="/addVehicle"
                            className="d-none d-sm-inline-block btn btn-sm btn-outline-primary shadow-sm"
                          >
                            <i className="fas fa-folder-plus fa-sm text-white-20" />{" "}
                            Add Vehicle
                          </a>
                        </div>
                      </div>
                      <div>
                        {/* Page Heading */}
                        <div>
                          {/* DataTales Example */}
                          <div
                            className="card shadow mb-4"
                            style={{ width: "100%" }}
                          >
                            <div className="card-body">
                              <div className="table-responsive">
                                {/* table className="table table-bordered dt-responsive" id="dataTable" width="100%" cellspacing="0" */}
                                <table
                                  id="dataTable"
                                  className="table table-striped table-bordered dt-responsive"
                                  style={{ width: "100%" }}
                                >
                                  <thead>
                                    <tr>
                                      <th className="tRow">File No.</th>
                                      <th className="tRow">Reg. No.</th>
                                      <th className="tRow">Make/Model</th>
                                      <th className="tRow">Purchase</th>
                                      <th className="tRow">Days in stock</th>
                                      <th className="tRow">MOT</th>
                                      <th className="tRow">Status</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {data?.map((v, i) => {
                                      return (
                                        <tr key={i}>
                                          <td>
                                            <nobr>
                                              <a href="#!" />
                                              {v.file_no}
                                            </nobr>
                                          </td>
                                          <td>
                                            <nobr>{v.v_reg_no}</nobr>
                                          </td>
                                          <td>
                                            <nobr>{v.v_make_model}</nobr>
                                          </td>
                                          <td>
                                            <nobr>{v.v_purchase_date}</nobr>
                                          </td>
                                          <td>
                                            <nobr>{v.days_in_stock}</nobr>
                                          </td>
                                          <td>
                                            <nobr>{v.v_mot}</nobr>
                                          </td>
                                          <td>
                                            <nobr>{v.veh_status}</nobr>
                                          </td>
                                        </tr>
                                      );
                                    })}

                                    <tr>
                                      <td>
                                        <nobr>
                                          <a href="#!" />
                                          222
                                        </nobr>
                                      </td>
                                      <td>
                                        <nobr>BBB3A20</nobr>
                                      </td>
                                      <td>
                                        <nobr>TOYATA YARIS</nobr>
                                      </td>
                                      <td>
                                        <nobr>28/10/2021</nobr>
                                      </td>
                                      <td>
                                        <nobr>37 Days</nobr>
                                      </td>
                                      <td>
                                        <nobr>05/12/2022</nobr>
                                      </td>
                                      <td>
                                        <nobr>Reserved</nobr>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <nobr>
                                          <a href="#!" />
                                          333
                                        </nobr>
                                      </td>
                                      <td>
                                        <nobr>CCC3A20</nobr>
                                      </td>
                                      <td>
                                        <nobr>BMW</nobr>
                                      </td>
                                      <td>
                                        <nobr>13/10/2021</nobr>
                                      </td>
                                      <td>
                                        <nobr>50 Days</nobr>
                                      </td>
                                      <td>
                                        <nobr>05/12/2022</nobr>
                                      </td>
                                      <td>
                                        <nobr>In-stock</nobr>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <nobr>
                                          <a href="#!" />
                                          444
                                        </nobr>
                                      </td>
                                      <td>
                                        <nobr>DDD3A20</nobr>
                                      </td>
                                      <td>
                                        <nobr>TOYATA</nobr>
                                      </td>
                                      <td>
                                        <nobr>05/10/2021</nobr>
                                      </td>
                                      <td>
                                        <nobr>60 Days</nobr>
                                      </td>
                                      <td>
                                        <nobr>05/12/2022</nobr>
                                      </td>
                                      <td>
                                        <nobr>Ad pending</nobr>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* content-wrapper ends */}
          {/* partial:partials/_footer.html */}
          <Footer />
          {/* partial */}
        </div>
        
  );
};

export default VehicleStockReport;
