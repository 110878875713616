import React from "react";
// import { Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
// import logoutIcon from "../images/logoutIconImg.png";
import { useHistory } from "react-router";
import $ from "jquery";

import "bootstrap/dist/css/bootstrap.min.css";
// import 'bootstrap/dist/js/bootstrap.bundle';
import "bootstrap/dist/js/bootstrap.bundle";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { useEffect } from "react";
import { useState } from "react";
// import { useForceUpdate } from "../utils/functions";



const useForceUpdate = () => {
  const [, updateState] = React.useState();
  return React.useCallback(() => updateState({}), []);
};

const SideBar = (props) => {
  const history = useHistory();
  const forceUpdate = useForceUpdate();

  const [routes, setRoutes] = useState([]);
  const [allRoutes, setAllRoutes] = useState([])
  const [loading, setLoading] = useState([]);
  const [activeRoutes, setActiveRoutes] = useState({});


  function makeActiveRoutes(allRoutes, routes) {

    setLoading(true)
    console.log('make')
    console.log(allRoutes)
    let obj = {};
    for (let key in allRoutes) {
      routes.includes(allRoutes[key]) ? obj[allRoutes[key]] = "1" : obj[allRoutes[key]] = null
    }
    setActiveRoutes(obj)
    forceUpdate();
    setLoading(true)

  }

  useEffect(() => {
    setLoading(true)


    var r = localStorage.getItem("screens") && JSON.parse(localStorage.getItem('screens'));
    var aR = JSON.parse(localStorage.getItem('allRoutes'));
    setRoutes(r ? r : aR);
    setAllRoutes(aR);
    forceUpdate();
    makeActiveRoutes(aR, r ? r : aR);
    setLoading(false)

  }, [])

  useEffect(() => {
    history.listen(location => {

      for (let key in allRoutes) {
        // let rPath = allRoutes[key];
        // console.log('rPath')
        // console.log(rPath)
        try {
          $(`#${allRoutes[key]}`).removeClass("active");
        }
        catch { }

      }

      let path = location.pathname.substring(1)
      path = path.charAt(0).toUpperCase() + path.slice(1)
      $(`#${path}`).addClass("active");
      forceUpdate();
    });
  }, [])

  if (loading) return null;

  return (
    <div
      className="default_bg_color" /* className="list-group list-group-flush overflow-auto vh-100" */
    >
      <nav className="sidebar sidebar-offcanvas scrol" id="sidebar">
        <ul
          className="nav navbar-nav  sidebar sidebar-dark accordion"
          id="accordionSidebar"
        >
          <li className="nav-item bgColor ">
            <div className="nav-link">
              <i className="mdi mdi-grid-large menu-icon" />
              <Link
                style={{ textDecorationLine: "none" }}
                to="/dashboard"
                className="menu-title"
              >
                Dashboard
              </Link>
            </div>

          </li>


          {
            activeRoutes["AddVehicle"] ||
              activeRoutes["ListVehicle"] ||
              activeRoutes["SearchVehicle"] ||
              activeRoutes["SoldVehicles"] ?


              <li className="nav-item">
                <a
                  className="nav-link"
                  data-toggle="collapse"
                  href="#"
                  data-target="#nav_vehicle"
                  aria-controls="charts"
                >
                  <i className="menu-icon mdi mdi-card-text-outline" />
                  <span className="menu-title">Vehicles</span>
                  <i className="menu-arrow" />
                </a>
                <div
                  // className="collapse"
                  id="nav_vehicle"
                  style={{ marginTop: -10 }}
                >
                  <ul className="nav flex-column sub-menu">

                    {
                      activeRoutes["AddVehicle"] &&
                      <li id={"AddVehicle"}
                        className={window.location.pathname == "/AddVehicle" ? "nav-item  active" : "nav-item "}
                      >
                        <Link
                          // onClick={() => forceUpdate()}
                          to="/AddVehicle"
                          className="nav-link active"
                        >
                          Add Vehicle
                        </Link>
                      </li>
                    }
                  </ul>
                  {/* starts */}
                  <ul className="nav flex-column sub-menu" style={{ marginTop: -20 }}>

                    {
                      activeRoutes["AllVehicles"] &&
                      <li id="AllVehicles"
                        className={window.location.pathname == "/AllVehicles" ? "nav-item  active" : "nav-item "}
                      >
                        <Link to="/AllVehicles" className="nav-link">
                          All Vehicles
                        </Link>
                      </li>
                    }

                    {
                      activeRoutes["SearchVehicle"] &&
                      <li id="SearchVehicle"
                        className={window.location.pathname == "/SearchVehicle" ? "nav-item  active" : "nav-item "}
                      >
                        <Link to="/SearchVehicle" className="nav-link">
                          Search Vehicle
                        </Link>
                      </li>
                    }


                    {
                      activeRoutes["ListVehicle"] &&
                      <li id="ListVehicle"
                        className={window.location.pathname == "/ListVehicle" ? "nav-item  active" : "nav-item "}
                      >
                        <Link to="/ListVehicle" className="nav-link">
                          Available Vehicles
                        </Link>
                      </li>
                    }



                    {
                      activeRoutes["SoldVehicles"] &&
                      <li id="SoldVehicles"
                        className={window.location.pathname == "/SoldVehicles" ? "nav-item  active" : "nav-item "}
                      >
                        <Link to="/SoldVehicles" className="nav-link">
                          Sold Vehicles
                        </Link>
                      </li>
                    }
                  </ul>
                </div>
              </li>
              : null
          }
          {
            activeRoutes["AddAppointment"] ||
              activeRoutes["AppointmentCalender"] ?


              <li className="nav-item">
                <a
                  className="nav-link"
                  data-toggle="collapse"
                  href="#"
                  data-target="#appointments"
                  aria-expanded="false"
                  aria-controls="charts"
                >
                  <i className="menu-icon mdi mdi-chart-line" />
                  <span className="menu-title">Appointments</span>
                  <i className="menu-arrow" />
                </a>
                <div
                  // className="collapse"
                  id="appointments">
                  <ul className="nav flex-column sub-menu">
                    {/* {
                      activeRoutes["AddAppointment"] && */}
                    {/* <li className={"nav-item d-none"}>
                        {" "}
                        <Link to="/AddAppointment" className="nav-link">
                          Add Appointment
                        </Link>
                      </li> */}
                    {/* } */}
                    {
                      activeRoutes["AddAppointment"] &&
                      <li id="AddAppointment" className={"nav-item"}>
                        {" "}
                        <Link to="/AddAppointment" className="nav-link">
                          Add Appointment
                        </Link>
                      </li>
                    }
                    {
                      activeRoutes["AppointmentCalender"] &&
                      <li id="AppointmentCalender" className={"nav-item"}>
                        {" "}
                        <Link to="/AppointmentCalender" className="nav-link">
                          Appointment calendar
                        </Link>
                      </li>
                    }
                  </ul>
                </div>
              </li>
              : null
          }
          {
            activeRoutes["AssignTask"] ||
              activeRoutes["ViewOpenTasks"] ||
              activeRoutes["AddTask"] ||
              activeRoutes["ManageTasks"] ?

              <li className="nav-item">
                <a
                  className="nav-link"
                  data-toggle="collapse"
                  href="#"
                  data-target="#to_do"
                  aria-controls="charts"
                >
                  <i className="menu-icon mdi mdi-chart-line" />
                  <span className="menu-title">Task [ToDo]</span>
                  <i className="menu-arrow" />
                </a>

                <div
                  // className="collapse"
                  id="to_do"
                  aria-labelledby="headingTwoe"
                  data-parent="#accordionSidebar"
                >
                  <ul className="nav flex-column sub-menu collapse-inner">
                    {
                      activeRoutes["AssignTask"] &&
                      <li id="AssignTask" className={"nav-item"}>
                        <Link to="/AssignTask" className="nav-link">
                          Assign Task
                        </Link>
                      </li>
                    }

                    {
                      activeRoutes["ViewOpenTasks"] &&
                      <li id="ViewOpenTasks" className={"nav-item"}>
                        {" "}
                        <Link to="/viewOpenTasks" className="nav-link">
                          View tasks
                        </Link>
                      </li>
                    }

                    {
                      activeRoutes["AddTask"] &&
                      <li id="AddTask" className={"nav-item"}>
                        <Link to="/addTask" className="nav-link">
                          Add New Task
                        </Link>
                      </li>
                    }

                    {
                      activeRoutes["ManageTasks"] &&
                      <li id="ManageTasks" className={"nav-item"}>
                        <Link to="/ManageTasks" className="nav-link">
                          Manage tasks
                        </Link>
                      </li>
                    }
                  </ul>
                </div>
              </li>
              : null
          }
          {
            activeRoutes["AddStaff"] ||
              activeRoutes["ListStaff"] ?

              <li className="nav-item">
                <a
                  className="nav-link"
                  data-toggle="collapse"
                  data-target="#staff"
                  href="#"
                  aria-expanded="false"
                  aria-controls="tables"
                >
                  <i className="menu-icon mdi mdi-table" />
                  <span className="menu-title">Staff management</span>
                  <i className="menu-arrow" />
                </a>
                <div
                  // className="collapse" 
                  id="staff">
                  <ul className="nav flex-column sub-menu">
                    {
                      activeRoutes["AddStaff"] &&
                      <li id="AddStaff" className={"nav-item"}>
                        {" "}
                        <Link to="/addStaff" className="nav-link">
                          Add staff
                        </Link>
                      </li>
                    }
                    {
                      activeRoutes["ListStaff"] &&
                      <li id="ListStaff" className={"nav-item"}>
                        {" "}
                        <Link to="/listStaff" className="nav-link">
                          Manage staff
                        </Link>
                      </li>
                    }
                  </ul>
                </div>
              </li>
              : null
          }


          {
            activeRoutes["AddIncome"] ||
              activeRoutes["Journal"] ||
              activeRoutes["AccountStatement"] ||
              activeRoutes["TrailAccount"] ||
              activeRoutes["ManageAccounts"] ?

              <li className="nav-item">
                <a
                  className="nav-link"
                  data-toggle="collapse"
                  data-target="#accounts"
                  href="#"
                  aria-expanded="false"
                  aria-controls="icons"
                >
                  <i className="menu-icon mdi mdi-layers-outline" />
                  <span className="menu-title">Accounts</span>
                  <i className="menu-arrow" />
                </a>
                <div
                  // className="collapse"
                  id="accounts">
                  <ul className="nav flex-column sub-menu">
                    {
                      activeRoutes["AddIncome"] &&
                      <li id="AddIncome" className={"nav-item"}>
                        {" "}
                        <Link to="/addIncome" className="nav-link">
                          Add Income/Expense
                        </Link>
                      </li>
                    }
                    {
                      activeRoutes["Journal"] &&
                      <li id="Journal" className={"nav-item"}>
                        {" "}
                        <Link to="/journal" className="nav-link">
                          Journal General
                          {/*  Ledger */}
                        </Link>
                      </li>
                    }
                    {
                      activeRoutes["AccountStatement"] &&
                      <li id="AccountStatement" className={"nav-item"}>
                        {" "}
                        <Link to="/AccountStatement" className="nav-link">
                          Ledger Account
                        </Link>
                      </li>
                    }

                    {
                      activeRoutes["TrailAccount"] &&
                      <li id="TrailAccount" className={"nav-item"}>
                        {" "}
                        <Link to="/TrailAccount" className="nav-link">
                          Trail Account
                        </Link>
                      </li>
                    }
                    {
                      activeRoutes["ManageAccounts"] &&
                      <li id="ManageAccounts" className={"nav-item"}>
                        {" "}
                        <Link to="/manageAccounts" className="nav-link">
                          Manage Accounts
                        </Link>
                      </li>
                    }
                  </ul>
                </div>
              </li>
              : null
          }
          {
            activeRoutes["TargetPlanner"] ||
              activeRoutes["ProfitTarget"] ||
              activeRoutes["VehiclesSalesTarget"] ?

              <li className="nav-item">
                <a
                  className="nav-link"
                  data-toggle="collapse"
                  href="#"
                  data-target="#target_planner"
                  aria-expanded="false"
                  aria-controls="charts"
                >
                  <i className="menu-icon mdi mdi-card-text-outline" />
                  <span className="menu-title">Target Planner</span>
                  <i className="menu-arrow" />
                </a>
                <div
                  // className="collapse"
                  id="target_planner">
                  <ul className="nav flex-column sub-menu">
                    {
                      activeRoutes["TargetPlanner"] &&
                      <li className={"nav-item"}>
                        {" "}
                        <Link to="/TargetPlanner" className="nav-link">
                          Target Planner
                        </Link>
                      </li>
                    }
                    {
                      activeRoutes["ProfitTarget"] &&
                      <li className={"nav-item"}>
                        {" "}
                        <Link to="/ProfitTarget" className="nav-link">
                          Monthly Sale Target
                        </Link>
                      </li>
                    }
                    {
                      activeRoutes["VehiclesSalesTarget"] &&
                      <li className={"nav-item"}>
                        {" "}
                        <a href="/VehiclesSalesTarget" className="nav-link">
                          Yearly Sale Target
                        </a>
                      </li>
                    }
                  </ul>
                </div>
              </li>
              : null
          }



          {
            activeRoutes["UpcomingMotReport"] ||
              activeRoutes["VehicleStockReport"] ||
              activeRoutes["TargetAchievementReport"] ||
              activeRoutes["UpcomingCollections"] ?

              <li className="nav-item">
                <a
                  className="nav-link"
                  data-toggle="collapse"
                  href="#"
                  data-target="#reports"
                  aria-expanded="false"
                  aria-controls="ui_basic"
                >
                  <i className="menu-icon mdi mdi-floor-plan" />
                  <span className="menu-title">Reports</span>
                  <i className="menu-arrow" />
                </a>

                <div
                  // className="collapse"
                  id="reports"
                  aria-labelledby="headingTwoe"
                  data-parent="#accordionSidebar"
                >
                  <ul className="nav flex-column sub-menu collapse-inner">
                    {
                      activeRoutes["UpcomingMotReport"] &&
                      <li className={"nav-item"}>
                        {" "}
                        <Link to="/upcomingMotReport" className="nav-link">
                          Upcoming MOT
                        </Link>
                      </li>
                    }
                    {
                      activeRoutes["VehicleStockReport"] &&
                      <li className={"nav-item"}>
                        {" "}
                        <Link to="/vehicleStockReport" className="nav-link">
                          Vehicles Stock
                        </Link>
                      </li>
                    }
                    {
                      activeRoutes["TargetAchievementReport"] &&
                      <li className={"nav-item"}>
                        {" "}
                        <Link to="/targetAchievementReport" className="nav-link">
                          Targets achievement
                        </Link>
                      </li>
                    }
                    {
                      activeRoutes["UpcomingCollections"] &&
                      <li className={"nav-item"}>
                        {" "}
                        <Link to="/upcomingCollections" className="nav-link">
                          Upcoming collections
                        </Link>
                      </li>
                    }
                  </ul>

                </div>


              </li>
              : null
          }
          {
            activeRoutes["ManageAccess"] &&
            <li className={"nav-item"}>
              <Link
                to="/ManageAccess" className="nav-link"

                style={{ borderWidth: 0 }}
                aria-expanded="false"
                aria-controls="ui_basic"
              // onClick={() => { }}
              >
                <i className="fa fa-universal-access" style={{ fontSize: 20 }} />
                <span className="menu-title ml-3">Manage Access</span>
              </Link>

            </li>
          }

          <div
            style={{
              width: "100%",
              justifyContent: "center",
              display: "flex",
              marginTop: 30,
            }}
          >
            <a
              onClick={() => {
                localStorage.setItem("garage_data", "");
                localStorage.setItem("screens", "");
              }}
              href="/"
              className="btn btn-user btn-block text-center col-12 col-xl-6 col-sm-8"
              style={{ backgroundColor: "#32BBE8", color: "white", fontFamily: "monospace", fontSize: 18, fontWeight: "bold", alignSelf: "center", textAlign: "center", display: "flex", justifyContent: "center", flexDirection: "row", }}
            >
              <i className="menu-icon mdi mdi-logout" />
            </a>
          </div>
        </ul>
      </nav>
    </div >
  );
};

export default SideBar;
