import React, { useContext, useEffect, useState } from "react";
import $ from "jquery";

import SideBar from "../../Components/SideBar";
import TopBar from "../../Components/TopBar";
import Footer from "../../Components/Footer";
import SettingPanel from "../../Components/SettingPanel";

import TargetChart from "../../images/target_chart.jpg";
import { Context } from "../../Context/DataContext";
import { doConsole, useForceUpdate } from "../../utils/functions";
import { apiRequest } from "../../utils/apiCalls";
import { Button, Modal } from "react-bootstrap";
import Loader from "../../Components/Loader";

import Chart from "../../vendors/chart.js/Chart.min.js";
import { ErrorAlert, SuccessAlert } from "../../Components/Alerts.js";

const TargetAchievementReport = () => {

  const { state } = useContext(Context);
  const forceUpdate = useForceUpdate();
  const [loading, setLoading] = useState(false);


  const [collections, setCollections] = useState([]);
  const [monthlySales, setMonthlySales] = useState([]);
  const [saleDataSummary, setSaleDataSummary] = useState([]);

  const [maxSale, setmaxSale] = useState();
  const [minSale, setminSale] = useState();
  const [maxProfit, setmaxProfit] = useState();
  const [minProfit, setminProfit] = useState();

  const [dataPurchase, setDataPurchase] = useState([]);

  const [maxPurchase, setMaxPurchase] = useState();
  const [minPurchase, setMinPurchase] = useState();
  const [maxAmount, setMaxAmount] = useState();
  const [minAmount, setMinAmount] = useState();
  const [totalPurchases, setTotalPurchases] = useState();
  const [totalAmount, setTotalAmount] = useState();

  const [year, setYear] = useState('')
  const [month, setMonth] = useState('');


  const [avg_car_target, setAvg_car_target] = useState("");
  const [avg_profit_target, setAvg_profit_target] = useState("");
  const [avg_car_progress, setAvg_car_progress] = useState("");
  const [avg_profit_progress, setAvg_profit_progress] = useState("");

  const labels = ["Target", "Progress"];

  var carSaleByNumber = {
    labels,
    datasets: [
      {
        label: [["Target"], ["Progress"]],
        data: [avg_car_target, avg_car_progress],
        borderColor: ["#587ce4", "#ff0000"],
        borderWidth: 2,
        fill: false,
      },
    ],
  };

  var carSaleByVolume = {
    labels,
    datasets: [
      {
        label: [["Target"], ["Progress"]],
        data: [avg_profit_target, avg_profit_progress],
        borderColor: ["#587ce4", "#ff0000"],
        borderWidth: 2,
        fill: false,
      },
    ],
  };



  var options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    legend: {
      display: true,
    },
    elements: {
      point: {
        radius: 2,
      },
    },
  };




  useEffect(() => {

    $("#carSaleByNumber");
    {
      var multiLineCanvas = $("#carSaleByNumber").get(0).getContext("2d");
      var lineChart = new Chart(multiLineCanvas, {
        type: "bar",
        data: carSaleByNumber,
        options: options,
      });
    }

    $("#carSaleByVolume");
    {
      var multiLineCanvas = $("#carSaleByVolume").get(0).getContext("2d");
      var lineChart = new Chart(multiLineCanvas, {
        type: "bar",
        data: carSaleByVolume,
        options: options,
      });
    }


    return () => { };
  }, [avg_car_target, avg_profit_target, avg_car_progress, avg_profit_progress,]);





  function get_monthly_sales(year, month) {
    setLoading(true);
    let currentDate = new Date();
    const reqObj = {
      token: state.garageData.token,
      g_id: state.garageData.g_id,
      year: year ? year : currentDate.getFullYear(),
      month: month ? month : currentDate.getMonth() + 1
    };

    apiRequest(reqObj, "get_monthly_sales")
      .then((data) => {
        doConsole(data);
        setLoading(false);
        if (data.status == "1") {

          let maxSale = 0;
          let minSale = 1000;
          let minProfit = 1000000;
          let maxProfit = 0;

          let maxPurchase = 0;
          let minPurchase = 1000;
          let maxAmount = 0;
          let minAmount = 100000;
          let totalPurchases = 0;
          let totalAmount = 0;
          for (let key in data.data) {
            maxSale = Math.max(maxSale, data.data[key].no_of_sales)
            minSale = Math.min(minSale, data.data[key].no_of_sales)
            maxProfit = Math.max(maxProfit, data.data[key].total_profit)
            minProfit = Math.min(minProfit, data.data[key].total_profit)
          }

          setmaxSale(maxSale);
          setminSale(minSale);
          setmaxProfit(maxProfit);
          setminProfit(minProfit);

          setMonthlySales(data.data)
          setSaleDataSummary(data.data_summary[0])

          for (let key in data.data_purchase) {
            maxPurchase = Math.max(maxPurchase, data.data_purchase[key].no_of_purchases)
            minPurchase = Math.min(minPurchase, data.data_purchase[key].no_of_purchases)
            maxAmount = Math.max(maxAmount, data.data_purchase[key].total_price)
            minAmount = Math.min(minAmount, data.data_purchase[key].total_price)

            totalPurchases = totalPurchases + data.data_purchase[key].no_of_purchases;
            totalAmount = totalAmount + data.data_purchase[key].total_price;
          }

          setMaxPurchase(maxPurchase);
          setMinPurchase(minPurchase);
          setMaxAmount(maxAmount);
          setMinAmount(minAmount);
          setTotalPurchases(totalPurchases);
          setTotalAmount(totalAmount);

          setDataPurchase(data.data_purchase)

          setAvg_car_target(data?.chart_data?.avg_car_target)
          setAvg_profit_target(data?.chart_data?.avg_profit_target)
          setAvg_car_progress(data?.chart_data?.avg_car_progress)
          setAvg_profit_progress(data?.chart_data?.avg_profit_progress)


          forceUpdate();
        } else {
          // setErrorMessage(data.error_msg);
          forceUpdate();
          window.scrollTo(0, 0);
        }
      })
      .catch((err) => {
        doConsole(err);
        setLoading(false);
      });
  }


  useEffect(() => {
    get_monthly_sales();
  }, []);




  useEffect(() => {
    // $(document).ready(function () {
    //     $('#dataTable').DataTable();
    // });
    $("#reports").addClass("show");
  }, [loading]);

  return (

    <div className="main-panel">
      <div className="content-wrapper">
        {loading && <Loader />}
        <div className="row" >
          <div style={{ marginTop: 10, }} className="col-md-12 col-lg-4 float-left" >
            <div style={{ fontFamily: "sans-serif", fontSize: 14, fontWeight: "normal", color: 'grey', }} >Year</div>
            <select
              className="form-control"

              style={{ marginTop: 3, borderRadius: 5 }}
              onChange={(t) => {
                setYear(t.target.value)
              }}

            >
              <option disabled >Year</option>
              <option selected disabled value={"all"}  >Current Year</option>
              <option value={"2021"} >2021</option>
              <option value={"2022"} >2022</option>
              <option value={"2023"} >2023</option>
              <option value={"2024"} >2024</option>
              <option value={"2025"} >2025</option>
              <option value={"2026"} >2026</option>
              <option value={"2027"} >2027</option>
              <option value={"2028"} >2028</option>
              <option value={"2029"} >2029</option>
              <option value={"2030"} >2030</option>
              <option value={"2031"} >2031</option>
              <option value={"2032"} >2032</option>
              <option value={"2033"} >2033</option>
              <option value={"2034"} >2034</option>
              <option value={"2035"} >2035</option>
              <option value={"2036"} >2036</option>
              <option value={"2037"} >2037</option>
              <option value={"2038"} >2038</option>
              <option value={"2039"} >2039</option>
              <option value={"2040"} >2040</option>
              <option value={"2040"} >2040</option>
              <option value={"2041"} >2041</option>
              <option value={"2042"} >2042</option>
              <option value={"2043"} >2043</option>
              <option value={"2044"} >2044</option>
              <option value={"2045"} >2045</option>
              <option value={"2046"} >2046</option>
              <option value={"2047"} >2047</option>
              <option value={"2048"} >2048</option>
              <option value={"2049"} >2049</option>
              <option value={"2050"} >2050</option>
            </select>
          </div>


          <div style={{ marginTop: 10, }} className="col-md-12 col-lg-4 float-right" >
            <div style={{ fontFamily: "sans-serif", fontSize: 14, fontWeight: "normal", color: 'grey', }} >Month</div>
            <select
              className="form-control"
              style={{ marginTop: 3, borderRadius: 5 }}
              onChange={(t) => {
                setMonth(t.target.value)
              }}

            >
              <option disabled >Month</option>
              <option selected disabled value={"Current"}  >Current Month</option>
              <option value={"01"} >January</option>
              <option value={"02 "} >February </option>
              <option value={"03 "} >March </option>
              <option value={"04 "} >April </option>
              <option value={"05 "} >May </option>
              <option value={"06 "} >June </option>
              <option value={"07 "} >July </option>
              <option value={"08 "} >August </option>
              <option value={"09 "} >September </option>
              <option value={"10 "} >October </option>
              <option value={"11 "} >November </option>
              <option value={"12"} >December</option>
            </select>
          </div>

          <Button

            onClick={() => {
              get_monthly_sales(year, month)
              // setDelId(v.v_id);
              // setDelModal(true);
            }}
            style={{ height: 35, marginTop: 25, alignItems: 'center', padding: 0 }}
            title="Delete Vehicle"
            className="btn btn-primary del_customer text-middle col-lg-3"
          >Submit</Button>

        </div>




        <div className="row " style={{ marginTop: 30 }} >

          <div className=" col col-lg-6 col-xl-6  col-md-12 col-sm-12 " style={{ borderWidth: 1, borderColor: 'grey' }} >
            <h3 className="rate-percentage text-center "><strong> Car Sales &amp; Profit</strong></h3>

            <div className="row mt-2 ">
              <div className="col col-5 " >
                <div className="row " style={{ backgroundColor: 'yellow' }} >
                  <div className="col col-6 text-left " ><strong> Cars</strong></div>
                  <div className="col col-6 text-right "><strong> {saleDataSummary.no_of_sales}</strong></div>
                </div>
                <div className="row " >
                  <div className="col col-7 text-left " style={{ marginTop: 3, color: 'grey', fontSize: 13 }} > <strong> Average Sold</strong></div>
                  <div className="col col-5 text-right " style={{ marginTop: 3, color: 'grey', fontSize: 13 }} ><strong> {saleDataSummary.avg_car_sold}</strong></div>
                </div>
                <div className="row " >
                  <div className="col col-7 text-left " style={{ marginTop: 3, color: 'grey', fontSize: 13 }} > <strong> Max Sold a day</strong></div>
                  <div className="col col-5 text-right " style={{ marginTop: 3, color: 'grey', fontSize: 13 }} ><strong> {maxSale}</strong></div>
                </div>
                <div className="row " >
                  <div className="col col-7 text-left " style={{ marginTop: 3, color: 'grey', fontSize: 13 }} > <strong> Min Sold a day</strong></div>
                  <div className="col col-5 text-right " style={{ marginTop: 3, color: 'grey', fontSize: 13 }} ><strong> {minSale}</strong></div>
                </div>
                <div className="row " >
                  <div className="col col-6 text-left " style={{ marginTop: 3, fontSize: 12 }} > <strong> Average Profit</strong></div>
                  <div className="col col-6 text-right " style={{ marginTop: 3, fontSize: 12 }} ><strong>£{Math.round(saleDataSummary.profit / saleDataSummary.no_of_sales)}</strong></div>
                </div>

              </div>



              <div className="col col-2"></div>

              <div className="col col-5 " >
                <div className="row " style={{ backgroundColor: 'yellow' }} >
                  <div className="col col-6 text-left " > <strong> Profit</strong></div>
                  <div className="col col-6 text-right " ><strong> £{saleDataSummary.profit}</strong></div>
                </div>
                <div className="row " >
                  <div className="col col-7 text-left " style={{ marginTop: 3, color: 'grey', fontSize: 13 }} > <strong>Avg Profit a day</strong></div>
                  <div className="col col-5 text-right " style={{ marginTop: 3, color: 'grey', fontSize: 13 }} ><strong> </strong>£{saleDataSummary.avg_profit}</div>
                </div>
                <div className="row " >
                  <div className="col col-7 text-left " style={{ marginTop: 3, color: 'grey', fontSize: 13 }} > <strong> Max Profit a day</strong></div>
                  <div className="col col-5 text-right " style={{ marginTop: 3, color: 'grey', fontSize: 13 }} ><strong> £{maxProfit}</strong></div>
                </div>
                <div className="row " >
                  <div className="col col-7 text-left " style={{ marginTop: 3, color: 'grey', fontSize: 13 }} > <strong> Min Profit a day</strong></div>
                  <div className="col col-5 text-right " style={{ marginTop: 3, color: 'grey', fontSize: 13 }} ><strong> £{minProfit}</strong></div>
                </div>
                <div className="row " >
                  <div className="col col-6 text-left " style={{ marginTop: 3, fontSize: 12 }} > <strong> Total Revenue</strong></div>
                  <div className="col col-6 text-right " style={{ marginTop: 3, fontSize: 12 }} ><strong> £{saleDataSummary.revenue}</strong></div>
                </div>

              </div>

              <div className="col col-6 " ></div>
            </div>

            {/* Chart start */}
            <div className="col-6 col-md-12 col-lg-12 col-sm-12 mt-3 mb-3 ">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">
                    No of cars sale
                  </h4>
                  <canvas id="carSaleByNumber"></canvas>
                </div>
              </div>
            </div>
            {/* Chart start */}

            <div className="table-responsive mt-1 ">
              {/* table className="table table-bordered dt-responsive" id="dataTable" width="100%" cellspacing="0" */}
              <table
                id="dataTable"
                className="table table-striped table-bordered dt-responsive"
                style={{ width: "100%" }}
              >
                <thead>
                  <tr>
                    <th className="tRow">Date</th>
                    <th className="tRow">No of sales</th>
                    <th className="tRow">Avg Profit</th>
                    <th className="tRow">Total Profit</th>
                    <th className="tRow">Revenue</th>
                  </tr>
                </thead>
                <tbody>
                  {monthlySales.map((v, i) => {
                    return (
                      <tr key={i}>
                        <td><nobr>{v.v_sale_date}</nobr></td>
                        <td><nobr>{v.no_of_sales}</nobr></td>
                        <td><nobr>{v.avg_profit}</nobr></td>
                        <td><nobr>{v.total_profit}</nobr></td>
                        <td><nobr>{v.revenue}</nobr></td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>




          {/*  Car Purchases */}

          <div className=" col col-lg-6 col-xl-6  col-md-12 col-sm-12 "  >
            <h3 className="rate-percentage text-center"><strong> Car Purchases</strong></h3>

            <div className="row mt-2 ">
              <div className="col col-5 " >
                <div className="row " style={{ backgroundColor: 'yellow' }} >
                  <div className="col col-6 text-left " ><strong> Cars</strong></div>
                  {/* <div className="col col-6 text-right "><strong> {saleDataSummary.no_of_sales}</strong></div> */}
                </div>
                <div className="row " >
                  <div className="col col-7 text-left " style={{ marginTop: 3, color: 'grey', fontSize: 13 }} > <strong> Purchase</strong></div>
                  <div className="col col-5 text-right " style={{ marginTop: 3, color: 'grey', fontSize: 13 }} ><strong> {totalPurchases}</strong></div>
                </div>
                <div className="row " >
                  <div className="col col-7 text-left " style={{ marginTop: 3, color: 'grey', fontSize: 13 }} > <strong> Max Purc. A day</strong></div>
                  <div className="col col-5 text-right " style={{ marginTop: 3, color: 'grey', fontSize: 13 }} ><strong> {maxPurchase}</strong></div>
                </div>
                <div className="row " >
                  <div className="col col-7 text-left " style={{ marginTop: 3, color: 'grey', fontSize: 13 }} > <strong> Min Purch. A day</strong></div>
                  <div className="col col-5 text-right " style={{ marginTop: 3, color: 'grey', fontSize: 13 }} ><strong> {minPurchase}</strong></div>
                </div>

              </div>



              <div className="col col-1"></div>

              <div className="col col-6 " >
                <div className="row " style={{ backgroundColor: 'yellow' }} >
                  <div className="col col-6 text-left " > <strong> Purchased</strong></div>
                  {/* <div className="col col-6 text-right " ><strong> £{saleDataSummary.profit}</strong></div> */}
                </div>
                <div className="row " >
                  <div className="col col-7 text-left " style={{ marginTop: 3, color: 'grey', fontSize: 13 }} > <strong> Max Amount a Day</strong></div>
                  <div className="col col-5 text-right " style={{ marginTop: 3, color: 'grey', fontSize: 13 }} ><strong> </strong>£{maxAmount}</div>
                </div>
                <div className="row " >
                  <div className="col col-7 text-left " style={{ marginTop: 3, color: 'grey', fontSize: 13 }} > <strong> Min Amount a Day</strong></div>
                  <div className="col col-5 text-right " style={{ marginTop: 3, color: 'grey', fontSize: 13 }} ><strong> £{minAmount}</strong></div>
                </div>
                <div className="row " >
                  <div className="col col-7 text-left " style={{ marginTop: 3, color: 'grey', fontSize: 13 }} > <strong> Total Amount</strong></div>
                  <div className="col col-5 text-right " style={{ marginTop: 3, color: 'grey', fontSize: 13 }} ><strong> £{totalAmount}</strong></div>
                </div>
                <br />

              </div>

              <div className="col col-6 " ></div>
            </div>

            {/* Chart start */}
            <div className="col-12 col-md-12 col-lg-12 col-sm-12 m-3 ">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">
                    Average Profit per day
                  </h4>
                  <canvas id="carSaleByVolume"></canvas>
                </div>
              </div>
            </div>
            {/* Char end */}

            <div className="table-responsive mt-1 ">
              {/* table className="table table-bordered dt-responsive" id="dataTable" width="100%" cellspacing="0" */}
              <table
                id="dataTable"
                className="table table-striped table-bordered dt-responsive"
                style={{ width: "100%" }}
              >
                <thead>
                  <tr>
                    <th className="tRow">Date</th>
                    <th className="tRow">No of purchase</th>
                    <th className="tRow">Avg Amount</th>
                    <th className="tRow">Total Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {dataPurchase.map((v, i) => {
                    return (
                      <tr key={i}>
                        <td><nobr>{v.v_purchase_date}</nobr></td>
                        <td><nobr>{v.no_of_purchases}</nobr></td>
                        <td><nobr>{v.avg_price}</nobr></td>
                        <td><nobr>{v.total_price}</nobr></td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>




        </div>




      </div>
      {/* content-wrapper ends */}
      {/* partial:partials/_footer.html */}
      <Footer />
      {/* partial */}
    </div>
  );
};

export default TargetAchievementReport;
