import {
  Router,
  Switch,
  Route,
  BrowserRouter,
  Redirect,
} from "react-router-dom";
import React from "react";
import SideBar from "./Components/SideBar";
import TopBar from "./Components/TopBar";

import SettingPanel from "./Components/SettingPanel";

// import {Routes, Route, BrowserRouter} from "react-router-dom";
// import {Switch} from "react-dom";
import { useEffect, useState, useContext } from "react";

import Dashboard from "./pages/Dashboard";
import Home from "./pages/Home";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";

import AddVehicle from "./Screens/Vehicles/AddVehicle";
import AllVehicles from "./Screens/Vehicles/AllVehicles";
import ListVehicle from "./Screens/Vehicles/ListVehicle";
import EditVehicle from "./Screens/Vehicles/EditVehicle";
import DeleteVehicle from "./Screens/Vehicles/DeleteVehicle";
import AddCustomer from "./Screens/Vehicles/AddCustomer";
import SearchVehicle from "./Screens/Vehicles/SearchVehicle";
import MaintenanceTasks from "./Screens/Vehicles/MaintenanceTasks";
import AddAppointment from "./Screens/Appointments/AddAppointment.js";
import AppointmentCalender from "./Screens/Appointments/AppointmentCalender";
import AddTask from "./Screens/Tasks/AddTask";
import EditTask from "./Screens/Tasks/EditTask";
import AssignTask from "./Screens/Tasks/AssignTask";
import ViewOpenTasks from "./Screens/Tasks/ViewOpenTasks";
import ViewClosedTasks from "./Screens/Tasks/ViewClosedTasks";
import ManageTasks from "./Screens/Tasks/ManageTasks";
import AddStaff from "./Screens/StaffManagement/AddStaff";
import ListStaff from "./Screens/StaffManagement/ListStaff";
import AddIncome from "./Screens/Accounts/AddIncome";
import ManageAccounts from "./Screens/Accounts/ManageAccounts";
import Journal from "./Screens/Accounts/Journal";
import AccountStatement from "./Screens/Accounts/AccountStatement";
import TrailAccount from "./Screens/Accounts/TrailAccount";
import TargetPlanner from "./Screens/Targets/TargetPlanner";
import VehiclesSalesTarget from "./Screens/Targets/VehiclesSalesTarget";
import ProfitTarget from "./Screens/Targets/ProfitTarget";
import UpcomingMotReport from "./Screens/Reports/UpcomingMotReport";
import VehicleStockReport from "./Screens/Reports/VehicleStockReport";
import TargetAchievementReport from "./Screens/Reports/TargetAchievementReport";
import { useHistory, useLocation } from "react-router";
import { Context, Provider } from "./Context/DataContext";
import Notfound from "./Screens/Notfound";
import Login from "./Screens/Login";
import EditStaff from "./Screens/StaffManagement/EditStaff";
import EditAppointment from "./Screens/Appointments/EditAppointment";
import { doConsole } from "./utils/functions";
import ViewSingleVehicle from "./Screens/Vehicles/ViewSingleVehicle";
import SoldVehicles from "./Screens/Vehicles/SoldVehicles";
import UpcomingCollections from "./Screens/Reports/UpcomingCollections";
import ManageAccess from "./Screens/ManageAccess/ManageAccess";
import { ReactNotifications } from "react-notifications-component";
import { apiRequest } from "./utils/apiCalls";
import Loader from "./Components/Loader";
import Task from "./Task";

const useForceUpdate = () => {
  const [, updateState] = React.useState();
  return React.useCallback(() => updateState({}), []);
};

function getAuthStatus() {
  const status = localStorage.getItem("garage_data") ? true : false;
  return status;
}

const SecuredRoute = (props) => {
  const { setGarageData } = useContext(Context);
  const forceUpdate = useForceUpdate();
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    // localStorage.removeItem('store_data')
    setGarageData();
    forceUpdate();
    setLoading(true);
  }, []);

  if (!loading) return null;
  // (
  //   <Redirect to={{ pathname: "/" }}></Redirect>
  return (
    <Route
      path={props.path}
      render={(data) =>
        getAuthStatus() ? (
          <props.component {...data}></props.component>
        ) : window.location.pathname = '/'
      }
    ></Route>
  );
};


const components = {
  ListVehicle,
  Dashboard,
  Login,
  Login,
  Dashboard,
  Home,
  AddVehicle,
  AllVehicles,
  ListVehicle,
  EditVehicle,
  DeleteVehicle,
  AddCustomer,
  SearchVehicle,
  MaintenanceTasks,
  ViewSingleVehicle,
  SoldVehicles,
  AddAppointment,
  AppointmentCalender,
  EditAppointment,
  AssignTask,
  AddTask,
  EditTask,
  ViewOpenTasks,
  ViewClosedTasks,
  ManageTasks,
  AddStaff,
  ListStaff,
  EditStaff,
  ManageAccounts,
  AddIncome,
  Journal,
  AccountStatement,
  TrailAccount,
  TargetPlanner,
  ProfitTarget,
  VehiclesSalesTarget,
  UpcomingMotReport,
  VehicleStockReport,
  TargetAchievementReport,
  UpcomingCollections,
  ManageAccess,
}


function App() {

  const [loading, setLoading] = useState(false);
  const [pathName, setPathName] = useState(window.location.pathname)

  var routes = [
    "Dashboard",  //route: 
    "Login",  //route: 
    "Home",  //route: 
    "AddVehicle",  //route: 
    "AllVehicles", //route: 
    "ListVehicle",  //route: 
    "EditVehicle",  //route: 
    "DeleteVehicle",  //route: 
    "AddCustomer",  //route: 
    "SearchVehicle",  //route: 
    "MaintenanceTasks",  //route: 
    "ViewSingleVehicle",  //route: 
    "SoldVehicles",  //route: 
    "AddAppointment",  //route: 
    "AppointmentCalender",  //route: 
    "EditAppointment",  //route: 
    "AssignTask",  //route: 
    "AddTask",  //route: 
    "EditTask",  //route: 
    "ViewOpenTasks",  //route: 
    "ViewClosedTasks",  //route: 
    "ManageTasks",  //route: 
    "AddStaff",  //route: 
    "ListStaff",  //route: 
    "EditStaff",  //route: 
    "ManageAccounts",  //route: 
    "AddIncome",  //route: 
    "Journal",  //route: 
    "AccountStatement",  //route: 
    "TrailAccount",  //route: 
    "TargetPlanner",  //route: 
    "ProfitTarget", //Monthly Sale Targt  //route: 
    "VehiclesSalesTarget",  //route: 
    "UpcomingMotReport",  //route: 
    "VehicleStockReport",  //route: 
    "TargetAchievementReport",  //route: 
    "UpcomingCollections",  //route: 
    "ManageAccess"  //route: 
  ];


  function makeRoutes() {

    var staffRoutes;
    var routesX = routes;
    if (localStorage.getItem('screens')) {
      staffRoutes = JSON.parse(localStorage?.getItem('screens'));
      routesX = staffRoutes;
    }
    // var checkAdmin = localStorage.getItem("garage_data") && JSON.parse(localStorage.getItem("garage_data"));    
    // localStorage.setItem('', JSON.stringify(routes));

    var accessibleRoutes = [];
    for (let key in routesX) {
      accessibleRoutes.push(
        <SecuredRoute path={"/" + routesX[key]} component={components[routesX[key]]} />
      )
    }
    return accessibleRoutes;
  }

  const forceUpdate = useForceUpdate()

  function checkNotFound() {
    return true
    // let path = window.location.pathname.substring(1)
    // path = path.charAt(0).toUpperCase() + path.slice(1)
    // var staffRoutes = localStorage.getItem("screens") && JSON.parse(localStorage.getItem("screens"));
    // let x = staffRoutes ? staffRoutes : routes;
    // return x.includes(path)
  }

  function get_access_screens() {

    setLoading(true);
    const checkAdmin = localStorage.getItem("garage_data") && JSON.parse(localStorage.getItem("garage_data"));
    // console.log(checkAdmin);
    if (!checkAdmin) {
      setLoading(false);
      return
    }
    setPathName('/dashboard')
    if (checkAdmin.g_email) {
      setLoading(false)
      return;
    }

    const reqObj = {
      dep_id: checkAdmin?.dep_id
    }
    // console.log('req')
    // console.log(reqObj)
    apiRequest(reqObj, "get_access_screens")
      .then((data) => {
        setLoading(false);
        if (data.status == "1") {
          localStorage.setItem("screens", JSON.stringify(data?.data));
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });



  }

  useEffect(() => {
    // setLoading(true)
    // localStorage.setItem('allRoutes',routes)
    // if(localStorage.getItem('screens')){
    // console.log('yes i have')
    // console.log(JSON.parse(localStorage.getItem('screens')))
    // alert('yes')
    // }
    // else alert('no')
    localStorage.setItem('allRoutes', JSON.stringify(routes));
    get_access_screens()
    // localStorage.setItem('garage_data', '')
    // localStorage.setItem('screens', '')


    forceUpdate();


  }, [window.location.pathname])



  if (loading) return <Loader />

  return (
    <Provider>
      <ReactNotifications />
      <div className="container-scroller">
        {pathName != "/" &&
          pathName != "/login" && checkNotFound() && <TopBar />}
        <div
          className={
            pathName != "/" &&
            pathName != "/login" && checkNotFound() &&
            "container-fluid page-body-wrapper"
          }
        >
          <SettingPanel />

          <BrowserRouter>
            {pathName != "/" &&
              pathName != "/login" && checkNotFound() && <SideBar />}
            <Switch>
              <Route exact path="/" component={Login} />
              <Route exact path="/login" component={Login} />

              {makeRoutes()}
              <Route component={Notfound} />



            </Switch>
          </BrowserRouter>
        </div>
      </div>
    </Provider>
  );
}

export default App;

// {/* <Route exact path="/" component={Dashboard} />
//               <Route exact path="/" component={Login} />
//               <Route exact path="/login" component={Login} />

//               <SecuredRoute path="/dashboard" component={Dashboard} />
//               <SecuredRoute path="/home" component={Home} />
//               {/* Vehicles */}
//               <SecuredRoute path="/addVehicle" component={AddVehicle} />
//               <SecuredRoute path="/ListVehicle" component={ListVehicle} />
//               <SecuredRoute path="/editVehicle" component={EditVehicle} />
//               <SecuredRoute path="/deleteVehicle" component={DeleteVehicle} />
//               <SecuredRoute path="/addCustomer" component={AddCustomer} />
//               <SecuredRoute path="/searchVehicle" component={SearchVehicle} />
//               <SecuredRoute path="/maintenanceTasks" component={MaintenanceTasks} />
//               <SecuredRoute path="/viewSingleVehicle" component={ViewSingleVehicle} />
//               <SecuredRoute path="/soldVehicles" component={SoldVehicles} />
//               {/* Appointments */}
//               <SecuredRoute path="/AddAppointment" component={AddAppointment} />
//               <SecuredRoute path="/appointmentCalender" component={AppointmentCalender} />
//               <SecuredRoute path="/editAppointment" component={EditAppointment} />

//               {/* Tasks [Todo] */}
//               <SecuredRoute path="/AssignTask" component={AssignTask} />
//               <SecuredRoute path="/addTask" component={AddTask} />
//               <SecuredRoute path="/EditTask" component={EditTask} />
//               <SecuredRoute path="/viewOpenTasks" component={ViewOpenTasks} />
//               <SecuredRoute path="/viewClosedTasks" component={ViewClosedTasks} />
//               <SecuredRoute path="/ManageTasks" component={ManageTasks} />
//               {/* Staff Management */}
//               <SecuredRoute path="/addStaff" component={AddStaff} />
//               <SecuredRoute path="/listStaff" component={ListStaff} />
//               <SecuredRoute path="/editStaff" component={EditStaff} />
//               {/* Accounts */}
//               <SecuredRoute path="/ManageAccounts" component={ManageAccounts} />
//               <SecuredRoute path="/addIncome" component={AddIncome} />
//               <SecuredRoute path="/Journal" component={Journal} />
//               <SecuredRoute path="/AccountStatement" component={AccountStatement} />
//               <SecuredRoute path="/TrailAccount" component={TrailAccount} />
//               {/* Targets */}
//               <SecuredRoute path="/TargetPlanner" component={TargetPlanner} />
//               <SecuredRoute path="/ProfitTarget" component={ProfitTarget} />
//               <SecuredRoute path="/VehiclesSalesTarget" component={VehiclesSalesTarget} />
//               {/* Reports */}
//               <SecuredRoute path="/upcomingMotReport" component={UpcomingMotReport} />
//               <SecuredRoute path="/vehicleStockReport" component={VehicleStockReport} />
//               <SecuredRoute path="/targetAchievementReport" component={TargetAchievementReport} />
//               <SecuredRoute path="/upcomingCollections" component={UpcomingCollections} /> */}