import { urls } from './apiUrls';


export async function apiRequest(body_data, url_plus,) {

  var url = urls.API;
  console.log(" I request @ " + url + url_plus);
  console.log('body data is ')
  console.log(body_data);
  const configs = {
    method: "POST",
    // mode: 'no-cors',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      // "Access-Control-Allow-Origin": "*"
    },
    body: JSON.stringify(body_data),
  }
  console.log(configs)
  console.log(url + url_plus)
  return (
    fetch(url + url_plus, configs)
      .then((response) => response.json())
      // .then((response) => response.text())
      .then((responseJson) => {
        return responseJson
      }).catch((error) => {
        console.log('error is')
        console.log(error)
        return "Internet error"
      })
  )


  // return {isError,data};
}
// "proxy": "http://localhost:4000",