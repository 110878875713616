import React from 'react'

const DeleteVehicle = () => {
    return (
        <div>
            <h1>DeleteVehicle</h1>
        </div>
    )
}

export default DeleteVehicle
