import React, { useEffect, useState, useContext } from "react";
import $ from "jquery";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";

import { Button, Modal } from "react-bootstrap";

import SideBar from "../../Components/SideBar";
import TopBar from "../../Components/TopBar";
import Footer from "../../Components/Footer";
import SettingPanel from "../../Components/SettingPanel";

import { Link } from "react-router-dom";
import { Context } from "../../Context/DataContext";
import { apiRequest } from "../../utils/apiCalls.js";
import { doConsole, useForceUpdate } from "../../utils/functions.js";
import Loader from "../../Components/Loader.js";
import { ErrorAlert, SuccessAlert } from "../../Components/Alerts.js";

const UpcomingCollections = () => {
    const forceUpdate = useForceUpdate();
    const [errorMessage, setErrorMessage] = useState("");
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [vData, setVData] = useState([]);
    const [delId, setDelId] = useState("");
    const [delModal, setDelModal] = useState("");

    const [collections, setCollections] = useState([]);

    const { state } = useContext(Context);



    function get_upcoming_collections() {
        setLoading(true);
        const reqObj = {
            token: state.garageData.token,
            g_id: state.garageData.g_id,
        };
        apiRequest(reqObj, "get_upcoming_collections")
            .then((data) => {
                doConsole(data);
                setLoading(false);
                if (data.status == "1") {
                    setCollections(data.data)
                    forceUpdate();
                } else {
                    setErrorMessage(data.error_msg);
                    forceUpdate();
                    window.scrollTo(0, 0);
                }
            })
            .catch((err) => {
                doConsole(err);
                setLoading(false);
            });
    }


    function doDel() {
        const reqObj = {
            token: state.garageData.token,
            g_id: state.garageData.g_id,
            v_id: delId,
        };
        setLoading(true);
        apiRequest(reqObj, "delete_vehicle")
            .then((data) => {
                doConsole(data);
                setLoading(false);
                if (data.status == "1") {
                    if (data.data) {
                        setVData(data.data);
                    }
                } else {
                    setErrorMessage(data.error_msg);
                    window.scrollTo(0, 0);
                }
            })
            .catch((err) => {
                doConsole(err);
                setLoading(false);
            });
    }

    useEffect(() => {
        // $(document).ready(function () {
        //     $('#dataTable').DataTable();
        // });
        $(document).ready(function () {
            $("#dataTable").DataTable({
                searching: true,
                paging: true,
                info: false,
                language: {
                    emptyTable: "No collection found.",
                    zeroRecords: "No collection matches your search.",
                },
                order: [[0, "desc"]],

            });
        });

        $("#nav_vehicle").addClass("show");
        $("#list_vehicle").addClass("active");
    }, [loading]);

    useEffect(() => {
        get_upcoming_collections();
    }, []);

    return (
        <div className="main-panel">
            <div className="content-wrapper">
                {loading && <Loader />}
                {errorMessage && (
                    <ErrorAlert
                        onClose={() => setErrorMessage("")}
                        errorMessage={errorMessage}
                    />
                )}
                {success && (
                    <SuccessAlert message={"Hurry! Your vehicle has been saved!"} />
                )}

                <Modal show={delModal} onHide={() => setDelModal(false)}>
                    <Modal.Header>
                        <Modal.Title
                            style={{
                                fontSize: 18,
                                textAlign: "center",
                                alignSelf: "center",
                                fontFamily: "sans-serif",
                                fontWeight: "bold",
                            }}
                        >
                            Are you sure you want to delete this Vehicle?
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Footer>
                        <Button
                            style={{ fontFamily: "sans-serif", fontWeight: "bold" }}
                            variant="secondary"
                            onClick={() => {
                                setDelModal(false);
                            }}
                        >
                            No
                        </Button>
                        <Button
                            style={{ fontFamily: "sans-serif", fontWeight: "bold" }}
                            variant="danger"
                            onClick={() => {
                                setDelModal(false);
                                doDel();
                            }}
                        >
                            Yes
                        </Button>
                    </Modal.Footer>
                </Modal>

                <div className="row">
                    <div className="col-sm-12">
                        <div className="home-tab">
                            <div className="tab-content tab-content-basic">
                                <div
                                    className="tab-pane fade show active"
                                    id="overview"
                                    role="tabpanel"
                                    aria-labelledby="overview"
                                >
                                    <div className="row">
                                        <div className="col-8 float-left">
                                            <h3 className="text-black fw-bold">Upcoming Collections</h3>
                                            <br />
                                        </div>
                                        <div className="col-4 text-right float-left">
                                            <Link
                                                style={{ textDecorationLine: "none" }}
                                                to="/addVehicle"
                                                className="d-none d-sm-inline-block btn btn-sm btn-outline-primary shadow-sm"
                                            >
                                                <i className="fas fa-folder-plus fa-sm text-white-20" />{" "}
                                                Add Vehicle
                                            </Link>
                                        </div>
                                    </div>
                                    <div>
                                        {/* Page Heading */}
                                        <div>
                                            {/* DataTales Example */}
                                            <div
                                                className="card shadow mb-4"
                                                style={{ width: "100%" }}
                                            >
                                                <div className="card-body">
                                                    {/* && vData.length  */}
                                                    {!loading && (
                                                        <div className="table-responsive">
                                                            {/* table className="table table-bordered dt-responsive" id="dataTable" width="100%" cellspacing="0" */}

                                                            <table
                                                                id="dataTable"
                                                                style={{tableLayout:'fixed'}}
                                                                className="table table-striped table-bordered dt-responsive dataTable dtr-inline collapsed"
                                                                style={{ width: "100%" }}
                                                            >
                                                                <thead>
                                                                    <tr >
                                                                        <th className="tRow text-right">Collection date time</th>
                                                                        {/* <th className="tRow text-center">Collection_time</th> */}
                                                                        <th className="tRow text-center">Collection Note</th>
                                                                        <th className="tRow text-center">Reg No</th>
                                                                        <th className="tRow text-center">Make/Model</th>
                                                                        <th className="tRow text-center">Cust Name</th>
                                                                        <th className="tRow text-center">Cust Email</th>

                                                                        {/* <th className="tRow text-center">Sale Price</th>
                                    <th className="tRow text-center">Profit Margin</th>
                                    <th className="tRow text-center">Status</th>
                                    <th className="tRow text-center">Action</th> */}
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {collections?.map((v) => {
                                                                        return (
                                                                            <tr>
                                                                                <td className="text-center">{v.collection_date},    {v.collection_time} </td>
                                                                                {/* <td className="text-center">{v.collection_time}</td> */}
                                                                                <td style={{whiteSpace:'normal'}} className="text-center">{v.collection_note}</td>
                                                                                <td className="text-center">
                                                                                    <Link
                                                                                        style={{ extDecorationLine: "none", }}
                                                                                        to={{ pathname: "/searchVehicle", state: { data: v }, }}
                                                                                        title="View Vehicle"
                                                                                        className="text-center"
                                                                                    >
                                                                                        <nobr>{v.v_make_model}</nobr>
                                                                                    </Link>
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    <Link
                                                                                        style={{ extDecorationLine: "none", }}
                                                                                        to={{ pathname: "/searchVehicle", state: { data: v }, }}
                                                                                        title="View Vehicle"
                                                                                        className="text-center"
                                                                                    >
                                                                                        <nobr>{v.v_reg_no}</nobr>
                                                                                    </Link>
                                                                                </td>
                                                                                <td className="text-center"><nobr>{v.cust_name}</nobr></td>
                                                                                <td className="text-center"><nobr>{v.cust_email}</nobr></td>
                                                                                {/* <td>
                                                                                    <span className="dtr-data">
                                                                                        

                                                                                        <Link
                                                                                            style={{ extDecorationLine: "none", }}
                                                                                            to={{ pathname: "/AddAppointment", state: { data: v }, }}
                                                                                            title="Set Appointment"
                                                                                            className="btn btn-info"
                                                                                        >
                                                                                            <i
                                                                                                className="fas fa-calendar"
                                                                                                color="white"
                                                                                                style={{ height: 30, paddingTop: 7, }}
                                                                                            ></i>

                                                                                        </Link>
                                                                                        <Link
                                                                                            style={{
                                                                                                textDecorationLine: "none",
                                                                                            }}
                                                                                            to={{
                                                                                                pathname: "/EditVehicle",
                                                                                                state: {
                                                                                                    data: v,
                                                                                                },
                                                                                            }}
                                                                                            title="Edit Vehicle"
                                                                                            className="btn btn-info"
                                                                                        >
                                                                                            <i
                                                                                                className="fas fa-pencil-alt"
                                                                                                style={{
                                                                                                    height: 30,
                                                                                                    paddingTop: 7,
                                                                                                }}
                                                                                            ></i>
                                                                                        </Link>
                                                                                        <Button
                                                                                            onClick={() => {
                                                                                                setDelId(v.v_id);
                                                                                                setDelModal(true);
                                                                                            }}
                                                                                            title="Delete Vehicle"
                                                                                            className="btn btn-danger del_customer text-middle"
                                                                                        >
                                                                                            <i
                                                                                                className="fas fa-trash"
                                                                                                style={{
                                                                                                    height: 30,
                                                                                                    paddingTop: 7,
                                                                                                }}
                                                                                            ></i>
                                                                                        </Button>
                                                                                    </span>
                                                                                </td> */}
                                                                            </tr>
                                                                        );
                                                                    })}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* content-wrapper ends */}
            {/* partial:partials/_footer.html */}
            <Footer />
        </div>
    );
};

export default UpcomingCollections;
