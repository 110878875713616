import React, { useState, useEffect, useContext } from "react";
import $ from "jquery";

import SideBar from "../../Components/SideBar";
import TopBar from "../../Components/TopBar";
import Footer from "../../Components/Footer";
import SettingPanel from "../../Components/SettingPanel";

import { Link, useHistory, useLocation } from "react-router-dom";
import { Context } from "../../Context/DataContext";
import { apiRequest } from "../../utils/apiCalls.js";
import { doConsole, useForceUpdate } from "../../utils/functions.js";
import Loader from "../../Components/Loader.js";
import { ErrorAlert, SuccessAlert } from "../../Components/Alerts.js";
import { acolors } from "../../css/AppColors";

const AddAppointment = () => {
  const forceUpdate = useForceUpdate();
  const history = useHistory();
  const location = useLocation();

  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [delId, setDelId] = useState("");
  const [delModal, setDelModal] = useState("");

  const { state } = useContext(Context);

  const [appointData, setAppointData] = useState({
    file_no: location?.state?.data?.file_no ? location.state.data.file_no : '',
    v_reg_no: location?.state?.data?.v_reg_no ? location.state.data.v_reg_no : '',
    appoint_status: "Pending",
    cust_fname: "",
    cust_lname: "",
    cust_phone: "",
    cust_email: "",
    appoint_date: "",
    appoint_time: "",
    appoint_remarks: "",
    g_id: state.garageData.g_id,
    token: state.garageData.token,
  });

  function addAppoint(event) {
    event.preventDefault();
    doConsole(appointData);
    setLoading(true);
    apiRequest(appointData, "add_appoint")
      .then((data) => {
        doConsole(data);
        setLoading(false);
        if (data.status == "1") {
          setErrorMessage("");
          setSuccess(true);
          window.scrollTo(0, 0);
          setTimeout(() => {
            history.push("/appointmentCalender");
          }, 2000);
        } else {
          setErrorMessage(data.error_msg);
          forceUpdate();
          window.scrollTo(0, 0);
          // $("#datepicker-popup").focus();
        }
      })
      .catch((err) => {
        doConsole(err);
        setLoading(false);
      });
  }

  React.useEffect(() => {
    $("#appointments").addClass("show");
  }, []);

  // useEffect(() => {
  //   if (!location?.state?.data?.file_no && !location?.state?.data?.v_reg_no)
  //     history.push("listVehicle");
  // }, []);

  return (

    <div className="main-panel">
      <div className="content-wrapper">

        {loading && <Loader />}
        {errorMessage && (<ErrorAlert onClose={() => setErrorMessage("")} errorMessage={errorMessage} />)}
        {success && (<SuccessAlert message={"Hurry! Appointment has been added!"} />)}

        <div className="row">
          <div className="col-sm-12">
            <div className="home-tab">
              <div className="tab-content tab-content-basic">
                <div
                  className="tab-pane fade show active"
                  id="overview"
                  role="tabpanel"
                  aria-labelledby="overview"
                >
                  <div className="row">
                    <div className="col-12">
                      <h3 className="text-black fw-bold">
                        Add Appointment
                      </h3>
                      <br />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 col-md-12 d-flex flex-column">
                      <div className="row flex-grow">
                        <div className="col-12 col-md-12 col-lg-12 grid-margin stretch-card">
                          <div className="card card-rounded">
                            <div className="card-body">
                              <div className="d-sm-flex justify-content-between align-items-start">
                                <form
                                  onSubmit={(event) => addAppoint(event)}
                                  className="forms-sample"
                                >
                                  <div className="row w-100">
                                    <div className="form-group col-12  col-lg-4 col-md-4 col-sm-12 float-left p-2">
                                      <label for="exampleInputName1">
                                        File No.
                                      </label>
                                      <input
                                        type="text"
                                        min={0}
                                        value={appointData.file_no}
                                        // disabled="true"
                                        className="form-control disabled"
                                        id="exampleInputName1"
                                        placeholder="File No."
                                        onChange={(v) => {
                                          setAppointData({
                                            ...appointData,
                                            file_no: v.target.value
                                          })
                                        }}
                                      />
                                    </div>
                                    <div className="form-group col-12 col-sm-12 col-md-4 col-lg-4 float-left p-2">
                                      <label for="exampleInputName1">
                                        Registration No
                                      </label>
                                      <input
                                        type="text"
                                        min={0}
                                        value={appointData.v_reg_no}
                                        className="form-control disabled"
                                        id="exampleInputName1"
                                        placeholder="Registration No"
                                        onChange={(v) => {
                                          setAppointData({
                                            ...appointData,
                                            v_reg_no: v.target.value
                                          })
                                        }}
                                      />
                                    </div>

                                    <div className="form-group col-12 col-lg-4 col-md-4 col-sm-12 float-left p-2">
                                      <label for="exampleSelectGender">
                                        Status
                                      </label>
                                      <select
                                        value={appointData.appoint_status}
                                        required
                                        onChange={(v) => {
                                          setAppointData({
                                            ...appointData,
                                            appoint_status: v.target.value,
                                          });
                                        }}
                                        className="form-control"
                                        id="exampleSelectGender"
                                      >
                                        <option value="Pending">Pending</option>
                                        <option value="Followup">Followup</option>
                                        <option value="Attended">Attended</option>
                                        <option value="No Show">No Show</option>
                                        <option value="Rescheduled">Rescheduled</option>
                                      </select>
                                    </div>
                                    <div className="form-group col-12 col-sm-12 col-md-3 col-lg-3 float-left p-2">
                                      <label for="exampleInputName1">
                                        Cust First Name
                                      </label>
                                      <input
                                        value={appointData.cust_fname}
                                        required
                                        onChange={(v) => {
                                          setAppointData({
                                            ...appointData,
                                            cust_fname: v.target.value,
                                          });
                                        }}
                                        type="text"
                                        className="form-control"
                                        id="exampleInputName1"
                                        placeholder="First Name"
                                      />
                                    </div>
                                    <div className="form-group col-12 col-sm-12 col-md-3 col-lg-3 float-left p-2">
                                      <label for="exampleInputName1">
                                        Cust Last Name
                                      </label>
                                      <input
                                        value={appointData.cust_lname}
                                        required
                                        onChange={(v) => {
                                          setAppointData({
                                            ...appointData,
                                            cust_lname: v.target.value,
                                          });
                                        }}
                                        type="text"
                                        className="form-control"
                                        id="exampleInputName1"
                                        placeholder="Last Name"
                                      />
                                    </div>
                                    <div className="form-group col-12 col-sm-12 col-md-3 col-lg-3 float-left p-2">
                                      <label for="exampleInputName1">
                                        Cust Phone
                                      </label>
                                      <input
                                        value={appointData.cust_phone}
                                        required
                                        onChange={(v) => {
                                          setAppointData({
                                            ...appointData,
                                            cust_phone: v.target.value,
                                          });
                                        }}
                                        type="text"
                                        className="form-control"
                                        id="exampleInputName1"
                                        placeholder="Phone No."
                                      />
                                    </div>
                                    <div className="form-group col-12 col-sm-12 col-md-3 col-lg-3 float-left p-2">
                                      <label for="exampleInputName1">
                                        Cust Email
                                      </label>
                                      <input
                                        value={appointData.cust_email}
                                        // required
                                        onChange={(v) => {
                                          setAppointData({
                                            ...appointData,
                                            cust_email: v.target.value,
                                          });
                                        }}
                                        type="text"
                                        className="form-control"
                                        id="exampleInputName1"
                                        placeholder="Email"
                                      />
                                    </div>
                                    <div className="form-group col-12 col-sm-12 col-md-3 col-lg-3 float-left p-2">
                                      <label for="exampleInputName1">
                                        Appointment date
                                      </label>
                                      <div
                                        id="datepicker-popup"
                                        className="input-group date datepicker navbar-date-picker"
                                      >
                                        <span className="input-group-addon input-group-prepend border-right">
                                          <span className="icon-calendar input-group-text calendar-icon"></span>
                                        </span>
                                        <input
                                          value={appointData.appoint_date}
                                          required
                                          onChange={(v) => {
                                            setAppointData({
                                              ...appointData,
                                              appoint_date: v.target.value,
                                            });
                                          }}
                                          type="date"
                                          className="form-control"
                                          placeholder="dd/mm/yyyy"
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group col-12 col-sm-12 col-md-3 col-lg-3 float-left p-2">
                                      <label for="exampleInputName1">
                                        Appointment time
                                      </label>
                                      <div
                                        id="datepicker-popup"
                                        className="input-group date datepicker navbar-date-picker"
                                      >
                                        <span className="input-group-addon input-group-prepend border-right">
                                          <span className="icon-calendar input-group-text calendar-icon"></span>
                                        </span>
                                        <input
                                          value={appointData.appoint_time}
                                          required
                                          onChange={(v) => {
                                            setAppointData({
                                              ...appointData,
                                              appoint_time: v.target.value,
                                            });
                                          }}
                                          type="time"
                                          className="form-control"
                                          placeholder="hh:mm"
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group col-12 col-sm-12 col-md-6 col-lg-6 float-left p-2">
                                      <label for="exampleInputName1">
                                        Remarks
                                      </label>
                                      <input
                                        value={appointData.appoint_remarks}
                                        required
                                        onChange={(v) => {
                                          setAppointData({
                                            ...appointData,
                                            appoint_remarks: v.target.value,
                                          });
                                        }}
                                        type="text"
                                        className="form-control"
                                        id="exampleInputName1"
                                        placeholder="Remarks"
                                      />
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-12 text-center">
                                      <input
                                        title="Save"
                                        type="submit"
                                        className="btn me-2"
                                        style={{
                                          backgroundColor: acolors.primary,
                                          color: "white",
                                        }}
                                      />
                                      <button className="btn btn-light">
                                        Cancel
                                      </button>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* content-wrapper ends */}
      {/* partial:partials/_footer.html */}
      <Footer />
      {/* partial */}
    </div>

  );
};

export default AddAppointment;
