import React, { useEffect, useState, useContext } from "react";
import $ from "jquery";

import { Button, Modal } from "react-bootstrap";

import SideBar from "../../Components/SideBar";
import TopBar from "../../Components/TopBar";
import Footer from "../../Components/Footer";
import SettingPanel from "../../Components/SettingPanel";

import { Context } from "../../Context/DataContext";
import { apiRequest } from "../../utils/apiCalls";
import { doConsole, useForceUpdate } from "../../utils/functions";
import Loader from "../../Components/Loader";
import { acolors } from "../../css/AppColors";
import { Link, useHistory, useLocation } from "react-router-dom";
import { ErrorAlert, SuccessAlert } from "../../Components/Alerts";

const ViewOpenTasks = () => {
  const { state } = useContext(Context);
  const forceUpdate = useForceUpdate();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);

  const [openTasks, setOpenTasks] = useState([]);
  const [taskStatus, setTaskStatus] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);

  function getTaskTitles(t) {

    const checkAdmin = localStorage.getItem("garage_data") && JSON.parse(localStorage.getItem("garage_data"));
    var reqObj = {};
    if (checkAdmin.g_email) {
      reqObj = {
        token: state.garageData.token,
        g_id: state.garageData.g_id,
        task_status: t ? t : 'Open'
      };
      // alert('showing admin')
    }
    else {
      reqObj = {
        token: state.garageData.token,
        g_id: state.garageData.g_id,
        task_status: t ? t : 'Open',
        st_id: checkAdmin.st_id
      }
      // alert('showing staff')
    }


    setTaskStatus(t ? t : 'Open');
    setLoading(true);
    apiRequest(reqObj, "get_open_tasks")
      .then((data) => {
        setLoading(false);
        if (data.status == "1") {
          if (data.data) {
            setOpenTasks(data.data);
          }
        } else {
          setErrorMessage(data.error_msg);
          window.scrollTo(0, 0);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  function changeTaskStatus(t, taskId) {
    const reqObj = {
      token: state.garageData.token,
      g_id: state.garageData.g_id,
      task_status: t,
      task_id: taskId
    };
    setLoading(true);
    apiRequest(reqObj, "change_task_status")
      .then((data) => {
        setLoading(false);
        if (data.status == "1") {
          setErrorMessage('')
          if (data.data) {
            setTaskStatus(t);
            setOpenTasks(data.data);
          }
        } else {
          setErrorMessage(data.error_msg);
          window.scrollTo(0, 0);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  function checkDueData(v, status) {

    if (v) {
      var t = v.split(/[- :]/);
      var v = new Date(t);
      const current_date = new Date();

      // console.log('v')
      // console.log(v)
      // console.log('new date')
      if (status == 'Close') {
        return '#90ee90'
      }

      if (Date.parse(current_date) > Date.parse(v)) {
        return 'red';
      }
      else return ''
    }


  }


  useEffect(() => {


    // $(document).ready(function () {
    //     $('#dataTable').DataTable();
    // });
    $("#to_do").addClass("show");
  }, [loading]);

  useEffect(() => {

    setLoading(true)
    const checkIsAdmin = JSON.parse(localStorage.getItem('garage_data'));
    if (checkIsAdmin?.g_email) {
      setIsAdmin(true);
      forceUpdate();
    }
    else {
      setIsAdmin(false);
      forceUpdate();
    }
    setLoading(false)

    getTaskTitles();
  }, []);

  return (
    <div className="main-panel">
      <div className="content-wrapper">
        {loading && <Loader />}
        {errorMessage && (
          <ErrorAlert
            onClose={() => setErrorMessage("")}
            errorMessage={errorMessage}
          />
        )}
        {success && (
          <SuccessAlert message={"Hurry! Your vehicle has been saved!"} />
        )}

        <div className="row">
          <div className="col-sm-12">
            <div className="home-tab">
              <div className="tab-content tab-content-basic">
                <div
                  className="tab-pane fade show active"
                  id="overview"
                  role="tabpanel"
                  aria-labelledby="overview"
                >
                  {/* <div className="row">
                    <div className="col-8 float-left">
                      <h3 className="text-black fw-bold">{taskStatus} Tasks</h3>
                      <br />
                    </div>
                    <div className="col-4 text-right float-left">
                      <a
                        href="/assignTask"
                        className="d-none d-sm-inline-block btn btn-sm btn-outline-primary shadow-sm"
                      >
                        <i className="fas fa-folder-plus fa-sm text-white-20" />{" "}
                        Assign Task
                      </a>
                    </div>
                  </div> */}

                  <div className="form-group col-12 col-lg-4 col-md-4 col-sm-12 float-left p-2">
                    <label for="exampleSelectGender">
                      Task Type
                    </label>
                    <select required
                      onChange={(v) => {
                        getTaskTitles(v.target.value);
                        // doConsole(t.target.value);
                        // setVData({
                        //   ...vData,
                        //   vs_id: t.target.value,
                        // });
                      }}
                      // value={vData.vs_id}
                      className="form-control"
                      id="exampleSelectGender"
                    >
                      <option selected disabled>Select</option>
                      <option value={"Open"} selected={taskStatus == 'Open' ? true : false} >Open</option>
                      <option value={"In Progress"} selected={taskStatus == 'In Progress' ? true : false} >In Progress</option>
                      <option value={"Completed"} selected={taskStatus == 'Completed' ? true : false} >Completed</option>
                      <option value={"Close"} selected={taskStatus == 'Close' ? true : false} >Closed</option>
                    </select>
                  </div>
                  <div>
                    {/* Page Heading */}
                    <div>
                      {/* DataTales Example */}
                      <div
                        className="card shadow mb-4"
                        style={{ width: "100%" }}
                      >

                        <div className="card-body">
                          <div className="table-responsive">
                            {/* table className="table table-bordered dt-responsive" id="dataTable" width="100%" cellspacing="0" */}
                            <table
                              id="dataTable"
                              className="table table-striped table-bordered dt-responsive"
                              style={{ width: "100%" }}
                            >
                              <thead>
                                <tr>
                                  <th className="tRow" style={{ width: "20%" }}>Assigned to</th>
                                  <th className="tRow" style={{ width: "30%" }}>Task - Details</th>
                                  <th className="tRow" style={{ width: "20%" }} > Due date </th>
                                  <th className="tRow" style={{ width: "15%" }} > Priority </th>
                                  <th className="tRow" style={{ width: "15%" }} > Status </th>
                                  <th className="tRow text-center">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {openTasks?.map((v) => {
                                  return (
                                    <tr style={{ backgroundColor: checkDueData(v.task_due_date, v.task_status) }} >
                                      <td>
                                        <nobr>{v.st_fname + " " + v.st_lname}</nobr>
                                        <br></br>
                                      </td>
                                      <td><nobr>{v.task_details}</nobr></td>
                                      <td><nobr>{v.task_due_date}</nobr></td>
                                      <td>
                                        <select className="form-control bg-white text-black-50">
                                          <option disabled={isAdmin ? false : true} selected={v.task_priority == "High" ? true : false} value="High">High</option>
                                          <option disabled={isAdmin ? false : true} selected={v.task_priority == "Medium" ? true : false} value="Medium">Medium</option>
                                          <option disabled={isAdmin ? false : true} selected={v.task_priority == "Normal" ? true : false} value="Normal">Normal</option>
                                        </select>
                                      </td>
                                      <td>
                                        <select
                                          onChange={(t) => {
                                            changeTaskStatus(t.target.value, v.task_id)
                                          }}
                                          className="form-control bg-white text-black-50">
                                          <option value={"Open"} selected={v.task_status == "Open" ? true : false}>Open</option>
                                          <option value={"In Progress"} selected={v.task_status == "In Progress" ? true : false}>In Progress</option>
                                          <option value={"Completed"} selected={v.task_status == "Completed" ? true : false}>Complete</option>
                                          <option value={"Close"} disabled={isAdmin ? false : true} selected={v.task_status == "Close" ? true : false}>Close</option>
                                        </select>
                                      </td>

                                      <td>
                                        <span className="dtr-data">
                                        
                                          <Link
                                            style={{
                                              textDecorationLine: "none",
                                            }}
                                            to={{
                                              pathname: "/EditVehicle",
                                              state: {
                                                data: v,
                                              },
                                            }}
                                            title="Edit Vehicle"
                                            className="btn btn-info"
                                          >
                                            <i
                                              className="fas fa-pencil-alt"
                                              style={{
                                                height: 30,
                                                paddingTop: 7,
                                              }}
                                            ></i>
                                          </Link>
                                          <Button
                                            onClick={() => {
                                              // setDelId(v.v_id);
                                              // setDelModal(true);
                                            }}
                                            title="Delete Vehicle"
                                            className="btn btn-danger del_customer text-middle"
                                          >
                                            <i
                                              className="fas fa-trash"
                                              style={{
                                                height: 30,
                                                paddingTop: 7,
                                              }}
                                            ></i>
                                          </Button>
                                        </span>
                                      </td>

                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* content-wrapper ends */}
      {/* partial:partials/_footer.html */}
      <Footer />
      {/* partial */}
    </div>

  );
};

export default ViewOpenTasks;
