export const alertMessage = ({ title, message, type = 'success',duration = 2000 }) => ({
	title,
	message,
	type,
	insert: 'top',
	container: 'top-right',
	animationIn: ['animate__animated', 'animate__flipInX'],
	animationOut: ['animate__animated', 'animate__fadeOut'],
	dismiss: {
		duration,
		onScreen: true,
	},
});