import React, { useEffect, useState, useContext } from "react";
import $ from "jquery";

import SideBar from "../../Components/SideBar";
import TopBar from "../../Components/TopBar";
import Footer from "../../Components/Footer";
import SettingPanel from "../../Components/SettingPanel";

import { Context } from "../../Context/DataContext";
import { apiRequest } from "../../utils/apiCalls";
import { doConsole, useForceUpdate } from "../../utils/functions";
import Loader from "../../Components/Loader";
import { acolors } from "../../css/AppColors";
import { useHistory } from "react-router-dom";
import { ErrorAlert, SuccessAlert } from "../../Components/Alerts";
import { useLocation } from "react-router-dom";

const UpcomingMotReport = () => {
  const { state } = useContext(Context);
  const forceUpdate = useForceUpdate();
  const history = useHistory();
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  function getMots() {
    setLoading(true);
    const reqObj = {
      token: state.garageData.token,
      g_id: state.garageData.g_id,
    };
    apiRequest(reqObj, "get_upcoming_mots")
      .then((data) => {
        doConsole(data);
        setLoading(false);
        if (data.status == "1") {
          doConsole(data.data);
          setData(data.data);
        } else {
          setErrorMessage(data.error_msg);
          forceUpdate();
          window.scrollTo(0, 0);
        }
      })
      .catch((err) => {
        doConsole(err);
        setLoading(false);
      });
  }

  useEffect(() => {
    // $(document).ready(function () {
    //     $('#dataTable').DataTable();
    // });
    $("#reports").addClass("show");
  }, [loading]);

  useEffect(() => {
    getMots();
  }, []);

  return (

    <div className="main-panel">
      <div className="content-wrapper">
        {loading && <Loader />}
        {errorMessage && (
          <ErrorAlert
            onClose={() => setErrorMessage("")}
            errorMessage={errorMessage}
          />
        )}
        {success && (
          <SuccessAlert message={"Hurry! Your vehicle has been updated!"} />
        )}

        <div className="row">
          <div className="col-sm-12">
            <div className="home-tab">
              <div className="tab-content tab-content-basic">
                <div
                  className="tab-pane fade show active"
                  id="overview"
                  role="tabpanel"
                  aria-labelledby="overview"
                >
                  <div className="row">
                    <div className="col-12">
                      <h3 className="text-black fw-bold">Upcoming MOT</h3>
                      <br />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 col-md-12 d-flex flex-column">
                      <div className="row flex-grow">
                        <div className="col-12 col-md-12 col-lg-12 grid-margin stretch-card">
                          <div className="card card-rounded text-center pt-2">
                            <div className="card-body">
                              <div className="d-sm-flex justify-content-between align-items-start">
                                <div className="row w-100">
                                  {/* Page Heading */}
                                  <div>
                                    {/* DataTales Example */}
                                    {/* <div className="card shadow mb-4" style={{ width: "100%" }} > */}
                                    <div className="card-body">
                                      <div className="table-responsive">
                                        {/* table className="table table-bordered dt-responsive" id="dataTable" width="100%" cellspacing="0" */}
                                        <table
                                          id="dataTable"
                                          className="table table-striped table-bordered dt-responsive"
                                          style={{ width: "100%" }}
                                        >
                                          <thead>
                                            <tr>
                                              <th className="tRow">
                                                File No.
                                              </th>
                                              <th className="tRow">
                                                Reg. No.
                                              </th>
                                              <th className="tRow">
                                                MOT Due date
                                              </th>
                                              <th className="tRow">
                                                Days left
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {data?.map((v, i) => {
                                              return (
                                                <tr>
                                                  <td className="text-justify">
                                                    {v.file_no}
                                                  </td>
                                                  <td className="text-justify">
                                                    {v.v_reg_no}
                                                  </td>
                                                  <td className="text-justify">
                                                    {v.v_mot}
                                                  </td>
                                                  <td className="text-center">
                                                    {v.days_left}
                                                  </td>
                                                </tr>
                                              );
                                            })}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                    {/* </div> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* content-wrapper ends */}
      {/* partial:partials/_footer.html */}
      <Footer />
      {/* partial */}
    </div>
  );
};
export default UpcomingMotReport;
