import React from "react";
import { Modal, Button, Form, Col, Row, Container } from "react-bootstrap";
import { acolors } from "../css/AppColors";

const Loader = () => {
  return (
    <div>
      <Modal
        show={true}
        // onHide={() => setLoading(false)}
      >
        <Modal.Body className="text-center">
          <div
            className="text-center"
            className="spinner-border text-center"
            style={{ margin: 10, color: acolors.primary }}
            role="status"
          ></div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Loader;
