import React, { useEffect, useState, useContext } from "react";
import $ from "jquery";

import SideBar from "../../Components/SideBar";
import TopBar from "../../Components/TopBar";
import Footer from "../../Components/Footer";
import SettingPanel from "../../Components/SettingPanel";

import { Link } from "react-router-dom";
import { Context } from "../../Context/DataContext";
import { apiRequest } from "../../utils/apiCalls.js";
import { doConsole, useForceUpdate } from "../../utils/functions.js";
import Loader from "../../Components/Loader.js";
import { ErrorAlert, SuccessAlert } from "../../Components/Alerts.js";
import { acolors } from "../../css/AppColors";
import { useHistory, useLocation } from "react-router-dom";

const AddIncome = () => {
  const forceUpdate = useForceUpdate();
  const history = useHistory();
  const location = useLocation();

  const { state } = useContext(Context);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [staffData, setStaffData] = useState([]);
  const [delId, setDelId] = useState("");
  const [delModal, setDelModal] = useState("");

  const [accountTypes, setAccountTypes] = useState([]);
  const [accounts, setAccounts] = useState([]);
  // var date = new Date()

  const [postData, setPostData] = useState({
    g_id: state.garageData.g_id,
    token: state.garageData.token,
    j_date: new Date(),
    debit_ac: "",
    credit_ac: "",
    j_ref: "",
    amount: "",
    j_description: "",
  });

  function get_accounts() {
    setLoading(true);
    const reqObj = {
      token: state.garageData.token,
      g_id: state.garageData.g_id,
    };
    apiRequest(reqObj, "get_accounts")
      .then((data) => {
        doConsole(data);
        setLoading(false);
        if (data.status == "1") {
          doConsole(data.data);
          setAccounts(data.data);
        } else {
          setErrorMessage(data.error_msg);
          forceUpdate();
          window.scrollTo(0, 0);
        }
      })
      .catch((err) => {
        doConsole(err);
        setLoading(false);
      });
  }

  function doAddIncome(event) {
    event.preventDefault();
    doConsole(postData);
    setLoading(true);
    apiRequest(postData, "add_transacton")
      .then((data) => {
        doConsole(data);
        setLoading(false);
        if (data.status == "1") {
          setErrorMessage("");
          setSuccess(true);
          window.scrollTo(0, 0);
          setTimeout(() => {
            history.push("/journal");
          }, 1500);
        } else {
          setErrorMessage(data.error_msg);
          forceUpdate();
          window.scrollTo(0, 0);
          // $("#datepicker-popup").focus();
        }
      })
      .catch((err) => {
        doConsole(err);
        setLoading(false);
      });
  }

  useEffect(() => {
    // $(document).ready(function () {
    //     $('#dataTable').DataTable();
    // });
    $("#accounts").addClass("show");
  }, [loading]);

  useEffect(() => {
    get_accounts();
  }, []);

  return (
        <div className="main-panel">
          <div className="content-wrapper">
            {loading && <Loader />}
            {errorMessage && (
              <ErrorAlert
                onClose={() => setErrorMessage("")}
                errorMessage={errorMessage}
              />
            )}
            {success && (
              <SuccessAlert
                message={"Hurry! Your transaction has been saved!"}
              />
            )}
            <div className="row">
              <div className="col-sm-12">
                <div className="home-tab">
                  <div className="tab-content tab-content-basic">
                    <div
                      className="tab-pane fade show active"
                      id="overview"
                      role="tabpanel"
                      aria-labelledby="overview"
                    >
                      <div className="row">
                        <div className="col-12">
                          <h3 className="text-black fw-bold">
                            Add Income/Expense
                          </h3>
                          <br />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12 col-md-12 d-flex flex-column">
                          <div className="row flex-grow">
                            <div className="col-12 col-md-12 col-lg-12 grid-margin stretch-card">
                              <div className="card card-rounded">
                                <div className="card-body">
                                  <div className="d-sm-flex justify-content-between align-items-start">
                                    <div className="row w-100">
                                      <form
                                        onSubmit={(event) => doAddIncome(event)}
                                        className="forms-sample"
                                      >
                                        <div
                                          className="form-group"
                                          className="form-group col-12 col-sm-6 col-md-2 col-lg-2 float-left p-2"
                                        >
                                          <label for="exampleInputName1">
                                            Date
                                          </label>
                                          <div
                                            id="datepicker-popup"
                                            className="input-group date datepicker navbar-date-picker"
                                          >
                                            <span className="input-group-addon input-group-prepend border-right">
                                              {/* <span  className="icon-calendar input-group-text calendar-icon"></span> */}
                                            </span>
                                            <input
                                              onChange={(t) => {
                                                setPostData({
                                                  ...postData,
                                                  j_date: t.target.value,
                                                });
                                              }}
                                              placeholder="dd/mm/yyyy"
                                              value={postData.j_date}
                                              required
                                              type="date"
                                              className="form-control p-1"
                                            />
                                          </div>
                                        </div>

                                        <div className="form-group col-12  col-lg-3 col-md-3 col-sm-6 float-left p-2">
                                          <label for="exampleSelectGender">
                                            Debit A/C
                                          </label>
                                          <select
                                            onChange={(t) => {
                                              setPostData({
                                                ...postData,
                                                debit_ac: t.target.value,
                                              });
                                            }}
                                            value={postData.debit_ac}
                                            required
                                            className="form-control"
                                            id="exampleSelectGender"
                                            name="debit_ac"
                                          >
                                            <option
                                              disabled={
                                                postData.debit_ac ? true : false
                                              }
                                            >
                                              Select
                                            </option>
                                            {accounts?.map((v) => {
                                              return (
                                                <option value={v.a_id}>
                                                  {v.a_title}
                                                </option>
                                              );
                                            })}
                                          </select>
                                        </div>
                                        <div className="form-group col-12  col-lg-3 col-md-3 col-sm-6 float-left p-2">
                                          <label for="exampleSelectGender">
                                            Credit A/C
                                          </label>
                                          <select
                                            onChange={(t) => {
                                              setPostData({
                                                ...postData,
                                                credit_ac: t.target.value,
                                              });
                                            }}
                                            value={postData.credit_ac}
                                            required
                                            className="form-control"
                                            id="exampleSelectGender"
                                            name="credit_ac"
                                          >
                                            <option
                                              disabled={
                                                postData.credit_ac
                                                  ? true
                                                  : false
                                              }
                                            >
                                              Select
                                            </option>
                                            {accounts?.map((v) => {
                                              return (
                                                <option value={v.a_id}>
                                                  {v.a_title}
                                                </option>
                                              );
                                            })}
                                          </select>
                                        </div>
                                        <div className="form-group col-12  col-lg-1 col-md-1 col-sm-6 float-left p-2">
                                          <label for="exampleInputName1">
                                            Ref. No.
                                          </label>
                                          <input
                                            onChange={(t) => {
                                              setPostData({
                                                ...postData,
                                                j_ref: t.target.value,
                                              });
                                            }}
                                            value={postData.j_ref}
                                            required
                                            type="text"
                                            className="form-control p-1"
                                            id="exampleInputName1"
                                            placeholder="Ref. No."
                                          />
                                        </div>

                                        <div
                                          className="form-group"
                                          className="form-group col-12 col-sm-12 col-md-3 col-lg-3 float-left p-2"
                                        >
                                          <label for="exampleInputName1">
                                            Amount
                                          </label>
                                          <input
                                            onChange={(t) => {
                                              setPostData({
                                                ...postData,
                                                amount: t.target.value,
                                              });
                                            }}
                                            value={postData.amount}
                                            required
                                            type="number"
                                            min="0"
                                            className="form-control  p-1"
                                            id="exampleInputName1"
                                            placeholder="Amount"
                                          />
                                        </div>
                                        <div
                                          className="form-group"
                                          className="form-group col-12 col-sm-12 col-md-12 col-lg-12 float-left p-2"
                                        >
                                          <label for="exampleInputName1">
                                            Description
                                          </label>
                                          <input
                                            onChange={(t) => {
                                              setPostData({
                                                ...postData,
                                                j_description: t.target.value,
                                              });
                                            }}
                                            value={postData.j_description}
                                            required
                                            type="text"
                                            className="form-control p-1"
                                            id="exampleInputName1"
                                            placeholder="Description"
                                          />
                                        </div>

                                        <div className="col-12 text-center">
                                          <input
                                            type="submit"
                                            title="Save"
                                            className="btn me-2  "
                                            style={{
                                              backgroundColor: acolors.primary,
                                              color: "white",
                                            }}
                                          />
                                          <button className="btn btn-light">
                                            Cancel
                                          </button>
                                        </div>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* content-wrapper ends */}
          {/* partial:partials/_footer.html */}
          <Footer />
          {/* partial */}
        </div>
  );
};
export default AddIncome;
