import React, { useEffect, useState, useContext } from "react";
import $ from "jquery";

import SideBar from "../../Components/SideBar";
import TopBar from "../../Components/TopBar";
import Footer from "../../Components/Footer";

import { Context } from "../../Context/DataContext";
import { apiRequest } from "../../utils/apiCalls";
import { doConsole, useForceUpdate } from "../../utils/functions";
import Loader from "../../Components/Loader";
import { acolors } from "../../css/AppColors";
import { Link, useHistory, useLocation } from "react-router-dom";
import { ErrorAlert, SuccessAlert } from "../../Components/Alerts";

import SettingPanel from "../../Components/SettingPanel";
import { Button, Modal } from "react-bootstrap";

const ManageTasks = () => {
  const { state } = useContext(Context);
  const forceUpdate = useForceUpdate();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);

  const [taskTitles, setTaskTitles] = useState([]);

  const [delId, setDelId] = useState("");
  const [delModal, setDelModal] = useState("");

  function getTaskTitles() {
    const reqObj = {
      token: state.garageData.token,
      g_id: state.garageData.g_id,
    };
    setLoading(true);
    apiRequest(reqObj, "get_task_titles")
      .then((data) => {
        doConsole(data);
        setLoading(false);
        if (data.status == "1") {
          if (data.data) {
            setTaskTitles(data.data);
          }
        } else {
          setErrorMessage(data.error_msg);
          window.scrollTo(0, 0);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  function doDel() {
    const reqObj = {
      token: state.garageData.token,
      g_id: state.garageData.g_id,
      tt_id: delId,
    };
    setLoading(true);
    apiRequest(reqObj, "delete_task_title")
      .then((data) => {
        doConsole(data);
        setLoading(false);
        if (data.status == "1") {
          setSuccess(true);
          if (data.data) {
            setTaskTitles(data.data);
          }
        } else {
          setErrorMessage(data.error_msg);
          window.scrollTo(0, 0);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  useEffect(() => {
    getTaskTitles();
  }, []);

  useEffect(() => {
    // $(document).ready(function () {
    //     $('#dataTable').DataTable();
    // });
    $("#to_do").addClass("show");
  }, [loading]);

  return (
   
        <div className="main-panel">
          <div className="content-wrapper">
            {loading && <Loader />}
            {errorMessage && (
              <ErrorAlert
                onClose={() => setErrorMessage("")}
                errorMessage={errorMessage}
              />
            )}
            {success && (
              <SuccessAlert message={"Hurry! Your tasks has been updated!"} />
            )}
            <Modal show={delModal} onHide={() => setDelModal(false)}>
              <Modal.Header>
                <Modal.Title
                  style={{
                    fontSize: 18,
                    textAlign: "center",
                    alignSelf: "center",
                    fontFamily: "sans-serif",
                    fontWeight: "bold",
                  }}
                >
                  Are you sure you want to delete this Task?
                </Modal.Title>
              </Modal.Header>
              <Modal.Footer>
                <Button
                  style={{ fontFamily: "sans-serif", fontWeight: "bold" }}
                  variant="secondary"
                  onClick={() => {
                    setDelModal(false);
                  }}
                >
                  No
                </Button>
                <Button
                  style={{ fontFamily: "sans-serif", fontWeight: "bold" }}
                  variant="danger"
                  onClick={() => {
                    setDelModal(false);
                    doDel();
                  }}
                >
                  Yes
                </Button>
              </Modal.Footer>
            </Modal>

            <div className="row">
              <div className="col-sm-12">
                <div className="home-tab">
                  <div className="tab-content tab-content-basic">
                    <div
                      className="tab-pane fade show active"
                      id="overview"
                      role="tabpanel"
                      aria-labelledby="overview"
                    >
                      <div className="row">
                        <div className="col-8 float-left">
                          <h3 className="text-black fw-bold">Manage Tasks</h3>
                          <br />
                        </div>
                        <div className="col-4 text-right float-left">
                          <Link
                            to={{
                              pathname: "addTask",
                            }}
                            // href="/addTask"
                            className="d-none d-sm-inline-block btn btn-sm btn-outline-primary shadow-sm"
                          >
                            <i className="fas fa-folder-plus fa-sm text-white-20" />{" "}
                            Add Task
                          </Link>
                        </div>
                      </div>
                      <div>
                        {/* Page Heading */}
                        <div>
                          {/* DataTales Example */}
                          <div
                            className="card shadow mb-4"
                            style={{ width: "100%" }}
                          >
                            <div className="card-body">
                              <div className="table-responsive">
                                {/* table className="table table-bordered dt-responsive" id="dataTable" width="100%" cellspacing="0" */}
                                <table
                                  id="dataTable"
                                  className="table table-striped table-bordered dt-responsive"
                                  style={{ width: "100%" }}
                                >
                                  <thead>
                                    <tr>
                                      <th
                                        className="tRow"
                                        style={{ width: "20%" }}
                                      >
                                        Type
                                      </th>
                                      <th
                                        className="tRow"
                                        style={{ width: "30%" }}
                                      >
                                        Title
                                      </th>
                                      <th
                                        className="tRow"
                                        style={{ width: "20%" }}
                                      >
                                        Status
                                      </th>
                                      <th
                                        className="tRow"
                                        style={{ width: "30%" }}
                                      >
                                        Action
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {taskTitles?.map((v, i) => {
                                      return (
                                        <tr>
                                          <td>
                                            <nobr>
                                              <a href="#!" />
                                              {v.tt_type}
                                            </nobr>
                                          </td>
                                          <td>
                                            <nobr>{v.tt_title}</nobr>
                                          </td>
                                          <td>
                                            <nobr>
                                              {v.tt_status == 1
                                                ? "Active"
                                                : "In-active"}
                                            </nobr>
                                          </td>
                                          <td>
                                            <Link
                                              style={{
                                                textDecorationLine: "none",
                                              }}
                                              to={{
                                                pathname: "/EditTask",
                                                state: {
                                                  data: v,
                                                },
                                              }}
                                              title="Edit Vehicle"
                                              className="btn btn-info"
                                            >
                                              <i className="fas fa-pencil-alt"></i>
                                            </Link>
                                            <Button
                                              onClick={() => {
                                                setDelId(v.tt_id);
                                                setDelModal(true);
                                              }}
                                              title="Delete Vehicle"
                                              className="btn btn-danger del_customer"
                                            >
                                              <i className="fas fa-trash"></i>
                                            </Button>
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* content-wrapper ends */}
          {/* partial:partials/_footer.html */}
          <Footer />
          {/* partial */}
        </div>
       
  )};

export default ManageTasks;
