import React from 'react';


export async function getStoreDataFromLocal() {


    // const data = JSON.parse(localStorage.getItem('store_data'))
    const  data = JSON.parse(localStorage.getItem('store_data')) ? JSON.parse(localStorage.getItem('store_data')) : null
    return data
}


export function formatDate(dateObj) {
    var month = dateObj.getMonth() + 1;
    if (month < 10) {
        month = "0" + month;
        if (dateObj.getDate() < 10) {
            const dat = "0" + dateObj.getDate();
            let date = dateObj.getFullYear() + "-" + month + "-" + dat;
            return date
            // props.onValueChange(date);
        }
        else {
            let date = dateObj.getFullYear() + "-" + month + "-" + dateObj.getDate()
            return date
            // props.onValueChange(date);
        }
    }
    else {
        if (dateObj.getDate() < 10) {
            const dat = "0" + dateObj.getDate()
            let date = dateObj.getFullYear() + "-" + month + "-" + dat
            return date
            // props.onValueChange(date);
        }
        else {
            let date = dateObj.getFullYear() + "-" + month + "-" + dateObj.getDate()
            return date
            // props.onValueChange(date);
        }

    }
}


export function validateEmail(text){
    console.log(text);
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (reg.test(text) === false) {

        return false;
    }
    else {
        return true;
    }
}

export const useForceUpdate = () => {
    const [, updateState] = React.useState();
    return React.useCallback(() => updateState({}), []);
}

export function doConsole(d) {
    console.log(d)
  }
  

