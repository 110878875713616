import React, { useEffect, useState, useContext } from "react";
import $ from "jquery";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";

import { Button, Modal } from "react-bootstrap";

import SideBar from "../../Components/SideBar";
import TopBar from "../../Components/TopBar";
import Footer from "../../Components/Footer";
import SettingPanel from "../../Components/SettingPanel";

import { Link } from "react-router-dom";
import { Context } from "../../Context/DataContext";
import { apiRequest } from "../../utils/apiCalls.js";
import { doConsole, useForceUpdate } from "../../utils/functions.js";
import Loader from "../../Components/Loader.js";
import { ErrorAlert, SuccessAlert } from "../../Components/Alerts.js";
import { CSVLink } from "react-csv";
import { acolors } from "../../css/AppColors";

import { Store as notify } from 'react-notifications-component';
import { alertMessage } from "../../utils/helpers";


const ListVehicle = () => {
  const forceUpdate = useForceUpdate();
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const [vData, setVData] = useState([]);
  const [filterDate, setFilterData] = useState([]);
  const [vsData, setVSData] = useState([]);

  const [delId, setDelId] = useState("");
  const [delModal, setDelModal] = useState("");

  const { state } = useContext(Context);

  function getV() {
    setLoading(true);
    const reqObj = {
      token: state.garageData.token,
      g_id: state.garageData.g_id,
    };
    apiRequest(reqObj, "get_vehicles")
      .then((data) => {
        doConsole(data);
        
        if (data.status == "1") {
          setFilterData(data.data);
          setVData(data.data);
          setLoading(false);


        } else {
          setLoading(false);
          setErrorMessage(data.error_msg);
          forceUpdate();
          window.scrollTo(0, 0);
        }
      })
      .catch((err) => {
        doConsole(err);
        setLoading(false);
      });
  }

  function doDel() {
    let checkDelRoute = localStorage.getItem('screens') && JSON.parse(localStorage.getItem('screens'));
    if (checkDelRoute) {
      if (!checkDelRoute.includes('DeleteVehicle')) {
        notify.addNotification(
          alertMessage({
            type: 'danger',
            title: 'Error',
            message: "Access Denied"
          })
        );
        return;
      }
    }

    const reqObj = {
      token: state.garageData.token,
      g_id: state.garageData.g_id,
      v_id: delId,
    };
    setLoading(true);
    apiRequest(reqObj, "delete_vehicle")
      .then((data) => {
        doConsole(data);
        setLoading(false);
        if (data.status == "1") {
          if (data.data) {
            setVData(data.data);
            setFilterData(data.data)
            // forceUpdate();
          }
          else { getV() }
        } else {
          setErrorMessage(data.error_msg);
          window.scrollTo(0, 0);
        }
      })
      .catch((err) => {
        doConsole(err);
        setLoading(false);
      });
  }

  function doFilterVehicle(vs_id) {

    setLoading(true)
    if (vs_id == "all") {
      setFilterData(vData);
      setLoading(false)
      return;
    }

    let data = [];
    data = vData.filter(item => item.vs_id == vs_id);
    // console.log(data)
    setTimeout(() => {
      setFilterData(data);
      setLoading(false)
    }, 500);


    // forceUpdate();
  }

  function getVS() {
    setLoading(true);
    const reqObj = {
      token: state.garageData.token,
      g_id: state.garageData.g_id,
    };
    apiRequest(reqObj, "get_veh_statuses")
      .then((data) => {
        doConsole(data);
        setLoading(false);
        if (data.status == "1") {
          doConsole(data.data);
          setVSData(data.data);
          //   forceUpdate();
        } else {
          setErrorMessage(data.error_msg);
          forceUpdate();
          window.scrollTo(0, 0);
        }
      })
      .catch((err) => {
        doConsole(err);
        setLoading(false);
      });
  }



  useEffect(() => {
    // $(document).ready(function () {
    //     $('#dataTable').DataTable();
    // });

    if ( $.fn.dataTable.isDataTable( '#dataTable' ) ) {
        $("#dataTable").DataTable()

  }
  else {
    
    $(document).ready(function () {
      $("#dataTable").DataTable({
        searching: true,
        paging: true,
        info: false,
        language: {
          emptyTable: "No vehicle found.",
          zeroRecords: "No vehicle matches your search.",
        },
        order: [[0, "desc"]],

      });
    })
  }


    // $(document).ready(function () {
    //   $("#dataTable").DataTable({
    //     searching: true,
    //     paging: true,
    //     info: false,
    //     language: {
    //       emptyTable: "No vehicle found.",
    //       zeroRecords: "No vehicle matches your search.",
    //     },
    //     order: [[0, "desc"]],

    //   });
    // });

    // $("#nav_vehicle").addClass("show");
    // $("#list_vehicle").addClass("active");
  }, [loading]);

  useEffect(() => {
    getV();
    getVS();
  }, []);

  return (
    <div className="main-panel">
      <div className="content-wrapper">
        {loading && <Loader />}
        {errorMessage && (
          <ErrorAlert
            onClose={() => setErrorMessage("")}
            errorMessage={errorMessage}
          />
        )}
        {success && (
          <SuccessAlert message={"Hurry! Your vehicle has been saved!"} />
        )}

        <Modal show={delModal} onHide={() => setDelModal(false)}>
          <Modal.Header>
            <Modal.Title
              style={{
                fontSize: 18,
                textAlign: "center",
                alignSelf: "center",
                fontFamily: "sans-serif",
                fontWeight: "bold",
              }}
            >
              Are you sure you want to delete this Vehicle?
            </Modal.Title>
          </Modal.Header>
          <Modal.Footer>
            <Button
              style={{ fontFamily: "sans-serif", fontWeight: "bold" }}
              variant="secondary"
              onClick={() => {
                setDelModal(false);
              }}
            >
              No
            </Button>
            <Button
              style={{ fontFamily: "sans-serif", fontWeight: "bold" }}
              variant="danger"
              onClick={() => {
                setDelModal(false);
                doDel();
              }}
            >
              Yes
            </Button>
          </Modal.Footer>
        </Modal>

        <div className="row">
          <div className="col-sm-12">
            <div className="home-tab">
              <div className="tab-content tab-content-basic">
                <div
                  className="tab-pane fade show active"
                  id="overview"
                  role="tabpanel"
                  aria-labelledby="overview"
                >
                  <div className="row">
                    <div className="col-8 float-left">
                      <h3 className="text-black fw-bold">Manage Vehicles</h3>
                      <br />
                    </div>
                    <div className="col-4 text-right float-left">
                      <Link
                        style={{ textDecorationLine: "none" }}
                        to="/addVehicle"
                        className="d-none d-sm-inline-block btn btn-sm btn-outline-primary shadow-sm"
                      >
                        <i className="fas fa-folder-plus fa-sm text-white-20" />{" "}
                        Add Vehicle
                      </Link>
                    </div>
                  </div>
                  <div>
                    {/* Page Heading */}
                    <div>
                      {/* DataTales Example */}
                      <div
                        className="card shadow mb-4"
                        style={{ width: "100%" }}
                      >
                        <div className="row col-12">
                          <div className="col-4 float-left">
                            <CSVLink
                              filename="vehicles.csv"
                              style={{ textDecorationLine: "none", backgroundColor: acolors.primary, color: 'white', marginTop: 10, marginLeft: 10, fontFamily: "sans-serif", fontWeight: "bold", }}
                              className="btn me-2"
                              data={vData} >CSV
                              <i className="fas fa-download fa-sm text-white-15 ml-2" />{" "}
                            </CSVLink>
                          </div>

                          <div className="col-2" ></div>

                          <div style={{ marginTop: 10, }} className="col-md-12 col-lg-6 float-right" >
                            <div style={{ fontFamily: "sans-serif", fontSize: 14, fontWeight: "normal", color: 'grey', }} >Filter by Status</div>
                            <select
                              className="form-control"

                              style={{ marginTop: 3, borderRadius: 5 }}
                              onChange={(t) => {
                                doFilterVehicle(t.target.value);
                              }}
                              value={vData.vs_id}
                            // id="exampleSelectGender"
                            >
                              <option disabled >
                                Filter by Status
                              </option>
                              <option value={"all"} selected >
                                All
                              </option>

                              {vsData?.map((v, i) => {
                                return (
                                  <option
                                    key={i}
                                    value={v.vs_id}
                                  >
                                    {v.veh_status}
                                  </option>
                                );
                              })}
                            </select>


                          </div>
                        </div>
                        <div className="card-body">

                          {/* && vData.length  */}
                          {!loading && vData.length ? (
                            <div className="table-responsive">
                              {/* table className="table table-bordered dt-responsive" id="dataTable" width="100%" cellspacing="0" */}

                              <table
                                id="dataTable"
                                className="table table-striped table-bordered dt-responsive dataTable dtr-inline collapsed"
                                style={{ width: "100%" }}
                              >
                                <thead>
                                  <tr >
                                    <th className="tRow text-center">File No.</th>
                                    <th className="tRow text-center">Reg. No.</th>
                                    <th className="tRow text-center">Make/Model</th>
                                    <th className="tRow text-center">Purchase</th>
                                    <th className="tRow text-center">Sale Date/<br />Days in stock{" "}</th>
                                    <th className="tRow text-center">Total Price</th>
                                    <th className="tRow text-center">Sale Price</th>
                                    <th className="tRow text-center">Profit Margin</th>
                                    <th className="tRow text-center">Status</th>
                                    <th className="tRow text-center">Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {filterDate?.map((v) => {
                                    return (
                                      <tr style={{ backgroundColor: v.vs_id == 5 ? 'rgba(255, 240, 0,0.5)' : v.vs_id == 4 ? 'rgba(255, 0, 0, 0.5)' : v.vs_id == 2 ? 'rgba(0,128,0,0.5)' : 'none' }} >
                                        <td className="text-center">
                                          <Link
                                            style={{ extDecorationLine: "none", color: 'black' }}
                                            to={{ pathname: "/viewSingleVehicle", state: { data: v }, }}
                                            title="View Vehicle"
                                            className="text-center"
                                          >
                                            <nobr>{v.file_no}</nobr>
                                          </Link>
                                        </td>
                                        <td className="text-center">
                                          <Link
                                            style={{ extDecorationLine: "none", color: 'black' }}
                                            to={{ pathname: "/viewSingleVehicle", state: { data: v }, }}
                                            title="View Vehicle"
                                            className="text-center"
                                          >
                                            <nobr>{v.v_reg_no}</nobr>
                                          </Link>
                                        </td>
                                        <td
                                        // className="text-center" 
                                        >
                                          <Link
                                            style={{ extDecorationLine: "none", color: 'black' }}
                                            to={{ pathname: "/viewSingleVehicle", state: { data: v }, }}
                                            title="View Vehicle"
                                          // className="text-center"
                                          >
                                            {v.v_make_model}
                                          </Link>
                                        </td>
                                        <td className="text-center"><nobr>{v.v_purchase_date}</nobr></td>
                                        <td className="text-center">
                                          <nobr>{v.v_sale_date ? v.v_sale_date : Math.floor((new Date() - new Date(v.v_purchase_date)) / 1000 / 60 / 60 / 24)}</nobr>
                                        </td>
                                        <td className="text-center"><nobr>{v.v_total_price}</nobr></td>
                                        <td className="text-center"><nobr>{v.v_sale_price}</nobr></td>
                                        <td className="text-center"><nobr>{v.v_profit}</nobr></td>
                                        <td className="text-center"><nobr>{v.veh_status}</nobr></td>
                                        <td>
                                          <span className="dtr-data">
                                            {/* <Link
                                              style={{ extDecorationLine: "none", }}
                                              to={{ pathname: "/viewSingleVehicle", state: { data: v }, }}
                                              title="View Vehicle"
                                              className="btn btn-info"
                                            >

                                              <i
                                                className="fas fa-eye "
                                                color="white"
                                                style={{ height: 30, paddingTop: 7, }}
                                              ></i>
                                            </Link> */}

                                            <Link
                                              style={{ extDecorationLine: "none", }}
                                              to={{ pathname: "/AddAppointment", state: { data: v }, }}
                                              title="Set Appointment"
                                              className="btn btn-info"
                                            >
                                              <i
                                                className="fas fa-calendar"
                                                color="white"
                                                style={{ height: 30, paddingTop: 7, }}
                                              ></i>

                                            </Link>
                                            <Link
                                              style={{
                                                textDecorationLine: "none",
                                              }}
                                              to={{
                                                pathname: "/EditVehicle",
                                                state: {
                                                  data: v,
                                                },
                                              }}
                                              title="Edit Vehicle"
                                              className="btn btn-info"
                                            >
                                              <i
                                                className="fas fa-pencil-alt"
                                                style={{
                                                  height: 30,
                                                  paddingTop: 7,
                                                }}
                                              ></i>
                                            </Link>
                                            <Button
                                              onClick={() => {
                                                setDelId(v.v_id);
                                                setDelModal(true);
                                              }}
                                              title="Delete Vehicle"
                                              className="btn btn-danger del_customer text-middle"
                                            >
                                              <i
                                                className="fas fa-trash"
                                                style={{
                                                  height: 30,
                                                  paddingTop: 7,
                                                }}
                                              ></i>
                                            </Button>
                                          </span>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* content-wrapper ends */}
      {/* partial:partials/_footer.html */}
      <Footer />
    </div>
  );
};

export default ListVehicle;
