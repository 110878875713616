import React, { useEffect, useState, useContext } from "react";
import $ from "jquery";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";

import { Button, Modal } from "react-bootstrap";

import SideBar from "../../Components/SideBar";
import TopBar from "../../Components/TopBar";
import Footer from "../../Components/Footer";
import SettingPanel from "../../Components/SettingPanel";

import { Context } from "../../Context/DataContext";
import { apiRequest } from "../../utils/apiCalls";
import { doConsole, useForceUpdate } from "../../utils/functions";
import Loader from "../../Components/Loader";
import { acolors } from "../../css/AppColors";
import { Link, useHistory, useLocation } from "react-router-dom";
import { ErrorAlert, SuccessAlert } from "../../Components/Alerts";
import { Store } from "react-notifications-component";
import { alertMessage } from "../../utils/helpers";

const SearchVehicle = () => {
  const { state } = useContext(Context);
  const forceUpdate = useForceUpdate();
  const history = useHistory();
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const [delId, setDelId] = useState("");
  const [delModal, setDelModal] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");

  const [vData, setVData] = React.useState({
    v_id: location?.state?.data?.v_id ? location?.state?.data?.v_id : '',
    file_no: location?.state?.data?.file_no ? location?.state?.data?.file_no : '',
    v_reg_no: location?.state?.data?.v_reg_no ? location?.state?.data?.v_reg_no : '',
    vs_id: location?.state?.data?.vs_id ? location?.state?.data?.vs_id : '',
    v_purchase_date: location?.state?.data?.v_purchase_date ? location?.state?.data?.v_purchase_date : '',
    v_sale_date: location?.state?.data?.v_sale_date ? location?.state?.data?.v_sale_date : '',
    v_mot: location?.state?.data?.v_mot ? location?.state?.data?.v_mot : '',
    v_info_url: location?.state?.data?.v_info_url ? location?.state?.data?.v_info_url : '',
    v_make_model: location?.state?.data?.v_make_model ? location?.state?.data?.v_make_model : '',
    v_price: location?.state?.data?.v_price ? location?.state?.data?.v_price : '',
    v_prep_expenses: location?.state?.data?.v_prep_expenses ? location?.state?.data?.v_prep_expenses : '',
    v_admin_fee: location?.state?.data?.v_admin_fee ? location?.state?.data?.v_admin_fee : '',
    v_delivery_charges: location?.state?.data?.v_delivery_charges ? location?.state?.data?.v_delivery_charges : '',
    v_total_price: location?.state?.data?.v_total_price ? location?.state?.data?.v_total_price : '',
    v_sale_price: location?.state?.data?.v_sale_price ? location?.state?.data?.v_sale_price : '',
    v_key_slot: location?.state?.data?.v_key_slot ? location?.state?.data?.v_key_slot : '',
    token: state.garageData.token,
    g_id: state.garageData.g_id,
  });

  function doDel() {

    const checkDelRoute = localStorage.getItem('screens') && JSON.parse(localStorage.getItem('screens'));
    if (checkDelRoute) {
      if (!checkDelRoute.includes('DeleteVehicle')) {
        Store.addNotification(
          alertMessage({
            type: 'danger',
            title: 'Error',
            message: "Access Denied"
          })
        );
        return;
      }
    }

    const reqObj = {
      token: state.garageData.token,
      g_id: state.garageData.g_id,
      search_keyword: searchKeyword,
    };
    setLoading(true);
    apiRequest(reqObj, "delete_vehicle")
      .then((data) => {
        doConsole(data);
        setLoading(false);
        if (data.status == "1") {
          if (data.data) {
            setVData(data.data);
          }
        } else {
          setErrorMessage(data.error_msg);
          window.scrollTo(0, 0);
        }
      })
      .catch((err) => {
        doConsole(err);
        setLoading(false);
      });
  }

  useEffect(() => {
    // $(document).ready(function () {
    //     $('#dataTable').DataTable();
    // });
    $(document).ready(function () {
      $("#dataTable").DataTable({
        searching: true,
        paging: true,
        info: false,
        language: {
          emptyTable: "No vehicle found.",
          zeroRecords: "No vehicle matches your search.",
        },
        order: [[0, "desc"]],

      });
    });

    // $("#nav_vehicle").addClass("show");
    // $("#list_vehicle").addClass("active");
  }, [loading]);


  useEffect(() => {
    if (location?.state?.data?.v_reg_no) {
      setSearchKeyword(location.state.data.v_reg_no);
      // forceUpdate();
      doSearch(location.state.data.v_reg_no);
    }
    if (location?.state?.data?.file_no) {
      setSearchKeyword(location.state.data.file_no);
      // forceUpdate();
      doSearch(location.state.data.file_no);
    }
  }, [])

  function doSearch(v) {
    // event.preventDefault();
    var word;
    doConsole(word)
    if (v) word = v;
    else word = searchKeyword
    const reqObj = {
      search_keyword: word,
      g_id: state.garageData.g_id,
      token: state.garageData.token,
    };
    doConsole(reqObj)
    setLoading(true);
    apiRequest(reqObj, "search_vehicle")
      .then((data) => {
        doConsole(data);
        setLoading(false);
        if (data.status == "1") {
          setErrorMessage("");
          setVData(data.data);

          if (!data.data.length) {
            setErrorMessage("No vehicle found");
            setVData(data.data);
          }
        } else {
          setErrorMessage(data.error_msg);
          window.scrollTo(0, 0);
        }
      })
      .catch((err) => {
        doConsole(err);
        setLoading(false);
      });
  }

  return (

    <div className="main-panel">
      <div className="content-wrapper">
        {loading && <Loader />}
        {errorMessage && (
          <ErrorAlert
            onClose={() => setErrorMessage("")}
            errorMessage={errorMessage}
          />
        )}
        {success && (
          <SuccessAlert message={"Hurry! Your vehicle has been saved!"} />
        )}

        <Modal show={delModal} onHide={() => setDelModal(false)}>
          <Modal.Header>
            <Modal.Title
              style={{
                fontSize: 18,
                textAlign: "center",
                alignSelf: "center",
                fontFamily: "sans-serif",
                fontWeight: "bold",
              }}
            >
              Are you sure you want to delete this Vehicle?
            </Modal.Title>
          </Modal.Header>
          <Modal.Footer>
            <Button
              style={{ fontFamily: "sans-serif", fontWeight: "bold" }}
              variant="secondary"
              onClick={() => {
                setDelModal(false);
              }}
            >
              No
            </Button>
            <Button
              style={{ fontFamily: "sans-serif", fontWeight: "bold" }}
              variant="danger"
              onClick={() => {
                setDelModal(false);
                doDel();
              }}
            >
              Yes
            </Button>
          </Modal.Footer>
        </Modal>

        <div className="row">
          <div className="col-lg-12 col-md-12 d-flex flex-column">
            <div className="row flex-grow">
              <h3 className="text-black fw-bold">Search Vehicle</h3>
              <div className="col-12 col-md-12 col-lg-12 grid-margin stretch-card">
                <div className="card card-rounded">
                  <h4 className="text-center pt-4">
                    Please enter File / Reg. No. or Make/Model to search
                    vehicle.
                  </h4>
                  <div className="card-body">
                    <div className="d-sm-flex justify-content-between align-items-start">
                      <div className="row w-100">
                        {/* <form className="forms-sample"> */}
                        <div className="d-none d-md-block d-lg-block form-group col-lg-3 col-md-3 p-2 text-center float-left"></div>
                        <div className="form-group col-12  col-lg-4 col-md-4 col-sm-12 p-2 text-center float-left">
                          <input
                            onChange={(v) =>
                              setSearchKeyword(v.target.value)
                            }
                            value={searchKeyword}
                            type="text"
                            className="form-control mt-1"
                            id="exampleInputName1"
                            placeholder="Enter search keyword"
                          />
                        </div>
                        <div className="form-group col-sm-12 col-lg-2 col-md-2 p-2 text-center float-left align-self-center">
                          <button
                            onClick={() => {
                              if (searchKeyword.length) {
                                doSearch();
                              }
                            }}
                            type="button"
                            className="btn btn-primary btn-md"
                            style={{
                              backgroundColor: acolors.primary,
                              color: "white",
                              fontWeight: "bold",
                              borderWidth: 0,
                            }}
                          >
                            Search
                          </button>
                        </div>
                        <div className="d-none d-md-block d-lg-block form-group col-lg-3 col-md-3 p-2 text-center"></div>
                        {/* </form> */}
                      </div>
                    </div>
                  </div>
                  {!loading && vData.length && (
                    <div className="container table-responsive">
                      <table
                        id="dataTable"
                        className="table table-striped table-bordered dt-responsive"
                        style={{ width: "100%" }}
                      >
                        <thead>
                          <tr >
                            <th className="tRow text-center">File No.</th>
                            <th className="tRow text-center">Reg. No.</th>
                            <th className="tRow text-center">Make/Model</th>
                            <th className="tRow text-center">Delivery Status</th>
                            <th className="tRow text-center">Purchase</th>
                            <th className="tRow text-center">Sale Date/<br />Days in stock{" "}</th>
                            <th className="tRow text-center">Total Price</th>
                            <th className="tRow text-center">Sale Price</th>
                            <th className="tRow text-center">Profit Margin</th>
                            <th className="tRow text-center">Status</th>
                            <th className="tRow text-center">Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          {vData?.map((v) => {
                            return (
                              <tr style={{ backgroundColor: v.vs_id == 5 ? 'rgba(255, 240, 0,0.5)' : v.vs_id == 4 ? 'rgba(255, 0, 0, 0.5)' : v.vs_id == 2 ? 'rgba(0,128,0,0.5)' : 'none' }} >
                                <td className="text-center">
                                  <Link
                                    style={{ extDecorationLine: "none", }}
                                    to={{ pathname: "/viewSingleVehicle", state: { data: v }, }}
                                    title="View Vehicle"
                                    className="text-center"
                                  >
                                    <nobr>{v.file_no}</nobr>
                                  </Link>
                                </td>
                                <td className="text-center">
                                  <Link
                                    style={{ extDecorationLine: "none", }}
                                    to={{ pathname: "/viewSingleVehicle", state: { data: v }, }}
                                    title="View Vehicle"
                                    className="text-center"
                                  >
                                    <nobr>{v.v_reg_no}</nobr>
                                  </Link>
                                </td>
                                <td
                                // className="text-center" 
                                >
                                  <Link
                                    style={{ extDecorationLine: "none", }}
                                    to={{ pathname: "/viewSingleVehicle", state: { data: v }, }}
                                    title="View Vehicle"
                                  // className="text-center"
                                  >
                                    {v.v_make_model}
                                  </Link>
                                </td>
                                <td className="text-center"><nobr>{v.deliver_status}</nobr></td>
                                <td className="text-center"><nobr>{v.v_purchase_date}</nobr></td>
                                <td className="text-center">
                                  <nobr>{v.v_sale_date ? v.v_sale_date : Math.floor((new Date() - new Date(v.v_purchase_date)) / 1000 / 60 / 60 / 24)}</nobr>
                                </td>
                                <td className="text-center"><nobr>{v.v_total_price}</nobr></td>
                                <td className="text-center"><nobr>{v.v_sale_price}</nobr></td>
                                <td className="text-center"><nobr>{v.v_profit}</nobr></td>
                                <td className="text-center"><nobr>{v.veh_status}</nobr></td>
                                <td>
                                  <span className="dtr-data">


                                    <Link
                                      style={{ extDecorationLine: "none", marginLeft: 5 }}
                                      to={{ pathname: "/AddAppointment", state: { data: v }, }}
                                      title="Set Appointment"
                                      className="btn btn-info"
                                    >
                                      <i
                                        className="fas fa-calendar"
                                        color="white"
                                        style={{ height: 30, paddingTop: 7, }}
                                      ></i>

                                    </Link>
                                    <Link
                                      style={{ textDecorationLine: "none", marginLeft: 5 }}
                                      to={{
                                        pathname: "/EditVehicle",
                                        state: { data: v, },
                                      }}
                                      title="Edit Vehicle"
                                      className="btn btn-info"
                                    >
                                      <i
                                        className="fas fa-pencil-alt"
                                        style={{
                                          height: 30,
                                          paddingTop: 7,
                                        }}
                                      ></i>
                                    </Link>
                                    <Button
                                      style={{ marginLeft: 5 }}
                                      onClick={() => {
                                        setDelId(v.v_id);
                                        setDelModal(true);
                                      }}
                                      title="Delete Vehicle"
                                      className="btn btn-danger del_customer text-middle"
                                    >
                                      <i
                                        className="fas fa-trash"
                                        style={{
                                          height: 30,
                                          paddingTop: 7,
                                        }}
                                      ></i>
                                    </Button>
                                  </span>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* content-wrapper ends */}
      {/* partial:partials/_footer.html */}
      <Footer />
      {/* partial */}
    </div>

  );
};

export default SearchVehicle;
