import React from 'react'
// import { AppColors } from '../css/AppColors';

const Notfound = () => {
    return (
        <div style={{ display: 'flex', flex: 1, width: "100%", height: "100%", flexDirection: 'row', textAlign: 'center', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(20,30,47,1)        ', height: "100vh" }} >

            <div style={{ color: 'rgba(255,255,255,0.5)', fontSize: 18, }}>404</div>
            <div style={{ color: 'rgba(255,255,255,0.4)', fontSize: 35, marginRight: 12, marginLeft: 12, textAlign: 'center' }}>|</div>
            <div style={{ color: 'rgba(255,255,255,0.5)', fontSize: 18, }}>NOT FOUND</div>
            {/* <div
                style={{
                    width: "100%",
                    justifyContent: "center",
                    display: "flex",
                    marginTop: 30,
                }}
            >
                <a
                    onClick={() => {
                        localStorage.setItem("garage_data", "");
                        localStorage.setItem("screens", "");
                    }}
                    href="/"
                    className="btn btn-user btn-block text-center col-12 col-xl-6 col-sm-8"
                    style={{ backgroundColor: "#32BBE8", color: "white", fontFamily: "monospace", fontSize: 18, fontWeight: "bold", alignSelf: "center", textAlign: "center", display: "flex", justifyContent: "center", flexDirection: "row", }}
                >
                    <i className="menu-icon mdi mdi-logout" />
                </a>
            </div> */}

        </div>
    )
}

export default Notfound
