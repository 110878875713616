import React, { useContext, useState, useEffect } from "react";

import $ from "jquery";
import Chart from "../vendors/chart.js/Chart.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../node_modules/bootstrap/dist/js/bootstrap.bundle";

// import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap/dist/js/bootstrap.js';

import "../vendors/mdi/css/materialdesignicons.min.css";
import "../vendors/ti-icons/css/themify-icons.css";
import "../vendors/typicons/typicons.css";
import "../vendors/simple-line-icons/css/simple-line-icons.css";
import "../vendors/css/vendor.bundle.base.css";

// import "./js/select.dataTables.min.css"
import "../css/vertical-layout-light/style.css";

import "../js/off-canvas.js";
import "../js/hoverable-collapse.js";
import "../js/template.js";
import "../js/settings.js";
import "../js/todolist.js";

// import { Link } from 'react-router-dom';
import SideBar from "../Components/SideBar";
import TopBar from "../Components/TopBar";
import Footer from "../Components/Footer";

import Target from "../images/target.jpg";
import SettingPanel from "../Components/SettingPanel";

import { useHistory, useLocation } from "react-router";
import { Context } from "../Context/DataContext";
import { apiRequest } from "../utils/apiCalls";
import { doConsole, useForceUpdate } from "../utils/functions";
import Loader from "../Components/Loader";
import { acolors } from "../css/AppColors";
import { ErrorAlert, SuccessAlert } from "../Components/Alerts";

import Alert from 'sweetalert';
import { Button } from "react-bootstrap";
import { Store as notify } from 'react-notifications-component';
import { alertMessage } from "../utils/helpers.js";
import { Link } from "react-router-dom";


// import "../vendors/js/vendor.bundle.base.js"
// import   "../js/hoverable-collapse.js";
// import   "../js/template.js";
// import   "../js/settings.js";
// import   "../js/todolist.js";
// import "../vendors/chart.js/Chart.min.js";
// import "../js/chart.js"

function Dashboard() {


  const { state } = useContext(Context);
  const forceUpdate = useForceUpdate();
  const history = useHistory();
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [searchKeyword, setSearchKeyword] = useState("");

  const [collections, setCollections] = useState([]);
  const [monthlySales, setMonthlySales] = useState([]);
  const [saleDataSummary, setSaleDataSummary] = useState([]);

  const [maxSale, setmaxSale] = useState();
  const [minSale, setminSale] = useState();
  const [maxProfit, setmaxProfit] = useState();
  const [minProfit, setminProfit] = useState();

  const [dataPurchase, setDataPurchase] = useState([]);

  const [maxPurchase, setMaxPurchase] = useState();
  const [minPurchase, setMinPurchase] = useState();
  const [maxAmount, setMaxAmount] = useState();
  const [minAmount, setMinAmount] = useState();
  const [totalPurchases, setTotalPurchases] = useState();
  const [totalAmount, setTotalAmount] = useState();

  const [isAdmin, setIsAdmin] = useState(true);


  const [avg_car_target, setAvg_car_target] = useState("");
  const [avg_profit_target, setAvg_profit_target] = useState("");
  const [avg_car_progress, setAvg_car_progress] = useState("");
  const [avg_profit_progress, setAvg_profit_progress] = useState("");

  const [today_sold_vehicles, setToday_sold_vehicles ] = useState([]);
  const [vehicles_added_today, setVehicles_added_today ] = useState([]);

  

  const labels = ["Target", "Progress"];

  var carSaleByNumber = {
    labels,
    datasets: [
      {
        label: "",
        data: [avg_car_target, avg_car_progress],
        borderColor: ["#587ce4", "#ff0000"],
        borderWidth: 2,
        fill: false,
      },
    ],
  };

  var carSaleByVolume = {
    labels,
    datasets: [
      {
        label: [[""], [""]],
        data: [avg_profit_target, avg_profit_progress],
        borderColor: ["#587ce4", "#ff0000"],
        borderWidth: 2,
        fill: false,
      },
    ],
  };



  var options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    elements: {
      point: {
        radius: 2,
      },
    },
  };


  function get_dashboard() {
    setLoading(true);
    const reqObj = {
      token: state?.garageData?.token,
      g_id: state?.garageData?.g_id,
      // date : "2020-07-21"
    };
    apiRequest(reqObj, "get_dashboard")
      .then((data) => {
        doConsole(data);
        setLoading(false);
        if (data?.status == "1") {
          doConsole(data?.data?.today_sold_vehicles);
          setData(data?.data);
          setToday_sold_vehicles(data?.data?.today_sold_vehicles);
          setVehicles_added_today(data?.data?.vehicles_added_today);
          console.log(data)
          forceUpdate();
        } else {
          setErrorMessage(data?.error_msg);
          forceUpdate();
          window.scrollTo(0, 0);
        }
      })
      .catch((err) => {
        doConsole(err);
        setLoading(false);
      });
  }

  function get_upcoming_collections() {
    setLoading(true);
    const reqObj = {
      token: state.garageData.token,
      g_id: state.garageData.g_id,
    };
    apiRequest(reqObj, "get_upcoming_collections")
      .then((data) => {
        doConsole(data);
        setLoading(false);
        if (data?.status == "1") {
          setCollections(data.data)
          forceUpdate();
        } else {
          setErrorMessage(data.error_msg);
          forceUpdate();
          window.scrollTo(0, 0);
        }
      })
      .catch((err) => {
        doConsole(err);
        setLoading(false);
      });
  }

  function get_monthly_sales() {
    setLoading(true);
    const reqObj = {
      token: state?.garageData?.token,
      g_id: state?.garageData?.g_id,
    };
    apiRequest(reqObj, "get_monthly_sales")
      .then((data) => {
        doConsole(data);
        setLoading(false);
        if (data?.status == "1") {

          let maxSale = 0;
          let minSale = 1000;
          let minProfit = 1000000;
          let maxProfit = 0;

          let maxPurchase = 0;
          let minPurchase = 1000;
          let maxAmount = 0;
          let minAmount = 100000;
          let totalPurchases = 0;
          let totalAmount = 0;
          for (let key in data?.data) {
            maxSale = Math.max(maxSale, data?.data[key]?.no_of_sales)
            minSale = Math.min(minSale, data?.data[key]?.no_of_sales)
            maxProfit = Math.max(maxProfit, data?.data[key]?.total_profit)
            minProfit = Math.min(minProfit, data?.data[key]?.total_profit)
          }

          setmaxSale(maxSale);
          setminSale(minSale);
          setmaxProfit(maxProfit);
          setminProfit(minProfit);

          setMonthlySales(data?.data)
          setSaleDataSummary(data?.data_summary[0])

          for (let key in data.data_purchase) {
            maxPurchase = Math.max(maxPurchase, data?.data_purchase[key]?.no_of_purchases)
            minPurchase = Math.min(minPurchase, data?.data_purchase[key]?.no_of_purchases)
            maxAmount = Math.max(maxAmount, data?.data_purchase[key]?.total_price)
            minAmount = Math.min(minAmount, data?.data_purchase[key]?.total_price)

            totalPurchases = totalPurchases + data?.data_purchase[key]?.no_of_purchases;
            totalAmount = totalAmount + data?.data_purchase[key]?.total_price;
          }

          setMaxPurchase(maxPurchase);
          setMinPurchase(minPurchase);
          setMaxAmount(maxAmount);
          setMinAmount(minAmount);
          setTotalPurchases(totalPurchases);
          setTotalAmount(totalAmount);

          setDataPurchase(data?.data_purchase)

          setAvg_car_target(data?.chart_data?.avg_car_target)
          setAvg_profit_target(data?.chart_data?.avg_profit_target)
          setAvg_car_progress(data?.chart_data?.avg_car_progress)
          setAvg_profit_progress(data?.chart_data?.avg_profit_progress)

          forceUpdate();
        } else {
          setErrorMessage(data?.error_msg);
          forceUpdate();
          window.scrollTo(0, 0);
        }
      })
      .catch((err) => {
        doConsole(err);
        setLoading(false);
      });
  }

  function doDeliverVehicle(v_id){

    setLoading(true);
    const reqObj = {
      token: state.garageData.token,
      g_id: state.garageData.g_id,
      v_id : v_id
    };
    apiRequest(reqObj, "do_deliver_vehicle")
      .then((data) => {
        doConsole(data);
        setLoading(false);
        if (data?.status == "1" && data?.data )  {
          setCollections(data?.data)
          notify.addNotification(
            alertMessage({
                type: 'success',
                title: 'Success',
                message: "Delivered"
            })
        );
          forceUpdate();
        } else {
          setErrorMessage(data.error_msg);
          forceUpdate();
          window.scrollTo(0, 0);
        }
      })
      .catch((err) => {
        doConsole(err);
        setLoading(false);
      });

  }


  useEffect(() => {

    $("#carSaleByNumber");
    {
      var multiLineCanvas = $("#carSaleByNumber").get(0).getContext("2d");
      var lineChart = new Chart(multiLineCanvas, {
        type: "bar",
        data: carSaleByNumber,
        options: options,
      });
    }

    $("#carSaleByVolume");
    {
      var multiLineCanvas = $("#carSaleByVolume").get(0).getContext("2d");
      var lineChart = new Chart(multiLineCanvas, {
        type: "bar",
        data: carSaleByVolume,
        options: options,
      });
    }


    return () => { };
  }, [avg_car_target, avg_profit_target, avg_car_progress, avg_profit_progress,]);


  useEffect(() => {

    const checkIsAdmin = JSON.parse(localStorage.getItem('garage_data'))
    if (checkIsAdmin?.g_email) {
      get_dashboard();
    get_upcoming_collections();
    get_monthly_sales();
    
      setIsAdmin(true);
      forceUpdate();
    }
    else {
      setIsAdmin(false);
      forceUpdate();
    }


    
    // doConsole(dat  a);
  }, []);

  if (isAdmin == null) return null;
  if (isAdmin == false) return (<div></div>

  )


  else return (
    <div className="main-panel">
      <div className="content-wrapper">
        {loading && <Loader />}
        {errorMessage && (
          <ErrorAlert
            onClose={() => setErrorMessage("")}
            errorMessage={errorMessage}
          />
        )}
        {success && (
          <SuccessAlert message={"Hurry! Your vehicle has been updated!"} />
        )}



        <div className="row">
          <div className="col-sm-12">
            <div className="home-tab">
              <div className="">
                <div
                  className=""
                  id="overview"
                  role="tabpanel"
                  aria-labelledby="overview"
                >
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="statistics-details d-flex  justify-content-between">
                        <div>
                          <p className="statistics-title">Vehicles in stock<sup>*</sup></p>
                          <h3 className="rate-percentage">
                            {data?.veh_in_stock}
                          </h3>


                        </div>
                        <div>
                          <p className="statistics-title">Stock value<sup>*</sup></p>
                          <h3 className="rate-percentage">
                            £{data?.stock_value}
                          </h3>

                        </div>

                        <div className="d-none d-md-block">
                          <p className="statistics-title">Open Tasks</p>
                          <h3 className="rate-percentage">
                            {data?.open_tasks}
                          </h3>

                        </div>

                        <div className="col  col-lg-4 mt-1">
                          <div className="row">
                            <div className="col-md-6 col-lg-12 grid-margin stretch-card">
                              <div className="card bg-primary card-rounded">
                                <div className="card-body pb-0 text-center">

                                  <h4 className="card-title card-title-dash text-white mb-4">
                                    Search Vehicle
                                  </h4>
                                  <div className="row">
                                    <div className="col-sm-12">
                                      <form>
                                        <div className="text-center">
                                          <input
                                            onChange={(v) => {
                                              setSearchKeyword(v.target.value)
                                            }}
                                            value={searchKeyword}
                                            type="text"
                                            name="srch_keyword"
                                            className="form-control form-control-sm"
                                          ></input>
                                        </div>
                                        <div className="pt-3 text-center">
                                          <button
                                            onClick={() => {
                                              history.push('searchVehicle', {
                                                data: {
                                                  file_no: searchKeyword
                                                }
                                              })
                                            }}
                                            className="btn btn-primary text-white">
                                            Search
                                          </button>
                                        </div>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>


                        <p style={{ position: 'absolute', color: 'grey', marginTop: 50, fontSize: 10 }} ><sup>*</sup>Including in transit</p>
                      </div>


                    </div>

                  </div>

                  {/* Averages Charts */}
                  <div className="row " >

                    <div className=" col col-lg-6 col-xl-6  col-md-12 col-sm-12 " style={{ borderWidth: 1, borderColor: 'grey' }} >
                      <h3 className="rate-percentage text-center "><strong> Car Sales &amp; Profit</strong></h3>

                      <div className="row mt-2 ">
                        <div className="col col-5 " >
                          <div className="row " style={{ backgroundColor: 'yellow' }} >
                            <div className="col col-6 text-left " ><strong> Cars</strong></div>
                            <div className="col col-6 text-right "><strong> {saleDataSummary.no_of_sales}</strong></div>
                          </div>
                          <div className="row " >
                            <div className="col col-7 text-left " style={{ marginTop: 3, color: 'grey', fontSize: 13 }} > <strong> Average Sold</strong></div>
                            <div className="col col-5 text-right " style={{ marginTop: 3, color: 'grey', fontSize: 13 }} ><strong> {saleDataSummary.avg_car_sold}</strong></div>
                          </div>
                          <div className="row " >
                            <div className="col col-7 text-left " style={{ marginTop: 3, color: 'grey', fontSize: 13 }} > <strong> Max Sold a day</strong></div>
                            <div className="col col-5 text-right " style={{ marginTop: 3, color: 'grey', fontSize: 13 }} ><strong> {maxSale}</strong></div>
                          </div>
                          <div className="row " >
                            <div className="col col-7 text-left " style={{ marginTop: 3, color: 'grey', fontSize: 13 }} > <strong> Min Sold a day</strong></div>
                            <div className="col col-5 text-right " style={{ marginTop: 3, color: 'grey', fontSize: 13 }} ><strong> {minSale}</strong></div>
                          </div>
                          <div className="row " >
                            <div className="col col-6 text-left " style={{ marginTop: 3, fontSize: 12 }} > <strong> Average Profit</strong></div>
                            <div className="col col-6 text-right " style={{ marginTop: 3, fontSize: 12 }} ><strong>£{Math.round(saleDataSummary.profit / saleDataSummary.no_of_sales)}</strong></div>
                          </div>

                        </div>



                        <div className="col col-2"></div>

                        <div className="col col-5 " >
                          <div className="row " style={{ backgroundColor: 'yellow' }} >
                            <div className="col col-6 text-left " > <strong> Profit</strong></div>
                            <div className="col col-6 text-right " ><strong> £{saleDataSummary.profit}</strong></div>
                          </div>
                          <div className="row " >
                            <div className="col col-7 text-left " style={{ marginTop: 3, color: 'grey', fontSize: 13 }} > <strong>Avg Profit a day</strong></div>
                            <div className="col col-5 text-right " style={{ marginTop: 3, color: 'grey', fontSize: 13 }} ><strong> </strong>£{saleDataSummary.avg_profit}</div>
                          </div>
                          <div className="row " >
                            <div className="col col-7 text-left " style={{ marginTop: 3, color: 'grey', fontSize: 13 }} > <strong> Max Profit a day</strong></div>
                            <div className="col col-5 text-right " style={{ marginTop: 3, color: 'grey', fontSize: 13 }} ><strong> £{maxProfit}</strong></div>
                          </div>
                          <div className="row " >
                            <div className="col col-7 text-left " style={{ marginTop: 3, color: 'grey', fontSize: 13 }} > <strong> Min Profit a day</strong></div>
                            <div className="col col-5 text-right " style={{ marginTop: 3, color: 'grey', fontSize: 13 }} ><strong> £{minProfit}</strong></div>
                          </div>
                          <div className="row " >
                            <div className="col col-6 text-left " style={{ marginTop: 3, fontSize: 12 }} > <strong> Total Revenue</strong></div>
                            <div className="col col-6 text-right " style={{ marginTop: 3, fontSize: 12 }} ><strong> £{saleDataSummary.revenue}</strong></div>
                          </div>

                        </div>

                        {/* <div className="col col-6 " ></div> */}
                      </div>



                      <div className="row mt-2 col col-12 ">
                         
                             <div className="col col-lg-8 col-md-6 "  >
        
                             <h3 className="rate-percentage "><strong>Upcoming Collections</strong></h3>
                               
        
                               {
                                 collections?.map((v) => {
                                   // if(v.day == 'none' ) return null
                                   return (
                                    <div className="row" >
                                      <div
                                        style={{ backgroundColor: v.day == 'today' ? 'red' : v.day == 'tommorrow' ? 'orange' : 'grey', paddingBlock: 10, paddingInline: 10, borderRadius: 10, color: 'white', fontWeight: 'bold', marginTop: 10 }}
                                        className="col-12 col-md-12 col-lg-12 " >
                                        {v.collection_date} {v.collection_time},  {v.v_make_model} [{v.v_reg_no}] <br />{v.cust_phone ? v.cust_name + ",(" + v.cust_phone + "),(" + v.cust_email + ")" : null}
                                        <Button 
                                          onClick={()=>{
                                            Alert({
                                              title: "Are you sure vehicle is delivered?",
                                              icon: "warning",
                                              buttons: true,
                                              dangerMode: true,
                                              buttons: ["Cancel", true],

                                            })
                                              .then((value) => {
                                                if (value == true) {
                                                  doDeliverVehicle(v.v_id)
                                                  // doCancelAppoint(v.app_id)
                                                }
                                              });
                                          
                                          }}
                                          style={{borderWidth:0,color:'white',marginTop:10,}} className="text-right float-right ">Delivered</Button>
                                      </div>
                                     
                                     </div>
                                   )
                                 })
                               }
        
                             </div>
                             </div>
        

                      {/* <div className="table-responsive mt-1 ">
                        <table
                          id="dataTable"
                          className="table table-striped table-bordered dt-responsive"
                          style={{ width: "100%" }}
                        >
                          <thead>
                            <tr>
                              <th className="tRow">Date</th>
                              <th className="tRow">No of sales</th>
                              <th className="tRow">Avg Profit</th>
                              <th className="tRow">Total Profit</th>
                              <th className="tRow">Revenue</th>
                            </tr>
                          </thead>
                          <tbody>
                            {monthlySales.map((v, i) => {
                              return (
                                <tr key={i}>
                                  <td><nobr>{v.v_sale_date}</nobr></td>
                                  <td><nobr>{v.no_of_sales}</nobr></td>
                                  <td><nobr>{v.avg_profit}</nobr></td>
                                  <td><nobr>{v.total_profit}</nobr></td>
                                  <td><nobr>{v.revenue}</nobr></td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div> */}
                    </div>

                    <div className=" col col-lg-6 col-xl-6  col-md-12 col-sm-12 " style={{ borderWidth: 1, borderColor: 'grey' }} >
                    <div className="row" >
            
                      <div className="col-6 col-md-6 col-lg-6 col-sm-12 mt-3 mb-3 "  >
                        <div className="card" >
                          <div className="card-body" >
                            <h4 className="card-title">
                              No of cars sale
                            </h4>
                            <canvas  id="carSaleByNumber"></canvas>
                          </div>
                        </div>
                      </div>

                      <div className="col-6 col-md-6 col-lg-6 col-sm-12 mt-3 mb-3 ">
                        <div className="card">
                          <div className="card-body">
                            <h4 className="card-title">
                              Average Profit per day
                            </h4>
                            <canvas id="carSaleByVolume"></canvas>
                          </div>
                        </div>
                      </div>
            
                  </div>
            </div>

                      <div className="col-12 col-md-21 col-lg-21 col-sm-12 mt-3 mb-3 "  >
                        <div className="card" >
                          <div className="card-body" >
                            <h4 className="card-title">
                              Car Sale Today ({today_sold_vehicles?.length})
                            </h4>
                            {
                              today_sold_vehicles?.length &&
                                
                                  <table
                                id="dataTable"
                                className="table table-striped table-bordered dt-responsive dataTable dtr-inline collapsed"
                                style={{ width: "100%" }}
                              >
                                <thead>
                                  <tr>
                                    <th className="tRow text-center">Reg. No.</th>
                                    <th className="tRow text-center">Make/Model</th>
                                    
                                    <th className="tRow text-center">Total Price</th>
                                    <th className="tRow text-center">Sale Price</th>
                                    <th className="tRow text-center">Profit Margin</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {today_sold_vehicles?.map((v) => {
                                    return (
                                      <tr style={{ backgroundColor: v.vs_id == 5 ? 'rgba(255, 240, 0,0.5)' : v.vs_id == 4 ? 'rgba(255, 0, 0, 0.5)' : v.vs_id == 2 ? 'rgba(0,128,0,0.5)' : 'none' }} >
                                        
                                        <td className="text-center">
                                          <Link
                                            style={{ extDecorationLine: "none", color: 'black' }}
                                            to={{ pathname: "/viewSingleVehicle", state: { data: v }, }}
                                            title="View Vehicle"
                                            className="text-center"
                                          >
                                            <nobr>{v.v_reg_no}</nobr>
                                          </Link>
                                        </td>
                                        <td
                                        >
                                          <Link
                                            style={{ extDecorationLine: "none", color: 'black' }}
                                            to={{ pathname: "/viewSingleVehicle", state: { data: v }, }}
                                            title="View Vehicle"
                                          >
                                            {v.v_make_model}
                                          </Link>
                                        </td>
                                        <td className="text-center"><nobr>£{v.v_total_price}</nobr></td>
                                        <td className="text-center"><nobr>£{v.v_sale_price}</nobr></td>
                                        <td className="text-center"><nobr>£{v.v_profit}</nobr></td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                              }
                          </div>
                      </div>

                   
            
                  </div>


                  <div className="col-12 col-md-21 col-lg-21 col-sm-12 mt-3 mb-3 "  >
                        <div className="card" >
                          <div className="card-body" >
                            <h4 className="card-title">
                              Car Added Today ({vehicles_added_today?.length})
                            </h4>
                            {
                              vehicles_added_today?.length &&
                                
                              <table
                              id="dataTable"
                              className="table table-striped table-bordered dt-responsive dataTable dtr-inline collapsed"
                              style={{ width: "100%" }}
                            >
                              <thead>
                                <tr >
                                  <th className="tRow text-center">File No.</th>
                                  <th className="tRow text-center">Reg. No.</th>
                                  <th className="tRow text-center">Make/Model</th>
                                  
                                  <th className="tRow text-center">Total Price</th>
                                  <th className="tRow text-center">Sale Price</th>
                                  <th className="tRow text-center">Profit Margin</th>
                                  
                                  
                                </tr>
                              </thead>
                              <tbody>
                                {vehicles_added_today?.map((v) => {
                                  return (
                                    <tr style={{ backgroundColor: v.vs_id == 5 ? 'rgba(255, 240, 0,0.5)' : v.vs_id == 4 ? 'rgba(255, 0, 0, 0.5)' : v.vs_id == 2 ? 'rgba(0,128,0,0.5)' : 'none' }} >
                                      <td className="text-center">
                                        <Link
                                          style={{ extDecorationLine: "none", color: 'black' }}
                                          to={{ pathname: "/viewSingleVehicle", state: { data: v }, }}
                                          title="View Vehicle"
                                          className="text-center"
                                        >
                                          <nobr>{v.file_no}</nobr>
                                        </Link>
                                      </td>
                                      <td className="text-center">
                                        <Link
                                          style={{ extDecorationLine: "none", color: 'black' }}
                                          to={{ pathname: "/viewSingleVehicle", state: { data: v }, }}
                                          title="View Vehicle"
                                          className="text-center"
                                        >
                                          <nobr>{v.v_reg_no}</nobr>
                                        </Link>
                                      </td>
                                      <td
                                      // className="text-center" 
                                      >
                                        <Link
                                          style={{ extDecorationLine: "none", color: 'black' }}
                                          to={{ pathname: "/viewSingleVehicle", state: { data: v }, }}
                                          title="View Vehicle"
                                        // className="text-center"
                                        >
                                          {v.v_make_model}
                                        </Link>
                                      </td>
                                      <td className="text-center"><nobr>{v.v_purchase_date}</nobr></td>
                                      
                                      <td className="text-center"><nobr>£{v.v_total_price}</nobr></td>
                                      <td className="text-center"><nobr>£{v.v_profit}</nobr></td>
                                      
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                              }
                          </div>
                      </div>

                   
            
                  </div>
                  
            
            


                  </div>

                  {/*  Car Purchases */}

                
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* content-wrapper ends */}
      {/* partial:partials/_footer.html */}
      <Footer />
      {/* partial */}
    </div>
  );
}

export default Dashboard;


// {/* <div className="row">
// <div className="col-lg-4 d-flex flex-column">
//   <div className="row flex-grow">
//     <div className="col-12 grid-margin stretch-card">
//       <div className="card card-rounded">
//         <div className="card-body">
//           <div className="row">
//             <div className="col-lg-12">
//               <div className="d-flex justify-content-between align-items-center">
//                 <h4 className="card-title card-title-dash">
//                   Todo list
//                 </h4>
//                 <div className="add-items d-flex mb-0">
//                   {/* <input type="text" className="form-control todo-list-input" placeholder="What do you need to do today?"> */}
//                   <a href="/assignTaks">
//                     <button className="add btn btn-icons btn-rounded btn-primary todo-list-add-btn text-white me-0 pl-12p">
//                       <i className="mdi mdi-plus" />
//                     </button>
//                   </a>
//                 </div>
//               </div>
//               <div className="list-wrapper">
//                 <ul className="todo-list todo-list-rounded">
//                   <li className="d-block">
//                     <div className="form-check w-100">
//                       <label className="form-check-label">
//                         <input
//                           className="checkbox"
//                           type="checkbox"
//                         />
//                         AAA3F32 - Wash, Tire Change, Engine
//                         oil change, seat cover change, MOT
//                       </label>
//                       <div className="d-flex mt-2">
//                         <div className="badge badge-opacity-warning me-3">
//                           2021-12-18
//                         </div>
//                         <div className="badge badge-opacity-warning me-3">
//                           Isaac
//                         </div>
//                         <div className="badge badge-opacity-warning me-3">
//                           Pending
//                         </div>
//                         <i className="mdi mdi-flag ms-2 flag-color" />
//                       </div>
//                     </div>
//                   </li>
//                   <li className="d-block">
//                     <div className="form-check w-100">
//                       <label className="form-check-label">
//                         <input
//                           className="checkbox"
//                           type="checkbox"
//                         />
//                         ABC3F32 - Wash, Tire Change, Engine
//                         oil change, seat cover change, MOT
//                       </label>
//                       <div className="d-flex mt-2">
//                         <div className="badge badge-opacity-warning me-3">
//                           2021-12-10
//                         </div>
//                         <div className="badge badge-opacity-warning me-3">
//                           Isaac
//                         </div>
//                         <div className="badge badge-opacity-success me-3">
//                           Done
//                         </div>
//                       </div>
//                     </div>
//                   </li>
//                   <li className="d-block">
//                     <div className="form-check w-100">
//                       <label className="form-check-label">
//                         <input
//                           className="checkbox"
//                           type="checkbox"
//                         />{" "}
//                         Lorem Ipsum is simply dummy text of
//                         the printing{" "}
//                         <i className="input-helper rounded" />
//                       </label>
//                       <div className="d-flex mt-2">
//                         <div className="ps-4 text-small me-3">
//                           2021-12-01
//                         </div>
//                         <div className="badge badge-opacity-danger me-3">
//                           Overdue
//                         </div>
//                       </div>
//                     </div>
//                   </li>
//                   <li className="d-block">
//                     <div className="form-check w-100">
//                       <label className="form-check-label">
//                         <input
//                           className="checkbox"
//                           type="checkbox"
//                         />
//                         AAA3F32 - Wash, Tire Change, Engine
//                         oil change, seat cover change, MOT
//                       </label>
//                       <div className="d-flex mt-2">
//                         <div className="badge badge-opacity-warning me-3">
//                           2021-12-18
//                         </div>
//                         <div className="badge badge-opacity-warning me-3">
//                           Isaac
//                         </div>
//                         <div className="badge badge-opacity-warning me-3">
//                           Pending
//                         </div>
//                         <i className="mdi mdi-flag ms-2 flag-color" />
//                       </div>
//                     </div>
//                   </li>
//                   <li className="d-block">
//                     <div className="form-check w-100">
//                       <label className="form-check-label">
//                         <input
//                           className="checkbox"
//                           type="checkbox"
//                         />
//                         ABC3F32 - Wash, Tire Change, Engine
//                         oil change, seat cover change, MOT
//                       </label>
//                       <div className="d-flex mt-2">
//                         <div className="badge badge-opacity-warning me-3">
//                           2021-12-10
//                         </div>
//                         <div className="badge badge-opacity-warning me-3">
//                           Isaac
//                         </div>
//                         <div className="badge badge-opacity-success me-3">
//                           Done
//                         </div>
//                       </div>
//                     </div>
//                   </li>
//                   <li className="d-block">
//                     <div className="form-check w-100">
//                       <label className="form-check-label">
//                         <input
//                           className="checkbox"
//                           type="checkbox"
//                         />{" "}
//                         Lorem Ipsum is simply dummy text of
//                         the printing{" "}
//                         <i className="input-helper rounded" />
//                       </label>
//                       <div className="d-flex mt-2">
//                         <div className="ps-4 text-small me-3">
//                           2021-12-01
//                         </div>
//                         <div className="badge badge-opacity-danger me-3">
//                           Overdue
//                         </div>
//                       </div>
//                     </div>
//                   </li>
//                 </ul>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   </div>
// </div>
// <div className="col-lg-8 d-flex flex-column">
//   <div className="row flex-grow">
//     <div className="col-12 grid-margin stretch-card">
//       <div className="card card-rounded">
//         <div className="card-body">
//           <div className="d-sm-flex justify-content-between align-items-start">
//             <div>
//               <h4 className="card-title card-title-dash">
//                 Targets
//               </h4>
//               <p className="card-subtitle card-subtitle-dash">
//                 Lorem ipsum dolor sit amet consectetur
//                 adipisicing elit
//               </p>
//             </div>
//             <div>
//               <div className="dropdown">
//                 <select
//                   className="form-control"
//                   id="target_duration"
//                 >
//                   <option>This Month</option>
//                   <option>Last Month</option>
//                   <option>Past 12 Months</option>
//                 </select>
//               </div>
//             </div>
//           </div>
//           <div className="d-sm-flex align-items-center mt-1 justify-content-between">
//             <div className="d-sm-flex align-items-center mt-4 justify-content-between">
//               <h2 className="me-2 fw-bold">$36,2531.00</h2>
//               <h4 className="me-2">USD</h4>
//               <h4 className="text-success">(+1.37%)</h4>
//             </div>

//             <div className="me-3">
//               <div id="marketing-overview-legend" />
//             </div>
//           </div>
//           <div className="chartjs-bar-wrapper mt-3">
//             <canvas id="marketingOverview" />
//             <img
//               className="img-fluid"
//               src={Target}
//               alt="Targets"
//             />
//           </div>
//         </div>
//       </div>
//     </div>
//   </div>
// </div>
// </div> */}






//  3 march


{/* <div className="col-lg-8 col-md-12 d-flex flex-column">
                      <div className="row flex-grow">
                        <div className="col-12 col-md-12 col-lg-12 grid-margin stretch-card">
                          <div className="card card-rounded">
                            <div className="card-body">
                              <div className="d-sm-flex justify-content-between align-items-start">
                                <div className="row w-100">
                                  <div className="col-12">
                                    <h4 className="card-title card-title-dash">
                                      Add Expense
                                    </h4>
                                  </div>
                                  <form className="forms-sample">
                                    <div className="form-group col-12 col-lg-4 col-md-4 col-sm-12 float-left p-2">
                                      <label for="exampleSelectGender">
                                        Expense Type
                                      </label>
                                      <select
                                        className="form-control"
                                        id="exampleSelectGender"
                                      >
                                        <option>Fixed Expenses</option>
                                        <option>Wages</option>
                                        <option>Variable Exp.</option>
                                        <option>Vehicle Specific</option>
                                      </select>
                                    </div>
                                    <div className="form-group col-12  col-lg-2 col-md-2 col-sm-12 float-left p-2">
                                      <label for="exampleInputName1">
                                        File No.
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="exampleInputName1"
                                        placeholder="File No."
                                      />
                                    </div>

                                    <div
                                      className="form-group"
                                      className="form-group col-12 col-sm-12 col-md-6 col-lg-6 float-left p-2"
                                    >
                                      <label for="exampleInputName1">
                                        Description
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="exampleInputName1"
                                        placeholder="Description"
                                      />
                                    </div>
                                    <div
                                      className="form-group"
                                      className="form-group  col-12 col-sm-12 col-md-12 col-lg-12 p-2 text-center"
                                    >
                                      <label
                                        for="exampleInputName1"
                                        style={{ fontSize: 35 }}
                                      >
                                        <input
                                          type="number"
                                          className="form-control text-center p-4"
                                          style={{ fontSize: 35 }}
                                          id="exampleInputName1"
                                          placeholder="£"
                                        />
                                      </label>
                                    </div>
                                    <div className="col-12 text-center">
                                      <button
                                        type="submit"
                                        className="btn btn-primary me-2"
                                        style={{
                                          color: "white",
                                          backgroundColor: acolors.primary,
                                        }}
                                      >
                                        Save
                                      </button>
                                      <button className="btn btn-light">
                                        Cancel
                                      </button>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}








{/* <div className="col-md-6 col-lg-12 grid-margin stretch-card">
                              <div className="card card-rounded">
                                <div className="card-body">
                                  <div className="row">
                                    <div className="col-sm-6">
                                      <div className="d-flex justify-content-between align-items-center mb-2 mb-sm-0">
                                        <div className="circle-progress-width">
                                          <div
                                            id="totalVisitors"
                                            className="progressbar-js-circle pr-2"
                                          />
                                        </div>
                                        <div>
                                          <p className="text-small mb-2">
                                            Total Visitors
                                          </p>
                                          <h4 className="mb-0 fw-bold">26.80%</h4>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-sm-6">
                                      <div className="d-flex justify-content-between align-items-center">
                                        <div className="circle-progress-width">
                                          <div
                                            id="visitperday"
                                            className="progressbar-js-circle pr-2"
                                          />
                                        </div>
                                        <div>
                                          <p className="text-small mb-2">
                                            Visits per day
                                          </p>
                                          <h4 className="mb-0 fw-bold">9065</h4>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div> */}




                          //   <div className=" col col-lg-6 col-xl-6  col-md-12 col-sm-12 "  >
                          //   <h3 className="rate-percentage text-center"><strong> Car Purchases</strong></h3>
        
                          //   <div className="row mt-2 ">
                          //     <div className="col col-5 " >
                          //       <div className="row " style={{ backgroundColor: 'yellow' }} >
                          //         <div className="col col-6 text-left " ><strong> Cars</strong></div>
                          //         {/* <div className="col col-6 text-right "><strong> {saleDataSummary.no_of_sales}</strong></div> */}
                          //       </div>
                          //       <div className="row " >
                          //         <div className="col col-7 text-left " style={{ marginTop: 3, color: 'grey', fontSize: 13 }} > <strong> Purchase</strong></div>
                          //         <div className="col col-5 text-right " style={{ marginTop: 3, color: 'grey', fontSize: 13 }} ><strong> {totalPurchases}</strong></div>
                          //       </div>
                          //       <div className="row " >
                          //         <div className="col col-7 text-left " style={{ marginTop: 3, color: 'grey', fontSize: 13 }} > <strong> Max Purc. A day</strong></div>
                          //         <div className="col col-5 text-right " style={{ marginTop: 3, color: 'grey', fontSize: 13 }} ><strong> {maxPurchase}</strong></div>
                          //       </div>
                          //       <div className="row " >
                          //         <div className="col col-7 text-left " style={{ marginTop: 3, color: 'grey', fontSize: 13 }} > <strong> Min Purch. A day</strong></div>
                          //         <div className="col col-5 text-right " style={{ marginTop: 3, color: 'grey', fontSize: 13 }} ><strong> {minPurchase}</strong></div>
                          //       </div>
        
                          //     </div>
        
        
        
                          //     <div className="col col-1"></div>
        
                          //     <div className="col col-6 " >
                          //       <div className="row " style={{ backgroundColor: 'yellow' }} >
                          //         <div className="col col-6 text-left " > <strong> Purchased</strong></div>
                          //         {/* <div className="col col-6 text-right " ><strong> £{saleDataSummary.profit}</strong></div> */}
                          //       </div>
                          //       <div className="row " >
                          //         <div className="col col-7 text-left " style={{ marginTop: 3, color: 'grey', fontSize: 13 }} > <strong> Max Amount a Day</strong></div>
                          //         <div className="col col-5 text-right " style={{ marginTop: 3, color: 'grey', fontSize: 13 }} ><strong> </strong>£{maxAmount}</div>
                          //       </div>
                          //       <div className="row " >
                          //         <div className="col col-7 text-left " style={{ marginTop: 3, color: 'grey', fontSize: 13 }} > <strong> Min Amount a Day</strong></div>
                          //         <div className="col col-5 text-right " style={{ marginTop: 3, color: 'grey', fontSize: 13 }} ><strong> £{minAmount}</strong></div>
                          //       </div>
                          //       <div className="row " >
                          //         <div className="col col-7 text-left " style={{ marginTop: 3, color: 'grey', fontSize: 13 }} > <strong> Total Amount</strong></div>
                          //         <div className="col col-5 text-right " style={{ marginTop: 3, color: 'grey', fontSize: 13 }} ><strong> £{totalAmount}</strong></div>
                          //       </div>
                          //       <br />
        
                          //     </div>
        
                          //     <div className="col col-6 " ></div>
                          //   </div>
        
        
                          //   <div className="table-responsive mt-1 ">
                          //     {/* table className="table table-bordered dt-responsive" id="dataTable" width="100%" cellspacing="0" */}
                          //     <table
                          //       id="dataTable"
                          //       className="table table-striped table-bordered dt-responsive"
                          //       style={{ width: "100%" }}
                          //     >
                          //       <thead>
                          //         <tr>
                          //           <th className="tRow">Date</th>
                          //           <th className="tRow">No of purchase</th>
                          //           <th className="tRow">Avg Amount</th>
                          //           <th className="tRow">Total Amount</th>
                          //         </tr>
                          //       </thead>
                          //       <tbody>
                          //         {dataPurchase.map((v, i) => {
                          //           return (
                          //             <tr key={i}>
                          //               <td><nobr>{v.v_purchase_date}</nobr></td>
                          //               <td><nobr>{v.no_of_purchases}</nobr></td>
                          //               <td><nobr>{v.avg_price}</nobr></td>
                          //               <td><nobr>{v.total_price}</nobr></td>
                          //             </tr>
                          //           );
                          //         })}
                          //       </tbody>
                          //     </table>
                          //   </div>
        
        
        
        
        
                          // </div>
        
        
        
        
                          // {/* Averages Charts */}
        
                          // <div className="row mt-2 col col-12 ">
                          //   {/* <div> */}
                          //   <div className="col col-lg-8 col-md-6 "  >
        
                          //     <h2 style={{ marginBottom: 10 }} className="card-title-dash">Upcoming Collections</h2>
        
                          //     {
                          //       collections?.map((v) => {
                          //         // if(v.day == 'none' ) return null
                          //         return (
        
                          //           <div
                          //             style={{ backgroundColor: v.day == 'today' ? 'red' : v.day == 'tommorrow' ? 'orange' : 'grey', paddingBlock: 10, paddingInline: 10, borderRadius: 10, color: 'white', fontWeight: 'bold', marginTop: 10 }}
                          //             className="col-12 col-md-12 col-lg-12 " >
                          //             {v.collection_date} {v.collection_time},  {v.v_make_model} [{v.v_reg_no}] <br />{v.cust_phone ? v.cust_name + ",(" + v.cust_phone + "),(" + v.cust_email + ")" : null}
                          //           </div>
                          //         )
                          //       })
                          //     }
        
                          //   </div>
        
        
                          //   {/* </div> */}
        
        
        
                          //   {/* </div> */}
        
        
                          // </div>
        